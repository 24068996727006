export const IMGIX_CDN_URL = 'https://tourlane-enquiry.imgix.net';

export const configForImages = {
  // disable LQIP
  preview: false,
  // Ask for max 2x resize on hi-dpi
  ratio: { 1: 1, 2: 2 },
  imgixParams: {
    // ask for autoCompression and autoFormat (webp) on Imgix side
    auto: 'compress,format',
    vib: '20',
    sharp: '10',
  },
};

export const destinationImageConfig = {
  ...configForImages,
  imgixParams: {
    ...configForImages.imgixParams,
    crop: 'edges',
  },
};
