import { FC, PropsWithChildren } from 'react';

import { ImageResizeAware, MEDIA, Skeleton, useMediaBreakpoints } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { Header } from 'components/Header';
import { MoneyTrustBadge } from 'components/MoneyTrustBadge';
import { TrustpilotBadge } from 'components/Trustpilot';
import { Market, currentMarket, destinationImageConfig } from 'const';
import { useAppContext } from 'reducer';
import styled from 'styled-components';

const Background = styled.aside`
  display: none;
  position: relative;

  ${MEDIA.above.md`
    display: flex;
    flex: 1;
    height: auto;
  `}

  ${MEDIA.above.lg`
    width: 100%;
  `}
`;

const Container: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box display="flex" width="100%" minWidth="100%" height="100%" minHeight="100%" justifyContent="space-between">
      {children}
    </Box>
  );
};

export const Layout: FC<PropsWithChildren & { type?: 'fallback' }> = ({ children, type }) => {
  const {
    meta: { destination },
  } = useAppContext();

  const { market } = currentMarket;
  const shouldRenderBackgroundImage = useMediaBreakpoints([false, false, false, true]);
  const shouldShowMoneyBadge = [Market.De, Market.Fr].includes(market);

  // Default layout
  return (
    <Container>
      <Box
        height="100%"
        minHeight="100%"
        width="100%"
        maxWidth={[null, null, null, '50%', 720]}
        position="relative"
        display={['block', 'flex']}
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Header type={type} />
        {children}
      </Box>
      {shouldRenderBackgroundImage && (
        <Background data-cy="layout-background-image">
          {!destination.imageUrl ? (
            <Skeleton />
          ) : (
            <ImageResizeAware alt={destination.name} src={destination.imageUrl} {...destinationImageConfig} />
          )}

          <Box
            data-cy="trustpilot"
            position="absolute"
            bottom="0"
            right="0"
            pointerEvents="none"
            backgroundColor="white"
          >
            {shouldShowMoneyBadge && <MoneyTrustBadge />}
            <TrustpilotBadge />
          </Box>
        </Background>
      )}
    </Container>
  );
};
