import { AgentShift } from '../components/TalkToUs';
import { currentMarket } from '../const';
import { formatAgentShiftHoursRange } from './formatAgentShiftHoursRange';
import { t } from './translations';

const getTranslationForWeekday = (weekday: number, weekdayKeyPrefix: string): string => {
  return t(`${weekdayKeyPrefix}.weekdays.${weekday}`);
};

export const getAgentShiftsTranslations = (agentShift: AgentShift, weekdayKeyPrefix = 'shifts'): string => {
  const { weekdayFrom, weekdayTo, hourFrom, hourTo } = agentShift;
  const { hourFromFormatted, hourToFormatted } = formatAgentShiftHoursRange(hourFrom, hourTo, currentMarket.locale);

  const translationDynamicValue = {
    weekdayFrom: getTranslationForWeekday(weekdayFrom, weekdayKeyPrefix),
    hourFrom: hourFromFormatted,
    hourTo: hourToFormatted,
  };

  if (weekdayFrom === weekdayTo) {
    return t('agentShifts.openingTimesOneDay', translationDynamicValue);
  }

  return t('agentShifts.openingTimes', {
    ...translationDynamicValue,
    weekdayTo: getTranslationForWeekday(weekdayTo, weekdayKeyPrefix),
  });
};
