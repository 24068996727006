import { getQueryParams } from '../getQueryParams';

export const isDuckToolsOn = process.env.REACT_APP_ENVIRONMENT === 'staging';

type DuckToolFeatureTypes = 'market';

const getQueryParam = (featureName: string) => {
  const [paramName] = getQueryParams(window.location.search, [featureName]);

  return paramName;
};

export const getDuckToolValue = (featureName: DuckToolFeatureTypes) => {
  switch (featureName) {
    case 'market':
    default:
      return getQueryParam(featureName);
  }
};
