export enum passengerTypesEnum {
  adults = 'adults',
  children = 'children',
  infants = 'infants',
}

export enum highlightsQuestionsEnum {
  poi = 'poi',
  preferTalkToAgent = 'preferTalkToAgent',
}

export enum emailFormFieldsEnum {
  gender = 'gender',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
}

export enum genderOptionsEnum {
  male = 'M',
  female = 'F',
}

export enum phoneFormFieldsEnum {
  code = 'code',
  number = 'number',
}

export enum budgetQuestionsEnum {
  budget = 'budget',
}

export enum durationQuestionsEnum {
  duration = 'duration',
}

export enum durationOptionsEnum {
  ONE_WEEK = 'oneWeek',
  TWO_WEEKS = 'twoWeeks',
  THREE_TO_FOUR_WEEKS = 'threeToFourWeeks',
  FIVE_PLUS_WEEKS = 'fivePlusWeeks',
}

export enum travelTypeQuestionsEnum {
  travelType = 'travelType',
}

export enum possibleTravelTypesEnum {
  SELF_DRIVE = 'selfDrive',
  PRIVATE_GUIDED = 'privateGuided',
  GROUP_TOUR = 'groupTour',
  OWN_CAR = 'ownCar',
  TRANSFER = 'transfer',
}

export enum datesApproxQuestionsEnum {
  datesApprox = 'datesApprox',
}

export enum fixedDatesEnum {
  startDate = 'startDate',
  endDate = 'endDate',
}

export enum QuestionContextTypes {
  INCREMENTAL_NUMBER_INPUT = 'incrementalNumberInput',
  SINGLE_SELECT_PILL = 'singleSelectPill',
  MULTI_SELECT_CARDS = 'multiSelectCards',
  SINGLE_SELECT_SLIDER = 'singleSelectSlider',
  INCREMENTAL_NUMBER_SLIDER = 'incrementalNumberSlider',
  CHECKBOX = 'checkbox',
  SINGLE_CHOICE_DROPDOWN = 'singleChoiceDropdown',
  DATEPICKER = 'datepicker',
}

export enum ConsentEventTypes {
  GRANTED = 'consent_granted',
  DENIED = 'consent_denied',
}
