import { CUSTOMISABLE_OFFER_PATH } from '../steps/Processing/config';

export const getOfferUrl = ({ previewId }: { previewId?: string }) => {
  if (!previewId) {
    return '';
  }

  const offerPath = `${CUSTOMISABLE_OFFER_PATH}/${previewId}`;

  return `${window.location.origin}${offerPath}`;
};
