import { emailFormFieldsEnum, flowTypes, phoneFormFieldsEnum } from 'const';
import { State } from 'reducer';
import { STEPS } from 'steps';
import { stepsConfig } from 'stepsConfig';

const possibleSteps = Object.values(STEPS).map(step => ({
  slug: stepsConfig[step].slug,
  id: stepsConfig[step].id,
}));

const stepSequence = possibleSteps.map(step => step.id);

export const initialState: State = {
  stepsConfig,
  isEmbedded: false,
  isTripSurfing: false,
  parentPage: null,
  parentHost: null,
  isLoadingDestination: true,
  answers: [],
  breadcrumbs: [],
  meta: {
    travelRequestUuid: '',
    customerUuid: '',
    travelerRouting: '',
    trip: {
      uuid: '',
      url: '',
      status: '',
    },
    destination: {
      name: '',
      uuid: '',
      imageUrl: '',
      tracking: {},
      budgetDefinitions: [],
    },
    customer: {
      [emailFormFieldsEnum.gender]: '',
      [emailFormFieldsEnum.firstName]: '',
      [emailFormFieldsEnum.lastName]: '',
      [emailFormFieldsEnum.email]: '',
      [phoneFormFieldsEnum.code]: '',
      [phoneFormFieldsEnum.number]: '',
    },
    template: {
      templateId: '',
      route: [],
      coordinates: [],
      touristicAreas: [],
      imageUrl: '',
    },
    voucher: undefined,
    featureFlags: {},
    salesConfig: undefined,
    offerPreview: undefined,
    flowType: flowTypes.LINEAR,
    steps: {},
    appLoadedTimestamp: performance.now(),
    timeToCompletionInSeconds: 0,
  },
  initialStep: STEPS.PASSENGERS,
  stepSequence,
};
