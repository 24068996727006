import { FC, useEffect, useState } from 'react';

import { Trans } from 'react-i18next';

import SuccessIcon from '@tourlane/iconography/Glyphs/Notifications/Success';
import SuccessExpertBooksYourTrip from '@tourlane/iconography/Icons/EnquiryExperience/SuccessExpertBooksYourTrip';
import SuccessPackYourBags from '@tourlane/iconography/Icons/EnquiryExperience/SuccessPackYourBags';
import SuccessPreviewAndCustomize from '@tourlane/iconography/Icons/EnquiryExperience/SuccessPreviewAndCustomize';
import SuccessReceiveOffer from '@tourlane/iconography/Icons/EnquiryExperience/SuccessReceiveOffer';
import BookIcon from '@tourlane/iconography/Icons/HowItWorks/Book';
import SuccessCreateOfferIcon from '@tourlane/iconography/Icons/NewExperience/SuccessCreateOffer';
import { COLORS, SvgIcon } from '@tourlane/tourlane-ui';

import { PhoneSuccess } from '../../components/PhoneSucess';
import { SuccessCallUs } from '../../components/SuccessCallUs';
import { SuccessNextStep, SuccessNextSteps } from '../../components/SuccessNextSteps';
import { AgentShift } from '../../components/TalkToUs';
import { canShowNewSignUp, currentMarket } from '../../const';
import { getAgentShiftsTranslations } from '../../utils/getAgentShiftsTranslations';
import { t } from '../../utils/translations';

interface SuccessElements {
  HeadlineIcon: FC;
  Description: FC;
  BodyCopy: FC;
  NextSteps: FC;
}

interface SuccessElementsProps {
  isDirectBooking: boolean;
  isInTheOperatingMarket: boolean;
  isDuringAgentShift: boolean;
  agentShifts: AgentShift[];
  phoneNumber: string;
}

const stepForNonDEMarket = {
  title: t('success.receiveYourOffer'),
  icon: SuccessReceiveOffer,
  description: t('success.happyWithYourTrip'),
};

const nextSteps: SuccessNextStep[] = [
  {
    title: t('success.previewAndCustomize'),
    icon: SuccessPreviewAndCustomize,
    description: t('success.ourTourlaneExperts'),
  },
  {
    title: t('success.expertBooksYourTrip'),
    icon: SuccessExpertBooksYourTrip,
    description: t('success.yourTravelExpertBooks'),
  },
  {
    title: t('success.packYourBags'),
    icon: SuccessPackYourBags,
    description: t('success.itsTimeToGetPacking'),
  },
];

if (!canShowNewSignUp(currentMarket.market)) {
  nextSteps.splice(1, 0, stepForNonDEMarket);
}

const directBookingNextSteps: SuccessNextStep[] = [
  {
    title: t('success.weCreateAnOffer'),
    icon: SuccessCreateOfferIcon,
    description: t('success.weSendYouAnOffer'),
  },
  {
    title: t('success.expertsBookYourTrip'),
    icon: BookIcon,
    description: t('success.travelExpertBooks'),
  },
  {
    title: t('success.packYourBagsOut'),
    icon: SuccessPackYourBags,
    description: t('success.readyForDreamTrip'),
  },
];

const getClassicBookingDescription = (
  isInTheOperatingMarket: boolean,
  isDuringAgentShift: boolean,
  agentShifts: AgentShift[]
) => {
  const classicBookingDescriptionForOperatedMarket = isDuringAgentShift ? (
    t('success.descriptionWorkingHours')
  ) : (
    <Trans
      i18nKey="success.descriptionOutsideWorkingHours"
      components={[
        <span style={{ display: 'block' }} key="array">
          {agentShifts.map(shift => {
            const translation = getAgentShiftsTranslations(shift, 'success');
            return (
              <strong style={{ display: 'block' }} key={translation}>
                {translation}
              </strong>
            );
          })}
        </span>,
      ]}
    />
  );

  return isInTheOperatingMarket
    ? classicBookingDescriptionForOperatedMarket
    : t('success.descriptionNonOperatedMarket');
};

export const useSuccessElements = ({
  isDirectBooking,
  isDuringAgentShift,
  isInTheOperatingMarket,
  agentShifts,
  phoneNumber,
}: SuccessElementsProps) => {
  const [elements, setElements] = useState<SuccessElements>({
    HeadlineIcon: () => null,
    Description: () => null,
    BodyCopy: () => null,
    NextSteps: () => null,
  });

  useEffect(() => {
    const iconColor = isInTheOperatingMarket && isDuringAgentShift ? COLORS.ADVENTURE_GREEN : COLORS.ELEMENT_GRAY;

    if (isDirectBooking) {
      setElements({
        HeadlineIcon: () => (
          <SvgIcon
            data-cy="db-headline-icon"
            size={[64, 50, 56, 64]}
            color={isInTheOperatingMarket ? COLORS.ADVENTURE_GREEN : COLORS.ELEMENT_GRAY}
          >
            <SuccessIcon />
          </SvgIcon>
        ),
        Description: () => (
          <span data-cy="db-description">
            <Trans>
              {isInTheOperatingMarket ? t('success.pleaseCallUs') : t('success.descriptionNonOperatedMarket')}
            </Trans>
          </span>
        ),
        BodyCopy: () =>
          isInTheOperatingMarket ? (
            <SuccessCallUs
              agentShifts={agentShifts}
              isDuringAgentShift={isDuringAgentShift}
              phoneNumber={phoneNumber}
            />
          ) : null,
        NextSteps: () =>
          isInTheOperatingMarket ? <SuccessNextSteps dataCy="db-next-steps" steps={directBookingNextSteps} /> : null,
      });
    } else {
      const classicBookingDescription = getClassicBookingDescription(
        isInTheOperatingMarket,
        isDuringAgentShift,
        agentShifts
      );

      setElements({
        HeadlineIcon: () => (
          <PhoneSuccess color={iconColor} withAnimation={isDuringAgentShift && isInTheOperatingMarket} />
        ),
        Description: () => classicBookingDescription,
        BodyCopy: () => null,
        NextSteps: () => <SuccessNextSteps steps={nextSteps} />,
      });
    }
  }, [isDirectBooking, isDuringAgentShift, isInTheOperatingMarket, agentShifts, phoneNumber]);

  return elements;
};
