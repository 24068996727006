import { FC, ReactNode } from 'react';

import { Box } from '@tourlane/tourlane-ui';
import { useFormik } from 'formik';

import { accessTokenLocalStorageKey, emailFormFieldsEnum } from '../../const';
import { ACTION_TYPE, useAppContext } from '../../reducer';
import { STEPS } from '../../steps';
import { EmailDataRecordTypes } from '../../types/EmailDataRecord.types';
import { BackendResponse } from '../../utils/api';
import { CUSTOMER_UUID_COOKIE_NAME, setCookie } from '../../utils/cookie';
import { getExperiments } from '../../utils/getExperiments';
import { getOfferUrl } from '../../utils/getOfferUrl';
import { trackEmailSubmit } from '../../utils/trackingEvents';
import { t } from '../../utils/translations';
import { createTravelRequest, handleBackendError } from '../../utils/travelRequest';
import { EmailSchema as validationSchema } from '../../utils/validation';
import { DataSafetyHint } from '../DataSafetyHint';
import { useFeatureFlagContext } from '../FeatureFlag';
import { Footer } from '../Footer';
import { NextButton } from '../Styled';
import { EmailInputField, Genders } from './InputFields';

type EmailFormProps = {
  stepConfig: any;
  onSubmitCallback: () => void;
  heading?: ReactNode;
  className?: string;
  nextButtonDataCy?: string;
};

export const EmailForm: FC<EmailFormProps> = ({
  stepConfig,
  onSubmitCallback,
  heading,
  className,
  nextButtonDataCy,
}) => {
  const {
    answers,
    dispatch,
    meta: { customer, template, destination, travelerRouting, previewId, flowType },
    stepsConfig,
  } = useAppContext();
  const { flags } = useFeatureFlagContext();
  const experiments = getExperiments(flags);
  const offerUrl = getOfferUrl({ previewId });

  const { firstName, lastName, gender, email } = customer;
  const { initialValues } = stepConfig;
  const { templateId } = template;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  let handleError = (response: BackendResponse) => {};

  const onSubmit = async (formData: EmailDataRecordTypes) => {
    try {
      const {
        customerUuid,
        uuid: travelRequestUuid,
        token,
      } = await createTravelRequest({
        answers,
        customer: formData,
        templateId,
        stepsConfig,
        destinationUuid: destination.uuid,
        travelerRouting,
        experiments,
        previewId,
        flowType,
        offerUrl,
      });

      setCookie({
        cookieName: encodeURIComponent(accessTokenLocalStorageKey),
        cookieValue: token?.accessToken,
      });

      setCookie({
        cookieName: CUSTOMER_UUID_COOKIE_NAME,
        cookieValue: customerUuid,
      });

      dispatch({
        type: ACTION_TYPE.STORE_META,
        payload: {
          travelRequestUuid,
          customerUuid,
          customer: {
            ...customer,
            ...formData,
          },
        },
      });

      trackEmailSubmit({
        conversionStep: STEPS.EMAIL,
        travelRequestUuid,
        customerUuid,
        destinationUuid: destination.uuid,
        ...formData,
      });

      onSubmitCallback();
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setFieldError(emailFormFieldsEnum.email, t('emailForm.emailInvalid'));
      handleError(error);
    }
  };

  const formik = useFormik<EmailDataRecordTypes>({
    initialValues: {
      gender: gender || initialValues[emailFormFieldsEnum.gender],
      firstName,
      lastName,
      email,
    },
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: true,
  });

  // Hack to pass formik instance after it was created
  handleError = error => handleBackendError(formik, error);

  const { handleSubmit, handleChange, isSubmitting, setFieldError } = formik;

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Box py={[32, null, null, null, 10]} px={[16, null, null, 32]}>
        {heading}
        <Genders handleChange={handleChange} checked={formik.values.gender} />
        <EmailInputField {...formik} />
        <DataSafetyHint />
      </Box>
      <Footer>
        <NextButton type="submit" isLoading={isSubmitting} dataCy={nextButtonDataCy} />
      </Footer>
    </form>
  );
};
