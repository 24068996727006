import { snowplow } from '@tourlane/tracking';
import { Base64 } from 'js-base64';
import { PhoneDataRecord } from 'steps/Phone/config';
import { StepConfigType } from 'stepsConfig.types';
import { FrontendAnswerType, MultipleAnswerType } from 'utils/answerHook';
import { BackendAnswerType, answersUtils } from 'utils/answers';
import { answerMapper } from 'utils/travelRequestHelpers';

import { passengerTypesEnum } from '../const';
import { DestinationTracking } from '../reducer';
import { STEPS } from '../steps';
import { MentionMeData } from '../steps/Phone/phoneHelpers';
import { EmailDataRecordTypes } from '../types/EmailDataRecord.types';
import {
  trackEmailSubmitGTMEvent,
  trackLastQuestionAnsweredGTMEvent,
  trackPhoneSubmitGTMEvent,
  trackQuestionAnsweredGTMEvent,
} from './gtmEvents';
import {
  ConversionSnowplowEventProps,
  QuestionEventTypes,
  trackConversionSnowplowStep,
  trackQuestionSubmitSnowplowEvent,
  trackQuestionnaireCompleteSnowplowEvent,
} from './snowplowEvents';

/**
 * Formats input data of the SIMPLIFIED EE flow into a valid payload and sends it to Snowplow and GTM
 * event
 *
 * all potential config data for all questions
 * @param {MultipleAnswerType[]} answers all answers entered by the user
 * @param {number} questionIndex total number of questions
 * @param {StepConfigType} stepConfig config of the step to generate the context/default values
 * @param {string} destinationUuid the UUID of the destination
 */
export const trackSubmitFormWithMultipleAnswers = (
  answers: MultipleAnswerType[],
  questionIndex: number,
  stepConfig: StepConfigType,
  destinationUuid: string
) => {
  const answersForSnowplow: BackendAnswerType[] = [];
  const answersForGTM: FrontendAnswerType[] = [];

  answers.forEach(({ stepId, value }) => {
    Object.keys(value).forEach((identifier: string) => {
      let isDefault;
      let answerText = stepConfig.questions![stepId].answerText(value[identifier]);
      const answerValue = value[identifier] as BackendAnswerType['value'];

      stepConfig.questions![stepId].availableAnswers?.forEach(availableAnswer => {
        if (answerValue === availableAnswer.value) {
          answerText = availableAnswer.title;
        }
      });

      const mappedAnswer = {
        identifier,
        value: answerValue,
        text: Base64.encode(answerText),
      };

      if (stepId === STEPS.PASSENGERS) {
        const shouldCheckIfDefaultPassengers = identifier === passengerTypesEnum.adults;
        const adultsAnswerMappedToPassengers = { ...mappedAnswer, identifier: STEPS.PASSENGERS };
        isDefault = shouldCheckIfDefaultPassengers
          ? answersUtils.isDefault(adultsAnswerMappedToPassengers, stepConfig)
          : false;
      } else {
        isDefault = answersUtils.isDefault(mappedAnswer, stepConfig);
      }

      answersForGTM.push({ [identifier]: answerValue });

      answersForSnowplow.push({
        ...mappedAnswer,
        value: String(mappedAnswer.value),
        is_default: isDefault,
      });
    });
  });

  trackQuestionAnsweredGTMEvent({ stepConfig, answers: answersForGTM, questionIndex });
  trackQuestionSubmitSnowplowEvent({
    type: QuestionEventTypes.SUBMIT,
    stepConfig,
    answers: answersForSnowplow,
    questionIndex,
    destinationUuid,
  });
};

/**
 * Formats input data into a valid payload and sends it to Snowplow and GTM
 * event
 *
 * all potential config data for all questions
 * @param {FrontendAnswerType} answers an answer entered by the user
 * @param {number} questionIndex total number of questions
 * @param {StepConfigType} stepConfig config of the step to generate the context/default values
 * @param {string} destinationUuid the UUID of the destination
 * @param {string} eventType the type of the event to be tracked
 */
export const trackQuestionSubmit = (
  answers: FrontendAnswerType,
  questionIndex: number,
  stepConfig: StepConfigType,
  destinationUuid: string,
  eventType?: QuestionEventTypes
) => {
  trackQuestionAnsweredGTMEvent({ stepConfig, answers, questionIndex });

  const mappedAnswers = answerMapper({
    answers,
    stepConfig,
  });

  const encodedAnwers = mappedAnswers.map(answer => {
    return {
      ...answer,
      // casting to string should ensure we always send string to snowplow
      value: String(answer.value),
      text: Base64.encode(answer.text),
      is_default: answersUtils.isDefault(answer, stepConfig),
    };
  });

  trackQuestionSubmitSnowplowEvent({
    type: eventType || QuestionEventTypes.SUBMIT,
    stepConfig,
    answers: encodedAnwers,
    questionIndex,
    destinationUuid,
  });
};

export const trackQuestionSkipAsSubmit = (
  answers: FrontendAnswerType,
  questionIndex: number,
  stepConfig: StepConfigType,
  destinationUuid: string
) => trackQuestionSubmit(answers, questionIndex, stepConfig, destinationUuid, QuestionEventTypes.SKIP);

export const trackQuestionnaireComplete = (
  numberOfQuestions: number,
  budget: string,
  destinationUuid: string,
  destinationTracking: DestinationTracking
) => {
  trackLastQuestionAnsweredGTMEvent(budget, destinationTracking);
  trackQuestionnaireCompleteSnowplowEvent(numberOfQuestions, destinationUuid);
};

export const trackEmailSubmit = ({
  conversionStep,
  travelRequestUuid,
  destinationUuid,
  email,
  firstName,
  gender,
  lastName,
}: {
  customerUuid: string;
} & ConversionSnowplowEventProps &
  EmailDataRecordTypes) => {
  trackEmailSubmitGTMEvent({ travelRequestUuid, email, firstName, lastName, gender });

  trackConversionSnowplowStep({
    conversionStep,
    travelRequestUuid,
    destinationUuid,
  });
};

export const trackPhoneSubmit = ({
  budget,
  conversionStep,
  travelRequestUuid,
  destinationUuid,
  mentionMeData,
}: {
  budget: string;
  customerUuid: string;
  destinationUuid: string;
  mentionMeData?: MentionMeData;
} & ConversionSnowplowEventProps &
  PhoneDataRecord) => {
  trackPhoneSubmitGTMEvent(budget, mentionMeData);

  trackConversionSnowplowStep({
    conversionStep,
    travelRequestUuid,
    destinationUuid,
  });
};

type ClickTrackingType = {
  atom: string;
  molecule: string;
  label?: string;
  pageType?: string;
  destinationUuid: string;
};

export const trackClick = ({ atom, molecule, label = '', pageType = '', destinationUuid }: ClickTrackingType) => {
  snowplow.trackSelfDescribingEvent({
    event: {
      schema: snowplow.Schema.COMPONENT_EVENT,
      data: {
        action: snowplow.Action.CLICK,
        molecule,
        atom,
        label,
      },
    },
    pageType,
    destination: destinationUuid,
  });
};
