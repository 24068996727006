import React, { useEffect, useRef, useState } from 'react';

import { ProgressBar as Bar } from '@tourlane/tourlane-ui';
import { FlowTypes } from 'const';
import { useAppContext } from 'reducer';
import { MAX_POSSIBLE_STEPS, STEPS, STEP_KEYS } from 'steps';
import { StepsConfigType } from 'stepsConfig.types';
import { getStepFromPathName } from 'utils/routing';

const getProgressByStep = ({
  stepsConfig,
  pathname,
  initialStep,
  stepSequence,
  flowType,
}: {
  stepsConfig: StepsConfigType;
  pathname: string;
  initialStep: STEP_KEYS;
  stepSequence: Array<STEP_KEYS>;
  flowType: FlowTypes;
}) => {
  const currentStep = getStepFromPathName(stepsConfig, pathname) || initialStep;

  // count starts from 0;
  const OFFSET = 1;
  const currentStepIndex = stepSequence.findIndex(id => id === currentStep) + OFFSET;
  const stepIndex = currentStep === STEPS.PROCESSING ? MAX_POSSIBLE_STEPS[flowType] : currentStepIndex;

  return Math.ceil((stepIndex / MAX_POSSIBLE_STEPS[flowType]) * 100);
};

export const ProgressBar: React.FC = () => {
  const {
    stepsConfig,
    initialStep,
    stepSequence,
    meta: { flowType },
  } = useAppContext();
  const { pathname } = window.location;
  const previousPathname = useRef(pathname);
  const stepValue = getProgressByStep({ stepsConfig, pathname, initialStep, stepSequence, flowType });
  const [progress, setProgress] = useState(stepValue);

  useEffect(() => {
    // TODO:TEST if this works with setup
    if (pathname !== previousPathname.current) {
      setProgress(getProgressByStep({ stepsConfig, pathname, initialStep, stepSequence, flowType }));
      previousPathname.current = pathname;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, flowType]);

  return (
    <Bar
      progress={progress}
      height={{ xs: 4, md: 6 }}
      corners
      data-cy="progress-bar"
      aria-label="Questionnaire progress"
      animationDuration={500}
    />
  );
};
