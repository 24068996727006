import { getQueryParams } from '../utils/getQueryParams';
import { EMBEDDED } from './constants';

const calculateIsEmbedded = () => {
  const [embeddedParam] = getQueryParams(window.location.search, [EMBEDDED]);

  return embeddedParam === 'true';
};

export const isEmbedded = calculateIsEmbedded();
