import { FC, SVGProps, useState } from 'react';

import xor from 'lodash-es/xor';

export type FrontendAnswerValueType = string | string[] | boolean | number | Date;

export type FrontendAnswerType = {
  [key: string]: FrontendAnswerValueType;
};

export type AvailableAnswerType = {
  value: string | number;
  title: string;
  content?: string;
  imageUrl?: string;
  icon?: string | FC<SVGProps<SVGSVGElement>>;
  iconInactive?: string | FC<SVGProps<SVGSVGElement>>;
  iconActive?: string | FC<SVGProps<SVGSVGElement>>;
  budgetConfig?: any[];
};

export type MultipleAnswerType = {
  stepId: string;
  value: FrontendAnswerType;
};

export const getUpdatedStepAnswer = (
  stepAnswer: FrontendAnswerType,
  questionId: string,
  answerValue: FrontendAnswerValueType | undefined
) => {
  let newAnswerForQuestion;
  const oldValue = stepAnswer[questionId];

  if (Array.isArray(oldValue)) {
    // Toggle item in array
    if (Array.isArray(answerValue) && !answerValue.length) {
      newAnswerForQuestion = {
        [questionId]: [],
      };
    } else {
      newAnswerForQuestion = {
        [questionId]: xor(oldValue, [answerValue]),
      };
    }
  } else if (answerValue === undefined) {
    // Remove field completely
    // eslint-disable-next-line no-param-reassign
    delete stepAnswer[questionId];
  } else {
    // Override answer for number, string and boolean
    newAnswerForQuestion = { [questionId]: answerValue };
  }

  return {
    ...stepAnswer,
    ...newAnswerForQuestion,
  };
};

export const useCurrentAnswerState = (initialValues: any) => {
  const [currentAnswer, setAnswer] = useState(initialValues);

  const updateAnswer = (questionId: string, answerValue: FrontendAnswerValueType) =>
    setAnswer(getUpdatedStepAnswer(currentAnswer as FrontendAnswerType, questionId, answerValue));

  return [currentAnswer, updateAnswer];
};
