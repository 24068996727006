import { isTestingEnv } from './environment';

const DEFAULT_DE_DESTINATION_SLUG = 'suedafrika';
export const DEFAULT_OP_DISABLED_DE_DESTINATION_SLUG = 'indonesien';

// ie pathname = /eee/suedafrika/enquiry
export const destinationSlugFromUrl: string = window.location.pathname.split('/')[2];

// we dont need to default to southafrika always, just when we are visiting "/"
export const destinationSlug: string = destinationSlugFromUrl || DEFAULT_DE_DESTINATION_SLUG;

const setBasePath = () => {
  // local development
  if (!isTestingEnv) {
    return `/eee/${destinationSlug}/enquiry`;
  }

  // cypress testing
  // @ts-ignore
  if (window.Cypress.env('isDestinationEnabledForOP')) {
    return `/eee/${DEFAULT_DE_DESTINATION_SLUG}/enquiry`;
  }
  return `/eee/${DEFAULT_OP_DISABLED_DE_DESTINATION_SLUG}/enquiry`;
};
export const basePath = setBasePath();

export const followUpSlug = `${basePath}/followup`;
