import { QuestionContextTypes, basePath, currentMarket, durationOptionsEnum } from 'const';
import { SIMPLIFIED_FLOW_STEPS, STEPS } from 'steps';
import { getSeasonsInSequence } from 'steps/DatesApprox/getSeasonsInSequence';
import { DEFAULT_INITIAL_TRAVEL_TYPE, SELF_DRIVE, TRANSFER } from 'steps/TravelType/config';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { formatPrice } from 'utils/formatPrice';
import { t } from 'utils/translations';

export const DEFAULT_INITIAL_DURATION = durationOptionsEnum.TWO_WEEKS;

const availableDatesApprox = getSeasonsInSequence();

const availablePassengers = [
  {
    value: '1',
    title: `1 ${t('simplifiedForm.passengerOne')}`,
  },
  {
    value: '2',
    title: `2 ${t('simplifiedForm.passengers')}`,
  },
  {
    value: '3',
    title: `3 ${t('simplifiedForm.passengers')}`,
  },
  {
    value: '4',
    title: `4 ${t('simplifiedForm.passengers')}`,
  },
  {
    value: '5',
    title: `5+ ${t('simplifiedForm.passengers')}`,
  },
];

const availableDuration = [
  {
    value: durationOptionsEnum.ONE_WEEK,
    title: t('duration.oneWeek'),
  },
  {
    value: durationOptionsEnum.TWO_WEEKS,
    title: t('duration.twoWeeks'),
  },
  {
    value: durationOptionsEnum.THREE_TO_FOUR_WEEKS,
    title: t('duration.threeToFourWeeks'),
  },
  {
    value: durationOptionsEnum.FIVE_PLUS_WEEKS,
    title: t('duration.fivePlusWeeks'),
  },
];

// Dropdown component expects label instead of title
export const changeTitleToLabel = (availableAnswers: { value: string | number; title: string }[]) => {
  return availableAnswers.map((item: { value: string | number; title: string }) => {
    return { value: item.value, label: item.title };
  });
};

export const DEFAULT_MIN_BUDGET = 500;
const DEFAULT_BUDGET_OFFSET = 500;
const DEFAULT_BUDGET_STEP = 500;
export const DEFAULT_MAX_BUDGET = 10000;
const DEFAULT_INITIAL_BUDGET = 2000;

export const config = {
  id: SIMPLIFIED_FLOW_STEPS.ONE,
  idx: 1,
  questionText: t('simplifiedForm.heading'),
  questions: {
    [STEPS.PASSENGERS]: {
      id: STEPS.PASSENGERS,
      initialValue: null,
      availableAnswers: availablePassengers,
      answerText: () => '',
      context: {
        type: QuestionContextTypes.SINGLE_CHOICE_DROPDOWN,
        step: 1,
        max: 5,
        min: 1,
      },
    },
    [STEPS.DATES_APPROX]: {
      id: STEPS.DATES_APPROX,
      initialValue: null,
      availableAnswers: availableDatesApprox,
      answerText: () => '',
      context: {
        type: QuestionContextTypes.SINGLE_CHOICE_DROPDOWN,
      },
    },
    [STEPS.DURATION]: {
      id: STEPS.DURATION,
      initialValue: DEFAULT_INITIAL_DURATION,
      availableAnswers: availableDuration,
      answerText: () => '',
      context: {
        type: QuestionContextTypes.SINGLE_CHOICE_DROPDOWN,
      },
    },
    [STEPS.BUDGET]: {
      id: STEPS.BUDGET,
      initialValue: DEFAULT_INITIAL_BUDGET,
      answerText: (value: FrontendAnswerValueType) => formatPrice(Number(value), currentMarket.locale),
      context: {
        type: QuestionContextTypes.INCREMENTAL_NUMBER_SLIDER,
        get step(): number {
          return config.questions[STEPS.BUDGET].configuration.budgetStep;
        },
        get min(): number {
          return config.questions[STEPS.BUDGET].configuration.minBudget;
        },
        get max(): number {
          return config.questions[STEPS.BUDGET].configuration.maxBudget;
        },
      },
      configuration: {
        budgetStep: DEFAULT_BUDGET_STEP,
        minBudget: DEFAULT_MIN_BUDGET,
        maxBudget: DEFAULT_MAX_BUDGET,
        budgetOffset: DEFAULT_BUDGET_OFFSET,
      },
    },
    [STEPS.TRAVEL_TYPE]: {
      id: STEPS.TRAVEL_TYPE,
      answerText: (value: FrontendAnswerValueType) =>
        config.questions.travelType.availableAnswers.find(a => a.value === value)?.title,
      initialValue: DEFAULT_INITIAL_TRAVEL_TYPE,
      availableAnswers: [SELF_DRIVE, TRANSFER],
      context: {
        type: QuestionContextTypes.SINGLE_CHOICE_DROPDOWN,
      },
    },
  },
  next: () => SIMPLIFIED_FLOW_STEPS.TWO,
  slug: `${basePath}/${SIMPLIFIED_FLOW_STEPS.ONE}`,
};
