import * as React from 'react';

import EmailIcon from '@tourlane/iconography/Glyphs/Navigation/Email';
import UserIcon from '@tourlane/iconography/Glyphs/Navigation/User';
import CheckmarkIcon from '@tourlane/iconography/Glyphs/Other/Checkmark';
import { Base, COLORS, Label, Radio, TextField } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { emailFormFieldsEnum, genderOptionsEnum } from 'const';
import { FormikProps } from 'formik';
import { t } from 'utils/translations';
import { emailSchema, fieldIsValid, firstNameSchema, lastNameSchema } from 'utils/validation';

import { EmailDataRecordTypes } from '../../types/EmailDataRecord.types';

type GENDERS = {
  id: string;
  value: string;
  label: string;
}[];

type GendersProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  checked?: string;
};

export const Genders: React.FC<GendersProps> = ({ handleChange, checked }) => {
  const { femaleLabel, maleLabel } = t('emailform');

  const genders: GENDERS = [
    { id: maleLabel, value: genderOptionsEnum.male, label: maleLabel },
    { id: femaleLabel, value: genderOptionsEnum.female, label: femaleLabel },
  ];

  return (
    <Box display="flex" mb={24}>
      {genders.map(({ id, value, label }) => (
        <Box key={id} mr={[32, null, 20]}>
          <Label htmlFor={id}>
            <Radio
              id={id}
              value={value}
              name={emailFormFieldsEnum.gender}
              defaultChecked={value === checked}
              onChange={handleChange}
              data-cy="email-form-gender"
            />
            <Base>{label}</Base>
          </Label>
        </Box>
      ))}
    </Box>
  );
};

export const EmailInputField: React.FC<FormikProps<EmailDataRecordTypes>> = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}) => {
  const { firstNameLabel, lastNameLabel, emailLabel } = t('emailform');

  const inputFields = [
    {
      placeholder: firstNameLabel,
      autoComplete: 'given-name',
      name: emailFormFieldsEnum.firstName,
      icon: <UserIcon />,
      validationSchema: firstNameSchema,
    },
    {
      placeholder: lastNameLabel,
      autoComplete: 'family-name',
      name: emailFormFieldsEnum.lastName,
      icon: <UserIcon />,
      validationSchema: lastNameSchema,
    },
    {
      placeholder: emailLabel,
      autoComplete: 'email',
      name: emailFormFieldsEnum.email,
      type: 'email',
      icon: <EmailIcon />,
      validationSchema: emailSchema,
    },
  ];

  return (
    <>
      {inputFields.map(({ name, placeholder, validationSchema, ...rest }) => (
        <Box data-cy={`email-form-${name}-wrapper`} key={name} mb={[20, 24, 24, 20]}>
          <TextField
            data-cy={`email-form-${name}`}
            hideErrorOnFocus={false}
            error={touched[emailFormFieldsEnum[name]] && errors[emailFormFieldsEnum[name]]}
            iconRight={
              fieldIsValid({
                values,
                validationSchema,
                fieldName: name,
              }) && !errors[emailFormFieldsEnum[name]] ? (
                <CheckmarkIcon />
              ) : null
            }
            iconRightColor={COLORS.ADVENTURE_GREEN}
            id={name}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            shrinkPlaceholder
            value={values[emailFormFieldsEnum[name]]}
            aria-label={placeholder}
            {...rest}
          />
        </Box>
      ))}
    </>
  );
};
