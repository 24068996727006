export enum FlagValueBoolWithExcluded {
  true = 'true',
  false = 'false',
  excluded = 'excluded',
}

// USE THIS FOR FUTURE STANDARD A/B EXPERIMENTS
export enum FlagValuesExperiment {
  control = 'control',
  test = 'test',
  excluded = 'excluded',
}

export enum FeatureFlags {
  HAS_CONFIGCAT_FLAGS = 'has_configcat_flags',
}

export interface FlagState {
  [FeatureFlags.HAS_CONFIGCAT_FLAGS]: 'true' | 'false';
}

export interface SettingKeyValue {
  settingKey: string;
  settingValue: any;
}
