import React from 'react';

import { Helmet } from 'react-helmet';

import { basePath, currentMarket } from 'const';
import { useAppContext } from 'reducer';
import { t } from 'utils/translations';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> extends AriaAttributes {
    // extends React's HTMLAttributes
    charset?: string;
  }
}

export const DynamicMeta: React.FC = () => {
  const {
    meta: { destination },
  } = useAppContext();

  return (
    <Helmet>
      <html lang={currentMarket.dayJsLocale} />
      <title>{t('meta.title', { name: destination.name })}</title>
      <meta name="description" content={t('meta.description')} />
      <meta property="og:title" content={t('meta.title', { name: destination.name })} />
      <meta property="og:url" content={`${window.location.origin}${basePath}`} />
      <meta property="og:description" content={t('meta.description')} />
    </Helmet>
  );
};
