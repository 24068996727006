import React, { FC, PropsWithChildren } from 'react';

import { DidomiSDK, IDidomiObject } from '@didomi/react';
import { snowplow } from '@tourlane/tracking';

import { ConsentEventTypes } from '../../const';
import { checkTupleValueIsTrue, consentStatusReducer, getFormSubmitEventContext } from './utils';

declare global {
  interface Window {
    Didomi: IDidomiObject;
  }
}

export const Didomi: FC<PropsWithChildren> = ({ children }) => {
  const onConsentChanged = () => {
    const { Didomi } = window;
    const userConsentStatusForPurposes = Didomi.getRequiredPurposeIds().reduce(
      consentStatusReducer(Didomi.getUserConsentStatusForPurpose),
      []
    );
    const userConsentStatusForVendors = Didomi.getRequiredVendorIds().reduce(
      consentStatusReducer(Didomi.getUserConsentStatusForVendor),
      []
    );

    // if user gave consent to at least one vendor/purpose => consent_granted

    if (
      userConsentStatusForPurposes.some(checkTupleValueIsTrue) ||
      userConsentStatusForVendors.some(checkTupleValueIsTrue)
    ) {
      snowplow.trackSelfDescribingEvent({
        event: getFormSubmitEventContext(ConsentEventTypes.GRANTED),
      });
    } else {
      snowplow.trackSelfDescribingEvent({
        event: getFormSubmitEventContext(ConsentEventTypes.DENIED),
      });
    }
  };

  return (
    <>
      <DidomiSDK apiKey={process.env.REACT_APP_DIDOMI_KEY_ID} iabVersion={2} onConsentChanged={onConsentChanged} />
      {children}
    </>
  );
};
