import * as React from 'react';

import ErrorIcon from '@tourlane/iconography/Icons/Error/Error';
import { H2, Link, P, Subline, SvgIcon } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { t } from 'utils/translations';

export const SomethingWentWrong: React.FC = () => (
  <Box m={24} display="flex" flexDirection="column" alignItems="center" textAlign="center">
    <Box my={16}>
      <SvgIcon size={120}>
        <ErrorIcon />
      </SvgIcon>
    </Box>
    <H2>{t('somethingWentWrong.title')}</H2>
    <Box my={16}>
      <Subline>{t('somethingWentWrong.subtitle')}</Subline>
    </Box>
    <Box maxWidth={400}>
      <P>
        {t('somethingWentWrong.cantAccessPage')} {t('somethingWentWrong.please')}{' '}
        <Link href="#reload" onClick={() => window.location.reload()}>
          {t('somethingWentWrong.tryAgain')}
        </Link>{' '}
        {t('somethingWentWrong.inFewMinutes')}
      </P>
    </Box>
  </Box>
);
