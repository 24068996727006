import { FC } from 'react';

import { Base, COLORS, H3, MEDIA, Subline } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import styled from 'styled-components';

import { getAgentShiftsTranslations } from '../../utils/getAgentShiftsTranslations';
import { t } from '../../utils/translations';
import { AgentShift } from '../TalkToUs';

export const StyledAnchor = styled.a`
  text-decoration-color: ${COLORS.ADVENTURE_GREEN};
  ${MEDIA.above.md`
    text-decoration: none;
  `}
`;

export const SuccessCallUs: FC<{ agentShifts: AgentShift[]; isDuringAgentShift: boolean; phoneNumber: string }> = ({
  agentShifts,
  isDuringAgentShift,
  phoneNumber,
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" data-cy="db-bodycopy">
      <Box mt={[32, 24]} mb={8}>
        <Subline>{t('success.callToFinalise')}</Subline>
      </Box>
      <Box mb={[16, null, null, 8, 4]}>
        <StyledAnchor href={`tel:${phoneNumber}`}>
          <H3 color={isDuringAgentShift ? COLORS.ADVENTURE_GREEN : COLORS.NIGHTINGALE_BLACK}>{phoneNumber}</H3>
        </StyledAnchor>
      </Box>
      <Base>
        <span style={{ display: 'block' }} key="array">
          {agentShifts.map(shift => {
            const translation = getAgentShiftsTranslations(shift, 'success');
            return (
              <strong style={{ display: 'block' }} key={translation}>
                {translation}
              </strong>
            );
          })}
        </span>
      </Base>
    </Box>
  );
};
