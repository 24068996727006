import { Market, MarketType } from 'const';

export const getTruspilotUrl = (market: string) => {
  const trustpilotKey = process.env.REACT_APP_TRUSTPILOT_API_KEY;
  const name = market === Market.Fr ? market : Market.De;
  return `https://api.trustpilot.com/v1/business-units/find?apikey=${trustpilotKey}&name=${name}`;
};

export interface TrustpilotResponse {
  score: number;
  stars: number;
  reviews: number;
}

export const trustpilotUrls: Record<MarketType, string> = {
  'de-DE': 'https://de.trustpilot.com/review/www.tourlane.de',
  'en-GB': 'https://uk.trustpilot.com/review/www.tourlane.de',
  'en-US': 'https://www.trustpilot.com/review/www.tourlane.de',
  'fr-FR': 'https://fr.trustpilot.com/review/www.tourlane.fr',
  'nl-NL': 'https://nl.trustpilot.com/review/www.tourlane.de',
};

export const reviewsByMarket: Record<MarketType, string> = {
  'de-DE': 'Bewertungen',
  'en-GB': 'reviews',
  'en-US': 'reviews',
  'fr-FR': 'avis',
  'nl-NL': 'beoordelingen',
};
