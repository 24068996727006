import { Locale, durationOptionsEnum, possibleTravelTypesEnum } from 'const';

import { DestinationsToShowStarsType } from './typeUtils';

export const destinationMap = [
  {
    name: 'Argentina',
    'alpha-2': 'AR',
    'alpha-3': 'ARG',
    uuid: '087e8ead-fd75-4c11-9dce-ad8a2c649c17',
    multiplierGroup: 2,
  },
  {
    name: 'Australia',
    'alpha-2': 'AU',
    'alpha-3': 'AUS',
    uuid: '24bb4b34-b449-4f20-beb6-a8c77a1dbe13',
    multiplierGroup: 2,
  },
  {
    name: 'Brazil',
    'alpha-2': 'BR',
    'alpha-3': 'BRA',
    uuid: 'e7796c03-6704-496e-a92e-bb720b680599',
    multiplierGroup: 2,
  },
  {
    name: 'Botswana',
    'alpha-2': 'BW',
    'alpha-3': 'BWA',
    uuid: '543fe461-7db6-4fcc-9ea2-b67f90a3dba3',
    multiplierGroup: 1,
  },
  {
    name: 'Canada',
    'alpha-2': 'CA',
    'alpha-3': 'CAN',
    uuid: '870e06b2-19e7-4fbb-8f27-6a13c4384b94',
    multiplierGroup: 1,
  },
  {
    name: 'Chile',
    'alpha-2': 'CL',
    'alpha-3': 'CHL',
    uuid: '107d80b0-ee97-4665-a4b3-6b02bc5b8a6e',
    multiplierGroup: 2,
  },
  {
    name: 'Colombia',
    'alpha-2': 'CO',
    'alpha-3': 'COL',
    uuid: '26f999aa-9248-41f0-a6c1-80ef71d07b6c',
    multiplierGroup: 2,
  },
  {
    name: 'Costa Rica',
    'alpha-2': 'CR',
    'alpha-3': 'CRI',
    uuid: 'a1ed5267-f937-47ed-8e9e-973edf32930e',
    multiplierGroup: 4,
  },
  {
    name: 'Ecuador',
    'alpha-2': 'EC',
    'alpha-3': 'ECU',
    uuid: 'c677da04-7e5b-4ff0-adee-72013adc2af6',
    multiplierGroup: 2,
  },
  {
    name: 'Greece',
    'alpha-2': 'GR',
    'alpha-3': 'GRC',
    uuid: 'a1cb9439-45e0-4b59-8885-13d0ba107b08',
    multiplierGroup: 4,
  },
  {
    name: 'Ireland',
    'alpha-2': 'IE',
    'alpha-3': 'IRL',
    uuid: '77ed4896-68cc-4c8d-ab7c-18469c07b1e0',
    multiplierGroup: 4,
  },
  {
    name: 'Iceland',
    'alpha-2': 'IS',
    'alpha-3': 'ISL',
    uuid: '5c83431f-b151-4266-9c5f-86ec9363cc3b',
    multiplierGroup: 4,
  },
  {
    name: 'Italy',
    'alpha-2': 'IT',
    'alpha-3': 'ITA',
    uuid: '1592e8d2-75e0-4ab3-9ca7-dae0615cc8a7',
    multiplierGroup: 2,
  },
  {
    name: 'Japan',
    'alpha-2': 'JP',
    'alpha-3': 'JPN',
    uuid: 'ff48fcf3-9eb3-4621-9456-40528c36132c',
    multiplierGroup: 4,
  },
  {
    name: 'Kenya',
    'alpha-2': 'KE',
    'alpha-3': 'KEN',
    uuid: 'f5bb7d61-ebb7-4700-bc31-73710ace5a80',
    multiplierGroup: 4,
  },
  {
    name: 'Cambodia',
    'alpha-2': 'KH',
    'alpha-3': 'KHM',
    uuid: 'c44ecf69-992f-44bd-b46f-415c9a4db173',
    multiplierGroup: 3,
  },
  {
    name: 'Laos',
    'alpha-2': 'LA',
    'alpha-3': 'LAO',
    uuid: '627b3e64-71b4-47a6-a429-5685228d29bc',
    multiplierGroup: 3,
  },
  {
    name: 'Sri Lanka',
    'alpha-2': 'LK',
    'alpha-3': 'LKA',
    uuid: '4571915c-fc4e-4180-a54d-3976ae323566',
    multiplierGroup: 3,
  },
  {
    name: 'Mexico',
    'alpha-2': 'MX',
    'alpha-3': 'MEX',
    uuid: '60b539ff-e520-4f15-97dd-67a8cde04873',
    multiplierGroup: 2,
  },
  {
    name: 'Mauritius',
    'alpha-2': 'MU',
    'alpha-3': 'MUS',
    uuid: '0a53f930-7f02-49a5-a739-ec890b592a3d',
    multiplierGroup: 1,
  },
  {
    name: 'Malaysia',
    'alpha-2': 'MY',
    'alpha-3': 'MYS',
    uuid: 'c5a58bff-5494-4918-8f31-7ee64f4ec2e4',
    multiplierGroup: 3,
  },
  {
    name: 'Namibia',
    'alpha-2': 'NA',
    'alpha-3': 'NAM',
    uuid: '67a3fac0-fd58-40f3-b65f-11cbd8487d51',
    multiplierGroup: 2,
  },
  {
    name: 'New Zealand',
    'alpha-2': 'NZ',
    'alpha-3': 'NZL',
    uuid: '5c8337ee-dfdd-431d-a3b4-3e8daa8006b0',
    multiplierGroup: 2,
  },
  {
    name: 'Panama',
    'alpha-2': 'PA',
    'alpha-3': 'PAN',
    uuid: '86bc96e3-14e6-46f1-b3be-41abfef6de20',
    multiplierGroup: 2,
  },
  {
    name: 'Peru',
    'alpha-2': 'PE',
    'alpha-3': 'PER',
    uuid: 'ccbe4431-14a8-4eb8-81a1-f77e0671c935',
    multiplierGroup: 2,
  },
  {
    name: 'Seychelles',
    'alpha-2': 'SC',
    'alpha-3': 'SYC',
    uuid: '82c39059-45b7-4885-928c-c3ee5c66b20f',
    multiplierGroup: 4,
  },
  {
    name: 'Thailand',
    'alpha-2': 'TH',
    'alpha-3': 'THA',
    uuid: '19a93701-05ab-433a-83a9-15b135e548c7',
    multiplierGroup: 3,
  },
  {
    name: 'Tanzania',
    'alpha-2': 'TZ',
    'alpha-3': 'TZA',
    uuid: '0a48dec9-d60e-41d8-b2c7-4cb2b04dbb50',
    multiplierGroup: 1,
  },
  {
    name: 'United States',
    'alpha-2': 'US',
    'alpha-3': 'USA',
    uuid: '1f0e3970-d3b8-4af8-ba72-33898fc408da',
    multiplierGroup: 4,
  },
  {
    name: 'Vietnam',
    'alpha-2': 'VN',
    'alpha-3': 'VNM',
    uuid: '6e2ed26c-337f-4dbd-b73b-891ba1d96ec0',
    multiplierGroup: 3,
  },
  {
    name: 'South Africa',
    'alpha-2': 'ZA',
    'alpha-3': 'ZAF',
    uuid: 'c44b8127-1529-4df0-ad52-bc084f5df7ee',
    multiplierGroup: 1,
  },
];

export const whenToTravelData = {
  'c44b8127-1529-4df0-ad52-bc084f5df7ee': {
    [Locale.De]: {
      text1: 'Die Jahreszeiten sind entgegengesetzt zu unseren.',
      text2: 'Im europäischen Frühling und Herbst sind die Temperaturen angenehm mild.',
      text3: `Walbeobachtung: von Juli bis Oktober \n Safari im Nord-Osten: von Mai bis Oktober \n Safari im Süden (Garden Route): September bis April`,
    },
    [Locale.Fr]: {
      text1: 'Les saisons en Afrique du Sud sont opposées aux nôtres.',
      text2: "Lors du printemps et et de l'automne européens, les températures en Afrique du Sud sont douces.",
      text3: `Observation des baleines : de juillet à octobre \n Safari dans le nord-est : de mai à octobre`,
    },
  },
  '5c83431f-b151-4266-9c5f-86ec9363cc3b': {
    [Locale.De]: {
      text1: 'Das Klima ist recht mild und die Temperaturen nicht zu kalt.',
      text2: 'Im Sommer ist das Wetter am wärmsten und es gibt kaum Regen.',
      text3: 'Polarlichter: von September bis März \n Wandern: von Juni bis September',
    },
    [Locale.Fr]: {
      text1: 'Le climat est généralement doux.',
      text2: "L'été en Islande offre un climat chaud et de rares précipitations.",
      text3: `Aurores boréales : de septembre à mars \n Randonnées : de juin à septembre`,
    },
  },
  'a1ed5267-f937-47ed-8e9e-973edf32930e': {
    [Locale.De]: {
      text1:
        'Es gibt eine Trockenzeit zwischen Ende November und April und eine Regenzeit zwischen Mai und Mitte November.',
      text2:
        'Zwischen Dezember und April. Einen weiteren empfehlenswerten Reisezeitraum bietet Costa Rica zwischen Regen- und Trockenzeit im Juli und August',
      text3:
        'Surfen: von Mai bis November \n Meeresschildkröten: von Dezember bis Mai \n Walbeobachtung: Ende Juli bis September & Dezember bis März',
    },
    [Locale.Fr]: {
      text1:
        'Il existe une saison sèche entre fin novembre et avril et une saison des pluies entre mai et mi-novembre.',
      text2:
        'La meilleure période pour visiter le Costa Rica se situe entre décembre et avril. Le Costa Rica offre une autre période de voyage en juillet et en août, entre la saison sèche et la saison des pluies.',
      text3: `Surf : de mai à novembre \n Observation des tortues de mer : de décembre à mai \n Observation des baleines : de fin juillet à septembre et de décembre à mars`,
    },
  },
  '82c39059-45b7-4885-928c-c3ee5c66b20f': {
    [Locale.De]: {
      text1:
        'Die zwei Monsunwinde des Jahres: der Südwestmonsun (Mai-Oktober) bringt Trockenheit und Winde, der Nordostmonsun (November-April) Niederschläge. Dies ist jedoch ein ganzjähriges Reiseziel.',
      text2: 'Während des Südostmonsuns von Mai bis Oktober mit Wind und wenig Regen.',
      text3: 'Segeln: von Mai bis Oktober \n Wandern: von Mai bis September / Oktober',
    },
    [Locale.Fr]: {
      text1:
        "Il existe deux périodes de mousson dans l'année : une période de sécheresse (mai-octobre) et une période de précipitations (novembre-avril). Cependant, la destination se visite toute l'année. ",
      text2: "La meilleure période pour visiter les Seychelles s'étend de mai à octobre, lors de la saison sèche.",
      text3: `Voile : de mai à octobre \n Randonnées : de mai à septembre/octobre`,
    },
  },
  '0a48dec9-d60e-41d8-b2c7-4cb2b04dbb50': {
    [Locale.De]: {
      text1:
        'Die große Regenzeit herrscht zwischen März und Mai, die kleine mit weniger Regentagen im Oktober und November.',
      text2: 'In den Trockenzeiten von Juni bis Oktober sowie der Januar und Februar.',
      text3:
        'Safari: von Dezember bis März, und Juni bis Oktober \n Serengeti „Große Tierwanderung“: von Januar bis März',
    },
    [Locale.Fr]: {
      text1:
        'La grande saison des pluies domine entre mars et mai. Il y a également une petite saison des pluies en octobre et novembre, avec moins de jours de pluie.',
      text2: 'Visitez la Tanzanie pendant les saisons sèches, de juin à octobre et de janvier à février.',
      text3: `Safari : de décembre à mars et de juin à octobre \n Migration des animaux à Serengeti : de janvier à mars`,
    },
  },
  '19a93701-05ab-433a-83a9-15b135e548c7': {
    [Locale.De]: {
      text1:
        'Es gibt drei Jahreszeiten: Sommerzeit von März bis Mai, Regenzeit im Juni und Oktober und Trockenzeit von November bis Februar.',
      text2: 'In der Trockenzeit von November bis März sind die Temperaturen angenehm warm und es gibt kaum Regen.',
      text3: 'Strandurlaub: Dezember bis April',
    },
    [Locale.Fr]: {
      text1:
        "La Thaïlande compte trois saisons : l'été de mars à mai, la saison des pluies de juin à octobre et la saison sèche de novembre à février.",
      text2:
        'Pendant la saison sèche, de novembre à mars, les températures sont agréables et les précipitations se font rares.',
      text3: `Vacances à la plage : de décembre à avril`,
    },
  },
};

export const destinationsToShowStars: DestinationsToShowStarsType = {
  '543fe461-7db6-4fcc-9ea2-b67f90a3dba3': {
    destination: 'Botswana',
    uuid: '543fe461-7db6-4fcc-9ea2-b67f90a3dba3',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  'c44ecf69-992f-44bd-b46f-415c9a4db173': {
    destination: 'Cambodia',
    uuid: 'c44ecf69-992f-44bd-b46f-415c9a4db173',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '6e2ed26c-337f-4dbd-b73b-891ba1d96ec0': {
    destination: 'Vietnam',
    uuid: '6e2ed26c-337f-4dbd-b73b-891ba1d96ec0',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '26f999aa-9248-41f0-a6c1-80ef71d07b6c': {
    destination: 'Colombia',
    uuid: '26f999aa-9248-41f0-a6c1-80ef71d07b6c',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  'fcba3c85-e37d-42a9-b1e6-c35a4d878d2e': {
    destination: 'Croatia',
    uuid: 'fcba3c85-e37d-42a9-b1e6-c35a4d878d2e',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '2f791cbc-53cc-49c3-8d13-9f268ab07051': {
    destination: 'Indonesia',
    uuid: '2f791cbc-53cc-49c3-8d13-9f268ab07051',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  'f5bb7d61-ebb7-4700-bc31-73710ace5a80': {
    destination: 'Kenya',
    uuid: 'f5bb7d61-ebb7-4700-bc31-73710ace5a80',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  'c5a58bff-5494-4918-8f31-7ee64f4ec2e4': {
    destination: 'Malaysia',
    uuid: 'c5a58bff-5494-4918-8f31-7ee64f4ec2e4',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '596b7bdc-4648-45d0-8bef-e4f2806a2b18': {
    destination: 'Portugal',
    uuid: '596b7bdc-4648-45d0-8bef-e4f2806a2b18',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '4571915c-fc4e-4180-a54d-3976ae323566': {
    destination: 'Sri Lanka',
    uuid: '4571915c-fc4e-4180-a54d-3976ae323566',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '19a93701-05ab-433a-83a9-15b135e548c7': {
    destination: 'Thailand',
    uuid: '19a93701-05ab-433a-83a9-15b135e548c7',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '627b3e64-71b4-47a6-a429-5685228d29bc': {
    destination: 'Laos',
    uuid: '627b3e64-71b4-47a6-a429-5685228d29bc',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.TRANSFER,
  },
  '6ab1339e-3b80-494a-9e67-025e6dfd6c31': {
    destination: 'Asia',
    uuid: '6ab1339e-3b80-494a-9e67-025e6dfd6c31',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '93001bb1-1c3b-4934-a61b-7d6d7706bf14': {
    destination: 'California',
    uuid: '93001bb1-1c3b-4934-a61b-7d6d7706bf14',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '870e06b2-19e7-4fbb-8f27-6a13c4384b94': {
    destination: 'Canada',
    uuid: '870e06b2-19e7-4fbb-8f27-6a13c4384b94',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  'c6d15063-4d25-430b-a50f-8817717e8dd0': {
    destination: 'Caribbean',
    uuid: 'c6d15063-4d25-430b-a50f-8817717e8dd0',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  'a1ed5267-f937-47ed-8e9e-973edf32930e': {
    destination: 'Costa Rica',
    uuid: 'a1ed5267-f937-47ed-8e9e-973edf32930e',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  'a58d5b91-64bb-446c-9152-88ee37a825c4': {
    destination: 'England',
    uuid: 'a58d5b91-64bb-446c-9152-88ee37a825c4',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  'cf5dfb95-b57e-490d-b504-a13f711b0649': {
    destination: 'Europe',
    uuid: 'cf5dfb95-b57e-490d-b504-a13f711b0649',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '358e6ac7-c7b3-40e1-aa20-803afd410d87': {
    destination: 'Florida',
    uuid: '358e6ac7-c7b3-40e1-aa20-803afd410d87',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  'e05d213a-8149-4294-bcd6-ba7e1c38521e': {
    destination: 'French Polynesia',
    uuid: 'e05d213a-8149-4294-bcd6-ba7e1c38521e',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '3d78113b-d1c7-4de2-aa40-3935431e4e57': {
    destination: 'Hawaii',
    uuid: '3d78113b-d1c7-4de2-aa40-3935431e4e57',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '5c83431f-b151-4266-9c5f-86ec9363cc3b': {
    destination: 'Iceland',
    uuid: '5c83431f-b151-4266-9c5f-86ec9363cc3b',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '60b539ff-e520-4f15-97dd-67a8cde04873': {
    destination: 'Mexico',
    uuid: '60b539ff-e520-4f15-97dd-67a8cde04873',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '607a08df-9f11-41e6-a485-e64c8a0675ab': {
    destination: 'Scotland',
    uuid: '607a08df-9f11-41e6-a485-e64c8a0675ab',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  '4c083bd7-2e64-4cdf-8570-75038899392c': {
    destination: 'Wales',
    uuid: '4c083bd7-2e64-4cdf-8570-75038899392c',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
  'ccbe4431-14a8-4eb8-81a1-f77e0671c935': {
    destination: 'Peru',
    uuid: 'ccbe4431-14a8-4eb8-81a1-f77e0671c935',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.PRIVATE_GUIDED,
  },
  '1f0e3970-d3b8-4af8-ba72-33898fc408da': {
    destination: 'United States',
    uuid: '1f0e3970-d3b8-4af8-ba72-33898fc408da',
    duration: durationOptionsEnum.TWO_WEEKS,
    travelType: possibleTravelTypesEnum.SELF_DRIVE,
  },
};
