import PhoneIcon from '@tourlane/iconography/Glyphs/Navigation/Phone';
import { COLORS, SvgIcon } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { TalkToUs } from 'components/TalkToUs';
import { currentMarket, isInOperatingMarket } from 'const';
import { useAppContext } from 'reducer';
import { t } from 'utils/translations';

import { getAgentShiftsTranslations } from '../../utils/getAgentShiftsTranslations';
import { useAgentShifts } from '../TalkToUs/useAgentShifts';
import { HeaderProps, StyledLogo } from './Header';

const getTalkToUsColor = (isTransparent: boolean | undefined, isAgentInShift: boolean) => {
  if (isTransparent) {
    return COLORS.SENSATION_WHITE;
  }

  if (isAgentInShift) {
    return COLORS.ADVENTURE_GREEN;
  }

  return COLORS.ELEMENT_GRAY;
};

export const LinearFlowContent = ({ transparent }: HeaderProps) => {
  const {
    meta: { salesConfig },
    isEmbedded,
    isLoadingDestination,
  } = useAppContext();
  const { isDuringAgentShift, agentShifts, phoneNumber } = useAgentShifts(salesConfig);

  const talkToUsCopy = t('talkToUs');

  const agentShiftTranslations = agentShifts.map(shift => getAgentShiftsTranslations(shift));

  const talkToUsTranslations = {
    ...talkToUsCopy,
    agentShifts: agentShiftTranslations,
    phoneNumber,
  };

  const isInTheOperatingMarket = isInOperatingMarket(currentMarket.locale);

  const canShowTalkToUs = !isLoadingDestination && !isEmbedded && isInTheOperatingMarket;

  const iconColor = getTalkToUsColor(transparent, isDuringAgentShift);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={[16, null, 24, 32, 40]}
      py={[16, 20, 24, null, 32]}
      overflow="hidden"
      maxHeight={[52, 60, 68, 72, 92]}
    >
      <a href="/" aria-label="Tourlane" style={{ lineHeight: 1 }}>
        <StyledLogo data-cy="tourlane-logo" color={transparent ? COLORS.SENSATION_WHITE : undefined} />
      </a>
      {canShowTalkToUs && (
        <TalkToUs
          i18n={talkToUsTranslations}
          isDuringAgentShift={isDuringAgentShift}
          offset={16}
          width={['calc(100vw - 32px)', 320, 360, 380, 420]}
        >
          <SvgIcon
            size={[12, null, 16, null, 20]}
            color={iconColor}
            style={{
              borderRadius: '50%',
              border: `1px solid ${iconColor}`,
              padding: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-cy="talk-to-us"
          >
            <PhoneIcon />
          </SvgIcon>
        </TalkToUs>
      )}
    </Box>
  );
};
