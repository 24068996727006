import { useEffect, useState } from 'react';

import { currentMarket, destinationSlug, environment } from 'const';
import { ACTION_TYPE, Destination, Dispatch } from 'reducer';
import { api } from 'utils/api';
import { isValidRoute } from 'utils/routing';

import { destinationIsoLocalStorageKey, saveToLocalStorage, trackingLocalStorageKey } from '../utils/localStorage';

const reduceTracking = (tracking: Array<{ key: string; value: string }>) =>
  tracking.reduce(
    (acc, { key, value }) => ({
      ...acc,
      [key]: value.includes(', ') ? value.split(', ') : value,
    }),
    {}
  );

const formatTracking = (tracking: any, destinationName: string) => {
  return {
    city: tracking.city || destinationName,
    region: tracking.region || destinationName,
    country: tracking.country || destinationName,
    ...(tracking ? reduceTracking(tracking) : {}),
  };
};

export const useFetchDestination = (dispatch: Dispatch) => {
  const [destinationFetchStatus, setDestinationFetchStatus] = useState<number>();
  const [destination, setDestination] = useState<Destination | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!isValidRoute()) {
        setDestinationFetchStatus(404);
        return;
      }

      const url = `${environment.API_ENDPOINT_DM}/api/v2/markets/${currentMarket.market}/destinations/${destinationSlug}`;

      try {
        const {
          name,
          uuid,
          imageUrl,
          activities,
          accommodationTypes: accommodations,
          pois,
          tracking,
          budgetDefinitions,
          alpha2,
          alpha3,
          parentDestination,
        } = await api({
          url,
        });
        const destinationTracking = formatTracking(tracking, name);
        const destinationIso = { alpha2, alpha3 };
        const destination = {
          name,
          uuid,
          imageUrl,
          tracking: destinationTracking,
          budgetDefinitions,
          parentDestination,
        };

        setDestination(destination);

        dispatch({
          type: ACTION_TYPE.STORE_META,
          payload: {
            destination,
          },
        });

        dispatch({
          type: ACTION_TYPE.STORE_AVAILABLE_ANSWERS,
          payload: {
            activities,
            accommodations,
            pois,
          },
        });
        setDestinationFetchStatus(200);
        saveToLocalStorage(trackingLocalStorageKey, destinationTracking);
        saveToLocalStorage(destinationIsoLocalStorageKey, destinationIso);
      } catch (err: any) {
        setDestinationFetchStatus(err.status);
      } finally {
        dispatch({ type: ACTION_TYPE.LOADING_DESTINATION, payload: false });
      }
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { destinationFetchStatus, destination };
};
