import { css, keyframes } from 'styled-components';

const PHONE_ANIMATION_DURATION = 420;

const phoneAnimationKeyframes = keyframes`
  0% {
    transform: scale(0.2);
  }
  57% {
    transform: scale(1.2);
  }
  71% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const phoneAnimation = css`
  animation-name: ${phoneAnimationKeyframes};
  animation-duration: ${PHONE_ANIMATION_DURATION}ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;
