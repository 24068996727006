import { useEffect, useState } from 'react';

import { AlarmButton, Flex, H3, Modal, ModalBody, ModalFooter, ModalHeader, SvgIcon } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import styled from 'styled-components';

import { useFeatureFlagContext } from '../../components/FeatureFlag';
import { FLAG_NAME, FLAG_VALUE } from '../../const';
import { getQueryParams } from '../queryParams';
import { DuckIcon } from './DuckIcon';
import { DuckToolsPanel } from './DuckToolsPanel';

const StyledBox = styled(Box)<{ isOpen: boolean }>`
  ${props =>
    props.isOpen &&
    `
      background: rgba(63, 65, 68, 0.9);
      width: min-content;
      border-radius: 8px;
    `}
`;

const StyledSvgIcon = styled(SvgIcon)`
  background: #ff3d13;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  padding: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1000;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.75) 0 5px 15px;
    scale: 1.05;
  }

  :active {
    transform: translateY(4px);
  }
`;

export const DuckTools = () => {
  const [flagName, flagValue] = getQueryParams(window.location.search, [FLAG_NAME, FLAG_VALUE]);

  const { setFlags } = useFeatureFlagContext();

  const [isDuckToolsOpen, setDuckToolsOpen] = useState(false);

  useEffect(() => {
    if (flagName && flagValue) {
      setFlags({ [flagName]: flagValue });
    }
  }, [flagName, flagValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledBox position="absolute" bottom={10} left={10} isOpen={isDuckToolsOpen} aria-hidden="true">
      {!isDuckToolsOpen && (
        <StyledSvgIcon onClick={() => setDuckToolsOpen(true)}>
          <SvgIcon size={48}>
            <DuckIcon />
          </SvgIcon>
        </StyledSvgIcon>
      )}
      <Modal animate isOpen={isDuckToolsOpen} onClose={() => setDuckToolsOpen(false)} closeButtonVariant="circle">
        <ModalHeader>
          <H3>Duck Tools 🦆</H3>
        </ModalHeader>
        <ModalBody>
          <DuckToolsPanel
            flagNameFromParams={flagName}
            flagValueFromParams={flagValue}
            onFlagOverride={({ flagName, flagValue }) => setFlags({ [flagName]: flagValue })}
          />
        </ModalBody>
        <ModalFooter>
          <Flex direction="ltr" justify="end">
            <AlarmButton
              onClick={() => {
                window.location.href = '/eee/suedafrika/enquiry/passengers';
              }}
            >
              Reset All
            </AlarmButton>
          </Flex>
        </ModalFooter>
      </Modal>
    </StyledBox>
  );
};
