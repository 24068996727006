import { COLORS, MEDIA } from '@tourlane/tourlane-ui';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStylesWithNormalize = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); // removing blue background on mobile touch
  }

  html,
  body,
  #root {
    min-width: 320px;
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *::selection {
    color: ${COLORS.SENSATION_WHITE};
    background-color: ${COLORS.ADVENTURE_GREEN};
  }

  /* Override normalize.css. Impacts disabled button from TUI */
  button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none !important;
  }

  .uspTestLoaderIcon {
    padding: 0 8px;
    fill: none !important;

    ${MEDIA.from.sm`
    padding: 0 12px;
  `}
    
    ${MEDIA.from.xl`
    padding: 0 16px;
  `}
  }
`;

function GlobalStylesWithNormalizeAndTypography() {
  return (
    <div>
      <GlobalStylesWithNormalize />
    </div>
  );
}

export default GlobalStylesWithNormalizeAndTypography;
