import { FC, useCallback, useEffect, useState } from 'react';

import { Button, Dropdown } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { FeatureFlags, FlagValuesExperiment } from 'components/FeatureFlag';
import { FLAG_NAME, FLAG_VALUE, Market, currentMarket, destinationSlug, environment } from 'const';
import { useAppContext } from 'reducer';
import { STEP_KEYS } from 'steps';
import { stepsConfig } from 'stepsConfig';
import { api } from 'utils/api';
import { deleteQueryParams, setQueryParams } from 'utils/queryParams';

import { getDuckToolValue } from './utils';

export type FlagToOverride = {
  flagName: string;
  flagValue: string;
};

type DestinationType = {
  name: string;
  slug: string;
};

type Option = {
  value: string;
  label: string;
};

const defaultDestinationSlugs = {
  [Market.De]: 'suedafrika',
  [Market.Fr]: 'afrique-du-sud',
  [Market.Nl]: 'zuid-afrika',
  [Market.Gb]: 'south-africa',
  [Market.Us]: 'south-africa',
};

const getValueForKeys = (value: any, keys: string[]) => {
  let existingValue;
  let i = 0;

  while (!existingValue && i < keys.length) {
    existingValue = value[keys[i]];
    i += 1;
  }

  return existingValue;
};

const mapObjectToOptions = (objectToMap: any, keys?: string[]): Option[] => {
  const objectValues: any[] = Object.values(objectToMap);

  return objectValues.map(value => {
    const stringValue = keys ? getValueForKeys(value, keys) : value;

    return {
      value: stringValue,
      label: stringValue,
    };
  });
};

interface DuckToolsPanelProps {
  flagNameFromParams: string | null;
  flagValueFromParams: string | null;
  onFlagOverride: (flag: FlagToOverride) => void;
}

export const DuckToolsPanel: FC<DuckToolsPanelProps> = ({
  flagNameFromParams,
  flagValueFromParams,
  onFlagOverride,
}) => {
  const { initialStep } = useAppContext();

  const [destinationOptions, setDestinationOptions] = useState<Option[]>([]);
  const [flagToOverride, setFlagToOverride] = useState<FlagToOverride>({
    flagName: flagNameFromParams || '',
    flagValue: flagValueFromParams || '',
  });

  const marketOptions = mapObjectToOptions(Market);
  const stepOptions = mapObjectToOptions(stepsConfig, ['id']);
  const featureFlagNameOptions = mapObjectToOptions({ ...FeatureFlags });
  const featureFlagValueOptions = mapObjectToOptions({
    ...FlagValuesExperiment,
  });

  const onMarketChange = useCallback((name: string, value: Market) => {
    const queryParams = setQueryParams(window.location.search, [{ name, value }]);

    // whenever we change the market, we want to reset to default destination
    const newPath = `/eee/${defaultDestinationSlugs[value]}/enquiry/${initialStep}`;
    window.location.href = `${window.location.origin}${newPath}?${queryParams.toString()}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFlagOverride = () => {
    const { flagName, flagValue } = flagToOverride;

    if (!flagName || !flagValue) {
      return;
    }

    window.location.search = setQueryParams(window.location.search, [
      { name: FLAG_NAME, value: flagName },
      { name: FLAG_VALUE, value: flagValue },
    ]);

    onFlagOverride(flagToOverride);
  };

  const resetFlagOverride = () => {
    window.location.search = deleteQueryParams(window.location.search, [FLAG_NAME, FLAG_VALUE]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${environment.API_ENDPOINT_DM}/api/v1/markets/${currentMarket.market}/destinations`;

      try {
        const { records }: { records: DestinationType[] } = await api({ url });
        const mappedDestinations = mapObjectToOptions(records, ['slug', 'name']);

        setDestinationOptions(mappedDestinations);
      } catch (err: any) {
        console.error(err.toString()); // eslint-disable-line no-console
      }
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" flexDirection={['column', null, 'row']} justifyContent="space-around">
      <Box>
        <Box mb={16} width={[250, null, null, null, 300]}>
          <Dropdown
            shrinkPlaceholder
            options={marketOptions}
            value={getDuckToolValue('market')}
            placeholder="Change market"
            notClearable
            menuZIndex={1000}
            parentQuerySelector="body"
            onChange={(selectedMarket: Market) => {
              onMarketChange('market', selectedMarket);
            }}
          />
        </Box>
        <Box mb={16} width={[250, null, null, null, 300]}>
          <Dropdown
            shrinkPlaceholder
            options={destinationOptions}
            value={destinationSlug}
            placeholder="Go to destination"
            notClearable
            menuZIndex={1000}
            parentQuerySelector="body"
            onChange={(destination: DestinationType) => {
              window.location.pathname = `/eee/${destination}/enquiry/${initialStep}`;
            }}
          />
        </Box>
        <Box mb={16} width={[250, null, null, null, 300]}>
          <Dropdown
            shrinkPlaceholder
            options={stepOptions}
            placeholder="Jump to step"
            notClearable
            menuZIndex={1000}
            parentQuerySelector="body"
            onChange={(stepId: STEP_KEYS) => {
              window.location.pathname = `${stepsConfig[stepId].slug}`;
            }}
          />
        </Box>
      </Box>
      <Box>
        <Box mb={16} width={[250, null, 300, 400]}>
          <Dropdown
            shrinkPlaceholder
            options={featureFlagNameOptions}
            placeholder="Jump to AB Test"
            notClearable
            menuZIndex={1000}
            parentQuerySelector="body"
            value={flagToOverride.flagName}
            onChange={(flagName: FeatureFlags) => {
              setFlagToOverride({ ...flagToOverride, flagName });
            }}
          />
        </Box>
        <Box mb={16} width={[250, null, 300, 400]}>
          <Dropdown
            shrinkPlaceholder
            options={featureFlagValueOptions}
            placeholder="Flag Value"
            notClearable
            menuZIndex={1000}
            parentQuerySelector="body"
            value={flagToOverride.flagValue}
            onChange={(flagValue: FlagValuesExperiment) => {
              setFlagToOverride({ ...flagToOverride, flagValue });
            }}
          />
        </Box>
        <Box width={[250, null, 300, 400]} display={['inline-flex', null, null, 'inherit']}>
          <Button style={{ marginRight: 16 }} onClick={() => applyFlagOverride()}>
            Set Flag
          </Button>
          <Button onClick={() => resetFlagOverride()}>Reset flag</Button>
        </Box>
      </Box>
    </Box>
  );
};
