import { FC, ReactNode } from 'react';

import { Base, Big, MEDIA, Strong, SvgIcon } from '@tourlane/tourlane-ui';
import styled from 'styled-components';

interface StepItemProps {
  title?: string;
  content?: ReactNode | string;
  icon: FC;
  iconSize: number | Record<string, any>;
  className?: string;
}

const StepItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  ${MEDIA.from.md`
    flex-direction: row;
  `}
`;

const StyledBig = styled(Big)`
  margin-top: 12px;
  ${MEDIA.from.md`
    margin-top: 0;
  `}
`;

const Wrapper = styled.div`
  ${MEDIA.from.md`
    margin-left: 24px;
    text-align: left;
  `}
`;

const BaseWithBoldSpan = styled(Base)`
  span {
    font-weight: bold;
  }
`;

export const StepItem: React.FC<StepItemProps> = ({ title, content, icon: Icon, iconSize, className }) => (
  <StepItemContainer className={className}>
    <SvgIcon size={iconSize}>
      <Icon />
    </SvgIcon>
    <Wrapper>
      <StyledBig>
        <Strong>{title}</Strong>
      </StyledBig>
      <BaseWithBoldSpan>{content}</BaseWithBoldSpan>
    </Wrapper>
  </StepItemContainer>
);
