import { QuestionContextTypes, basePath, datesApproxQuestionsEnum } from 'const';
import { STEPS, STEP_KEYS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { t } from 'utils/translations';

import { getSeasonsInSequence } from './getSeasonsInSequence';

const availableAnswers = getSeasonsInSequence();

export const config = {
  id: STEPS.DATES_APPROX,
  idx: 3,
  questions: {
    [datesApproxQuestionsEnum.datesApprox]: {
      id: datesApproxQuestionsEnum.datesApprox,
      initialValue: availableAnswers[0].value,
      answerText: (value: FrontendAnswerValueType) => availableAnswers.find(a => a.value === value)?.title || '',
      availableAnswers,
    },
  },
  questionText: t('dates.approx.headline'),
  next: (): STEP_KEYS => STEPS.HIGHLIGHTS,
  slug: `${basePath}/dates-approximate`,
  context: {
    type: QuestionContextTypes.SINGLE_SELECT_PILL,
    values: availableAnswers.map(({ value }) => value),
  },
};
