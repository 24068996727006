import { H2, MEDIA } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { useAppContext } from 'reducer';
import styled from 'styled-components';
import { formatMainDestinationHeadline } from 'utils/translationHelpers';
import { t } from 'utils/translations';

const CustomBox = styled(Box)`
  height: 72px;

  ${MEDIA.from.lg`
     height: 76px;
  `};
`;

const CustomH2 = styled(H2)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  font-size: 18px;
  line-height: 30px;

  ${MEDIA.from.sm`
    font-size: 22px;
    line-height: 30px;
  `};

  ${MEDIA.from.md`
    font-size: 26px;
    line-height: 34px;
  `};

  ${MEDIA.from.lg`
    font-size: 22px;
    line-height: 30px;
  `};

  ${MEDIA.from.xl`
    font-size: 24px;
    line-height: 32px;
  `};
`;

export const EmbedContent = () => {
  const {
    meta: { destination },
  } = useAppContext();

  return (
    <CustomBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={[16, 32, 40, 32, 32]}
      py={[6, 6, 19, 8, 6]}
    >
      <Box>
        <CustomH2 data-cy="intro-headline-text">
          {t('activity.headline', {
            destination: formatMainDestinationHeadline(destination.name),
            interpolation: { escapeValue: false },
          })}
        </CustomH2>
      </Box>
    </CustomBox>
  );
};
