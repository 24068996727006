import { basePath, emailFormFieldsEnum, genderOptionsEnum } from 'const';
import { STEPS } from 'steps';
import { t } from 'utils/translations';

export const config = {
  id: STEPS.EMAIL,
  idx: 7,
  questionText: t('contactus.greetings'),
  initialValues: {
    [emailFormFieldsEnum.gender]: genderOptionsEnum.male,
  },
  next: () => STEPS.PHONE,
  slug: `${basePath}/email`,
};
