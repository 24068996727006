import { Locale, currentMarket } from 'const';
import dayjs from 'dayjs';
// these are required by dayjs.locale() to download locale specific info
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);
dayjs.locale(currentMarket.dayJsLocale);

export const firstDayOfWeek = dayjs().localeData().firstDayOfWeek();

export const calendarProps = {
  months: dayjs().localeData().months(),
  // @ts-ignore bug in plugin - dayjs().localeData().weekdays() doesn't work
  weekdaysLong: dayjs.weekdays(),
  weekdaysShort: dayjs().localeData().weekdaysShort(),
};

export const formatDate = (date: Date): string => {
  switch (currentMarket.locale) {
    case Locale.De:
      return dayjs(date).format('D. MMMM YYYY');
    case Locale.Gb:
    case Locale.Fr:
    case Locale.Us:
      return dayjs(date).format('D MMMM YYYY');
    case Locale.Nl:
      return dayjs(date).format('D MMMM YYYY').toLowerCase();
    default:
      return dayjs(date).format('DD MMMM YYYY');
  }
};

export default dayjs;
