import { FC } from 'react';

import { ProgressBar as Bar, COLORS, Logo, MEDIA } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { ProgressBar } from 'components/ProgressBar';
import { flowTypes } from 'const';
import { useAppContext } from 'reducer';
import { STEPS, STEP_KEYS } from 'steps';
import styled from 'styled-components';
import { getStepFromPathName } from 'utils/routing';
import { zIndex } from 'utils/zIndex';

import { EmbedContent } from './EmbedContent';
import { LinearFlowContent } from './LinearFlowContent';
import { SimpleFlowContent } from './SimpleFlowContent';

const Hr = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.LINE_GRAY};
`;

export const StyledLogo = styled(Logo)`
  width: 93px;
  height: 16px;

  ${MEDIA.from.sm`
    width: 116px;
    height: 20px;
  `};

  ${MEDIA.from.md`
    width: 139px;
    height: 24px;
  `};

  ${MEDIA.from.xl`
    width: 185px;
    height: 32px;
  `};
`;

export type HeaderProps = {
  transparent?: boolean;
  type?: 'fallback';
};

const StaticHeader = () => (
  <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={[16, null, 24, 32, 40]}
      py={[16, 20, 24, null, 32]}
      overflow="hidden"
      maxHeight={[52, 60, 68, 72, 92]}
    >
      <a href="/" aria-label="Tourlane" style={{ lineHeight: 1 }}>
        <StyledLogo data-cy="tourlane-logo" />
      </a>
    </Box>
    <Bar
      progress={0}
      height={{ xs: 4, md: 6 }}
      corners
      data-cy="progress-bar"
      aria-label="Questionnaire progress"
      animationDuration={500}
    />
  </>
);

export const Header: FC<HeaderProps> = ({ transparent, type }) => {
  const {
    stepsConfig,
    meta: { flowType },
    isEmbedded,
    initialStep,
  } = useAppContext();
  const { pathname } = window.location;

  const currentStep = getStepFromPathName(stepsConfig, pathname) || initialStep;
  const splitFormSteps: Array<STEP_KEYS> = [STEPS.EMAIL, STEPS.PHONE, STEPS.SUCCESS];
  const isSplitForm = splitFormSteps.includes(currentStep);

  const canDisplayProgressBar = !isSplitForm;

  return (
    <Box
      as="header"
      width="100%"
      backgroundColor={transparent ? 'transparent' : COLORS.SENSATION_WHITE}
      position={transparent ? 'absolute' : 'static'}
      zIndex={zIndex.header}
    >
      {type === 'fallback' ? (
        <StaticHeader />
      ) : (
        <>
          {flowType === flowTypes.SIMPLIFIED && <SimpleFlowContent />}
          {flowType === flowTypes.LINEAR &&
            (isEmbedded ? <EmbedContent /> : <LinearFlowContent transparent={transparent} />)}
          {!transparent && (canDisplayProgressBar ? <ProgressBar /> : <Hr />)}
        </>
      )}
    </Box>
  );
};
