import { currentMarket } from 'const';

const formatFRPrepositions = (destinationName: string) => {
  switch (destinationName) {
    case 'Cambodge':
    case 'Costa Rica':
    case 'Chili':
    case 'Kenya':
    case 'Viêt Nam':
    case 'Canada':
    case 'Pérou':
    case 'Vietnam':
    case 'Laos':
    case 'Mexique':
    case 'Japon':
    case 'Brésil':
    case 'Texas':
      return `au ${destinationName}`;
    case 'États-Unis':
    case 'Seychelles':
    case 'Îles Cook':
      return `aux ${destinationName}`;
    case 'La Réunion':
    case 'Fidji':
      return `à ${destinationName}`;
    case 'dans le monde':
      return `${destinationName}`;
    case 'Maurice':
      return `à l'île ${destinationName}`;
    default:
      return `en ${destinationName}`;
  }
};

const formatDEPrepositions = (destinationName: string) => {
  switch (destinationName) {
    case 'Cookinseln':
    case 'Seychellen':
    case 'Fidschi':
    case 'Bali':
      return `auf ${destinationName}`;
    case 'Vereinigte Arabische Emirate':
      return 'in den Vereinigten Arabischen Emiraten';
    default:
      return `in ${destinationName}`;
  }
};

export const formatMainDestinationHeadline = (destinationName: string) => {
  if (currentMarket.locale === 'fr-FR') {
    return formatFRPrepositions(destinationName);
  }

  return destinationName;
};

export const formatDestinationPreposition = (destinationName: string) => {
  if (currentMarket.locale === 'de-DE') {
    return formatDEPrepositions(destinationName);
  }

  if (currentMarket.locale === 'fr-FR') {
    return formatFRPrepositions(destinationName);
  }

  return destinationName;
};
