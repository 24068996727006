import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

const CIRCLE_C = 40;
const CIRCLE_R = 38;
const STROKE_WIDTH = 3;
const DASH_LINE_LENGTH = 240;
const LINE_ANIMATION_DURATION = 3000;

interface CircleProps {
  color: string;
}

const StyledSvg = styled.svg`
  transform: scale(-1) rotateY(180deg);
`;

export const Circle: FC<CircleProps> = ({ color }) => {
  const [strokeDasharray, setStrokeDasharray] = useState(DASH_LINE_LENGTH);

  useEffect(() => {
    const interval = setInterval(() => {
      setStrokeDasharray(strokeDasharray === DASH_LINE_LENGTH * 2 ? 0 : strokeDasharray + 1);
    }, LINE_ANIMATION_DURATION / (DASH_LINE_LENGTH * 2));
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <StyledSvg>
      <circle
        cx={CIRCLE_C}
        cy={CIRCLE_C}
        r={CIRCLE_R}
        strokeWidth={STROKE_WIDTH}
        stroke={color}
        fill="none"
        strokeDasharray={DASH_LINE_LENGTH}
        strokeDashoffset={strokeDasharray}
      />
    </StyledSvg>
  );
};

export default Circle;
