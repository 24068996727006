import { SVGProps } from 'react';

import { StepConfigType } from 'stepsConfig.types';

export const addIcons = ({
  question,
  icons,
  stepConfig,
}: {
  question: string;
  icons: Record<string, React.FC<SVGProps<SVGSVGElement>>>;
  stepConfig: StepConfigType;
}): StepConfigType => {
  const availableAnswersWithIcons = stepConfig.questions![question].availableAnswers!.map(answer => ({
    ...answer,
    icon: icons[answer.icon as string],
    iconInactive: icons[answer.iconInactive as string],
    iconActive: icons[answer.iconActive as string],
  }));

  // eslint-disable-next-line no-param-reassign
  stepConfig.questions![question].availableAnswers = availableAnswersWithIcons;
  return stepConfig;
};
