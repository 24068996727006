import React from 'react';

import { COLORS } from '@tourlane/tourlane-ui';

export function DuckIcon() {
  return (
    <svg height={35} width={35} viewBox="0 0 443.879 443.879">
      <circle cx="139.795" cy="102.299" r="5.84" />
      <path
        fill={COLORS.SENSATION_WHITE}
        d="M440.195 135.659a8 8 0 00-12.48-5.12c-.88.64-89.28 61.92-151.28 64-24 .64-34.56-3.6-36.56-8s1.44-8.48 2.48-9.92c23.04-32.72 30.4-62.32 21.92-88a74.801 74.801 0 00-45.44-44.96 183.995 183.995 0 00-56-10.32c-46.96 0-66.72 27.68-81.12 48-2 2.8-3.84 5.36-5.68 8l9.52 13.68 22.24 32a8.001 8.001 0 011.04 6.96l-6.88 21.12-5.12 16.48a10.56 10.56 0 011.36 13.52c-89.6 80-49.84 144.56-35.52 162.4 14.32 17.84 72 55.04 155.28 55.04a304.478 304.478 0 00110.96-21.84c145.52-56.8 111.68-251.12 111.28-253.04zm-300.4-11.76c-12.062 0-21.84-9.778-21.84-21.84s9.778-21.84 21.84-21.84c12.044.044 21.796 9.796 21.84 21.84 0 12.062-9.778 21.84-21.84 21.84zm250.88 154.16c-1.36 2.32-33.12 56.64-89.68 63.12l-.96.24a8.012 8.012 0 01-.88-16 103.038 103.038 0 0062-34.8 113.763 113.763 0 01-43.92-5.12 8 8 0 01.72-15.44c29.68-7.52 42.64-22.96 48-34.96a188.796 188.796 0 01-106.24 19.52 8.096 8.096 0 012.48-16 176.004 176.004 0 00112-26.8 8 8 0 0112.4 6.72 70.079 70.079 0 01-37.68 56 46.236 46.236 0 0030-7.28 8 8 0 0111.76 10.8z"
      />
      <path
        fill={COLORS.SENSATION_WHITE}
        d="M73.475 113.579l-9.04-12.96c-21.12 14.56-56 15.12-56 15.12a8 8 0 00-8 7.2 37.2 37.2 0 007.44 28.56c12 14.4 34.64 16 54.72 18a185.654 185.654 0 0120.08 2.48l4.72-15.2 4.96-16-18.88-27.2z"
      />
    </svg>
  );
}
