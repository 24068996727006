import { flowTypes } from 'const';

// https://camchenry.com/blog/typescript-union-vs-enum-vs-object
export const STEPS = {
  PASSENGERS: 'passengers',
  DURATION: 'duration',
  DATES_APPROX: 'datesApprox',
  DATES: 'dates',
  HIGHLIGHTS: 'highlights',
  TRAVEL_TYPE: 'travelType',
  BUDGET: 'budget',
  INTENT: 'intent',
  PROCESSING: 'processing',
  EMAIL: 'email',
  PHONE: 'phone',
  SUCCESS: 'success',
} as const;

export const SIMPLIFIED_FLOW_STEPS = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
} as const;

export const MAX_POSSIBLE_STEPS = {
  [flowTypes.LINEAR]: Object.keys(STEPS).length - 3,
  [flowTypes.SIMPLIFIED]: Object.keys(SIMPLIFIED_FLOW_STEPS).length - 1,
};

export type LINEAR_FLOW_STEP_KEYS = (typeof STEPS)[keyof typeof STEPS];
export type SIMPLIFIED_FLOW_STEP_KEYS = (typeof SIMPLIFIED_FLOW_STEPS)[keyof typeof SIMPLIFIED_FLOW_STEPS];

export type STEP_KEYS = LINEAR_FLOW_STEP_KEYS | SIMPLIFIED_FLOW_STEP_KEYS;
// !FIXME this type is not accurate
export type STEPS = {
  [K in keyof typeof STEPS]: (typeof STEPS)[K];
} & {
  [K in keyof typeof SIMPLIFIED_FLOW_STEPS]: (typeof SIMPLIFIED_FLOW_STEPS)[K];
};

export type LINEAR_FLOW_STEPS = Record<LINEAR_FLOW_STEP_KEYS, STEP_KEYS>;
