import { durationOptionsEnum } from '../const';

// map X to Y type of functions

export const mapDurationToOptions = (duration: number) => {
  if (!duration) return durationOptionsEnum.TWO_WEEKS;

  if (duration < 10) {
    return durationOptionsEnum.ONE_WEEK;
  }

  if (duration < 18) {
    return durationOptionsEnum.TWO_WEEKS;
  }

  if (duration < 31) {
    return durationOptionsEnum.THREE_TO_FOUR_WEEKS;
  }

  return durationOptionsEnum.FIVE_PLUS_WEEKS;
};
