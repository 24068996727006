import { FC, PropsWithChildren, useMemo } from 'react';

import { NotificationProvider, useMediaBreakpoints } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { isEmbedded } from 'const/displayModes';

import { StepContext } from './context';
import { useTopBottomScroll } from './hooks';

interface StepProps {
  stickyFooterOnDesktop?: boolean;
  // Case when header has position: absolute (ie Preview)
  noHeaderHeight?: boolean;
  'data-cy'?: string;
}

export const Step: FC<PropsWithChildren & StepProps> = ({
  stickyFooterOnDesktop,
  noHeaderHeight,
  children,
  'data-cy': dataCy,
}) => {
  const [xs, sm, md] = isEmbedded ? [true, true, false] : [true, false];
  const listenOnChanges = true;
  const isMobile = useMediaBreakpoints([xs, sm, md], listenOnChanges);
  const [{ isAtBottom }, ref] = useTopBottomScroll();

  const stickyFooter = !!(isMobile || stickyFooterOnDesktop);
  const context = useMemo(() => ({ stickyFooter, isAtBottom }), [stickyFooter, isAtBottom]);

  const headerHeightXS = noHeaderHeight ? 0 : 52;
  const progressHeight = noHeaderHeight ? 0 : 4;
  const stickyFooterHeight = stickyFooter ? [78, 78, 82, 72, 88] : [78, 0, 0, 0, 0];

  return (
    <StepContext.Provider value={context}>
      <NotificationProvider
        notificationHubInfoProps={{
          'data-cy': 'notification-wrapper',
          role: 'alert',
        }}
        top={[68, 76, 88, null, 120]}
        width="100%"
        absolute={{ xs: false, md: true }}
        fixed={{ xs: true, md: false }}
      >
        <Box
          ref={ref}
          className="step"
          width="100%"
          minWidth={[288, 'unset']}
          height={[
            `calc(
            100%
            - ${stickyFooterHeight[0]}px
            - ${headerHeightXS + progressHeight}px
          )`,
            '100%',
          ]}
          overflowY="auto"
          flexGrow={1}
          mb={stickyFooterHeight}
          data-cy={dataCy}
        >
          {children}
        </Box>
      </NotificationProvider>
    </StepContext.Provider>
  );
};
