import { FC, ReactNode } from 'react';

import { useLocation } from 'react-router-dom';

import PhoneIcon from '@tourlane/iconography/Glyphs/Navigation/Phone';
import { COLORS, Dropdown, Flex, IconFlag, TextField } from '@tourlane/tourlane-ui';
import { useFormik } from 'formik';

import { phoneFormFieldsEnum } from '../../const';
import { ACTION_TYPE, useAppContext } from '../../reducer';
import { STEPS } from '../../steps';
import { PhoneDataRecord, config as currentStepConfig } from '../../steps/Phone/config';
import { filterOption, handleMentionMeTracking, phoneCodes } from '../../steps/Phone/phoneHelpers';
import { answersUtils } from '../../utils/answers';
import { BackendResponse } from '../../utils/api';
import { formatBudget } from '../../utils/gtmEvents';
import { getTravelRequestUuidFromQueryParams } from '../../utils/queryParams';
import { trackPhoneSubmit } from '../../utils/trackingEvents';
import { t } from '../../utils/translations';
import { handleBackendError, updateTravelRequest } from '../../utils/travelRequest';
import { phoneSchema } from '../../utils/validation';
import { Box } from '../Box';
import { Footer } from '../Footer';
import { NextButton } from '../Styled';

const optionsCodes = phoneCodes.map(code => ({
  ...code,
  image: <IconFlag rounded country={code.value} />,
}));

const popularCountries = ['DE', 'CH', 'AT'];
const popularCodes = optionsCodes.filter(o => popularCountries.includes(o.value));
const otherCodes = optionsCodes.filter(o => !popularCountries.includes(o.value));

type PhoneFormProps = {
  onSubmitCallback: (phoneNumber: string) => void;
  className?: string;
  nextButtonDataCy?: string;
  children?: ReactNode;
  nextButton?: {
    title: string;
    subTitle: string;
  };
  heading?: ReactNode;
};

export const PhoneForm: FC<PhoneFormProps> = ({
  onSubmitCallback,
  className,
  nextButtonDataCy,
  nextButton,
  children,
  heading,
}) => {
  const {
    answers,
    dispatch,
    meta: { customerUuid, travelRequestUuid, customer, destination, voucher },
  } = useAppContext();
  const { search } = useLocation();
  const { email, code, number, firstName, lastName } = customer;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  let handleError = (response: BackendResponse) => {};

  const onSubmit = async (data: PhoneDataRecord) => {
    const codeNumber = phoneCodes.find(c => c.value === data.code)?.label;
    const budget = answersUtils.getBudget(answers);
    const travelRequestId = travelRequestUuid || getTravelRequestUuidFromQueryParams(search);
    try {
      await updateTravelRequest(travelRequestId, {
        customer: {
          phone: { code: codeNumber, number: data.number },
        },
        ...(voucher && { voucher: { code: voucher.code } }),
      });

      dispatch({
        type: ACTION_TYPE.STORE_META,
        payload: {
          customer: {
            ...customer,
            ...data,
          },
        },
      });

      trackPhoneSubmit({
        budget: formatBudget(budget),
        conversionStep: STEPS.PHONE,
        travelRequestUuid,
        code: codeNumber!,
        number: data.number,
        customerUuid,
        destinationUuid: destination.uuid,
        mentionMeData: handleMentionMeTracking({ firstName, email, lastName }),
      });

      onSubmitCallback(`${codeNumber}${data.number}`);
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setFieldError('number', t('phone.invalid'));
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: code || currentStepConfig.initialValue.code,
      number,
    },
    onSubmit,
    validationSchema: phoneSchema,
    validateOnBlur: false,
    validateOnChange: true,
  });

  // Hack to pass formik instance after it was created
  handleError = error => handleBackendError(formik, error);

  const {
    handleSubmit,
    values,
    handleBlur,
    setFieldValue,
    handleChange,
    errors,
    isSubmitting,
    setFieldError,
    touched,
  } = formik;

  return (
    <main>
      <form className={className} onSubmit={handleSubmit}>
        <Box pt={[32, null, null, null, 10]} px={[16, null, null, 32]}>
          {heading}
          <Flex direction="ltr" fullWidth>
            <Box width={[116, 124, 132, 136, 140]} mr={[8, 12, 16, 24]} data-cy="step-phone-code">
              <Dropdown
                shrinkPlaceholder
                notClearable
                useVirtualizedMenuList
                options={[
                  { label: t('phone.popular'), options: popularCodes },
                  { label: t('phone.all'), options: otherCodes },
                ]}
                filterOption={filterOption}
                icon={<IconFlag rounded country={values.code} />}
                value={values.code}
                onBlur={handleBlur}
                onChange={(countryCode: Extract<PhoneDataRecord, phoneFormFieldsEnum.code>) =>
                  setFieldValue('code', countryCode)
                }
                aria-label={t('phone.phoneCode')}
              />
            </Box>
            <Box flexGrow={1}>
              <TextField
                shrinkPlaceholder
                hideErrorOnFocus={false}
                type="tel"
                autoComplete="tel"
                name="number"
                placeholder={t('phone.number')}
                data-cy="step-phone-number"
                icon={<PhoneIcon />}
                iconRightColor={COLORS.ADVENTURE_GREEN}
                value={values.number}
                onChange={handleChange}
                aria-label={t('phone.number')}
                onBlur={handleBlur}
                error={touched.number && errors.number}
              />
            </Box>
          </Flex>
        </Box>
        <Footer>
          <NextButton
            dataCy={nextButtonDataCy}
            type="submit"
            isLoading={isSubmitting}
            title={nextButton?.title}
            subTitle={nextButton?.subTitle}
          />
        </Footer>
      </form>
      {children}
    </main>
  );
};
