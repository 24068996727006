import { FC } from 'react';

import SafetyIcon from '@tourlane/iconography/Glyphs/Other/Safety';
import { COLORS, InfoWithIcons, Small, SvgIcon } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { t } from 'utils/translations';

export const DataSafetyHint: FC = () => (
  <Box display="flex" justifyContent="center" mt={[24]}>
    <InfoWithIcons
      vertical={{ xs: false, md: false }}
      multiline
      size="small"
      iconMargin={6}
      iconWidth={[16, null, 20, 24]}
      icon={
        <SvgIcon size={[16, null, 20, 24]} color={COLORS.INACTIVE_GRAY}>
          <SafetyIcon />
        </SvgIcon>
      }
    >
      <Small textAlign={['left', 'center']}>{t('common.yourDataIsSafe')}</Small>
    </InfoWithIcons>
  </Box>
);
