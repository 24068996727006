import { DataGovernance, PollingMode, getClient } from 'configcat-js';

const getConfigCatToken = (): string => {
  const configCatToken = process.env.REACT_APP_CFA_CONFIGCAT_TOKEN;

  if (!configCatToken) throw Error('ConfigCat token not present');

  return configCatToken;
};

export const configCatClient = getClient(getConfigCatToken(), PollingMode.AutoPoll, {
  dataGovernance: DataGovernance.EuOnly,
});
