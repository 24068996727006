import { t } from 'utils/translations';
import * as Yup from 'yup';

const { emptyEmail, emptyFirstName, emptyLastName, invalidEmail, tooLongFirstName, tooLongLastName } = t('emailform');

export const NAME_MAX_LENGTH = 40;

// First Name Schema
export const firstNameSchema = Yup.string().trim().required(emptyFirstName).max(NAME_MAX_LENGTH, tooLongFirstName);

export const NameSchema = Yup.object().shape({
  firstName: firstNameSchema,
});

// Last Name Schema
export const lastNameSchema = Yup.string().trim().required(emptyLastName).max(NAME_MAX_LENGTH, tooLongLastName);

// Email-address Schema
const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/i;

export const emailSchema = Yup.string().matches(emailRegex, { message: invalidEmail }).required(emptyEmail);

// Email-Form Validation Schema
export const EmailSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
});

export const VoucherSchema = Yup.object().shape({
  voucherCode: Yup.string().required(t('voucher.error.empty')),
});

// Phone Validation Schema
// regex from rooster
const phoneRegex =
  /^([+][0-9]{1,3}[ .-]?)?([(]{1}[0-9]{1,6}[)])?([0-9 .\-/]{6,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/i;

export const phoneSchema = Yup.object().shape({
  number: Yup.string().matches(phoneRegex, t('phone.invalid')).required(t('phone.required')),
});

/**
 * NOTE: the 'official' way to handle this would be to pass validateOnMount to
 * formik, for example for the name step
   const formik = useFormik({
    validateOnMount: Boolean(initialValues.firstName),
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });
 * sadly that is broken :-(
 * https://github.com/jaredpalmer/formik/issues/2172
 * TODO: keep an eye on that issue and remove this function when fixed
 */

export const shouldSubmitButtonBeDisabled: (formik: any, schema: any) => boolean = (formik, schema) => {
  const shouldBeEnabled = formik.dirty ? formik.isValid : schema.isValidSync(formik.initialValues);
  return !shouldBeEnabled;
};

/**
 * This is used to show the green tick next to an input field.
 * NOTE: it relies on the 'touched' property of the field in formik,
 * which we have to set manually. For example in name that is donw with:
 * onChange={(e: React.SyntheticEvent) => {
      formik.setTouched({ firstName: true });
      formik.handleChange(e);
    }}
 * There is not easy refactoring that would make this any less ugly
 * For more info as to why we need this,
 * @see https://github.com/jaredpalmer/formik/issues/2083
 */
export const fieldIsValid = ({ values, validationSchema, fieldName }: fieldIsValidArgs) => {
  const shouldBeEnabled = validationSchema.isValidSync(values[fieldName]);
  return shouldBeEnabled;
};
export type fieldIsValidArgs = {
  /** formik values */
  values: any;
  /** the validator passed to formik */
  validationSchema: any;
  /** name of the field we are checking */
  fieldName: string;
};
