import { FC } from 'react';

import { Base } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { StyledStrong } from 'components/Styled';
import { IMGIX_CDN_URL, canShowNewSignUp, currentMarket, flowTypes } from 'const';
import styled from 'styled-components/macro';
import { greetUser } from 'utils/greetUser';
import { t } from 'utils/translations';

type ContactUsTypeProps = {
  firstName?: string;
  translationsKey: string;
};

const ExpertImage = styled.img`
  width: auto;
  height: 100%;
`;

export const ContactUs: FC<ContactUsTypeProps> = ({ firstName, translationsKey }) => {
  const { travelExperts } = t('contactus');
  const { greetings } = t(translationsKey);

  const imageVariant = canShowNewSignUp(currentMarket.market) ? flowTypes.SIMPLIFIED : flowTypes.LINEAR;

  return (
    <>
      <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" mb={[16]}>
        <Box height={[80, null, null, null, 96]}>
          <ExpertImage src={`${IMGIX_CDN_URL}/travelExperts/experts-${imageVariant}.jpg`} alt={travelExperts} />
        </Box>
      </Box>
      <Box mb={[32, 32, 40, 32]}>
        <Base withBottomMargin textAlignCenter>
          <StyledStrong role="heading" aria-level={1}>
            {greetUser(greetings, firstName)}
          </StyledStrong>
        </Base>
      </Box>
    </>
  );
};
