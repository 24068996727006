import { FC, Suspense, useEffect, useMemo } from 'react';

import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { LazySteps } from 'LazySteps';
import { Layout } from 'components/Layout';
import { MantisSkeleton } from 'components/Skeleton';
import { canShowRecommendationStarsExperiment, flowTypes, followUpSlug } from 'const';
import { ACTION_TYPE, useAppContext } from 'reducer';
import { SIMPLIFIED_FLOW_STEPS, STEPS, STEP_KEYS } from 'steps';
import { SimplifiedFour } from 'steps/Simplified.Four';
import { SimplifiedOne } from 'steps/Simplified.One';
import { SimplifiedThree } from 'steps/Simplified.Three';
import { SimplifiedTwo } from 'steps/Simplified.Two';
import { StepsConfigType } from 'stepsConfig.types';
import { trackLandingPageLoadedGTMEvent } from 'utils/gtmEvents';
import { getStepFromPathName, shouldRedirectToStart } from 'utils/routing';

import { trackPageView } from './utils/tracking';

const getRoutesByFlowType = ({ sc }: { sc: StepsConfigType }) => {
  const [Passengers, DurationDatesHighlights, ActivityTypeBudget, SplitForm, Intent] = LazySteps;

  return {
    [flowTypes.LINEAR]: (
      <>
        <Route path={sc[STEPS.PASSENGERS].slug} element={<Passengers />} />
        <Route path={sc[STEPS.DURATION].slug} element={<DurationDatesHighlights />} />
        <Route path={sc[STEPS.DATES_APPROX].slug} element={<DurationDatesHighlights />} />
        <Route path={sc[STEPS.DATES].slug} element={<DurationDatesHighlights />} />
        <Route path={sc[STEPS.HIGHLIGHTS].slug} element={<DurationDatesHighlights />} />
        <Route path={sc[STEPS.TRAVEL_TYPE].slug} element={<ActivityTypeBudget />} />
        <Route path={sc[STEPS.BUDGET].slug} element={<ActivityTypeBudget />} />
        <Route path={sc[STEPS.INTENT].slug} element={<Intent />} />
        <Route path={sc[STEPS.PROCESSING].slug} element={<SplitForm />} />
        <Route path={sc[STEPS.EMAIL].slug} element={<SplitForm />} />
        <Route path={sc[STEPS.PHONE].slug} element={<SplitForm />} />
        <Route path={sc[STEPS.SUCCESS].slug} element={<SplitForm />} />
        <Route path={followUpSlug} element={<SplitForm />} />
      </>
    ),
    [flowTypes.SIMPLIFIED]: (
      <>
        <Route path={sc[SIMPLIFIED_FLOW_STEPS.ONE].slug} element={<SimplifiedOne />} />
        <Route path={sc[SIMPLIFIED_FLOW_STEPS.TWO].slug} element={<SimplifiedTwo />} />
        <Route path={sc[SIMPLIFIED_FLOW_STEPS.THREE].slug} element={<SimplifiedThree />} />
        <Route path={sc[SIMPLIFIED_FLOW_STEPS.FOUR].slug} element={<SimplifiedFour />} />
        <Route path={followUpSlug} element={<SplitForm />} />
      </>
    ),
  };
};

export const FallbackUI = () => (
  <Layout type="fallback">
    <MantisSkeleton />
  </Layout>
);

const Root = () => {
  const {
    dispatch,
    stepsConfig,
    stepSequence,
    meta: { destination },
    initialStep,
  } = useAppContext();

  const defaultStep = initialStep as STEP_KEYS;
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const currentStep = getStepFromPathName(stepsConfig, pathname) || initialStep;
  const sc = stepsConfig;
  const possibleStepsSlugs = stepSequence.map(step => sc[step].slug);

  useEffect(() => {
    if (canShowRecommendationStarsExperiment(destination.uuid)) {
      const modifiedStepSequence = [
        STEPS.PASSENGERS,
        STEPS.DATES_APPROX,
        STEPS.DURATION,
        STEPS.HIGHLIGHTS,
        STEPS.TRAVEL_TYPE,
        STEPS.BUDGET,
        STEPS.INTENT,
        STEPS.PROCESSING,
        STEPS.EMAIL,
        STEPS.PHONE,
        STEPS.SUCCESS,
      ];

      dispatch({
        type: ACTION_TYPE.SET_STEP_SEQUENCE,
        payload: modifiedStepSequence,
      });
    }

    if (shouldRedirectToStart({ pathname, search, defaultStep })) {
      navigate(stepsConfig[defaultStep].slug + search, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (possibleStepsSlugs.includes(pathname)) {
      trackPageView({ destinationUuid: destination.uuid });
    }

    trackLandingPageLoadedGTMEvent(destination.tracking);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    /* For a lazy-loadable bundle (say DurationDatesHighlights),
     We get `loadWhenThis` of type STEPS along with it which tells us that:
     given: we are in loadWhenThis (i.e. currentStep === loadWhenThis)
     we can tell our browser to preload the said bundle.
     */
    const loadableComponent = LazySteps.find(({ loadWhenThis }) => loadWhenThis === currentStep);

    if (loadableComponent) {
      loadableComponent.preload();
    }
  }, [currentStep]);

  return (
    <Layout>
      <Suspense fallback={<MantisSkeleton />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};

// we only create the routes once the isAppSetupReady = true as we conditionally decide which routes to have configured based on the flowType
const AppRouter: FC<{ isAppSetupReady: boolean }> = ({ isAppSetupReady }) => {
  const {
    stepsConfig,
    meta: { flowType },
    initialStep,
  } = useAppContext();

  const defaultStep = initialStep as STEP_KEYS;
  const { search } = window.location;

  const sc = stepsConfig;

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromChildren(
          isAppSetupReady ? (
            <Route element={<Root />}>
              {getRoutesByFlowType({ sc })[flowType]}
              <Route path="*" element={<Navigate replace to={sc[defaultStep].slug + search} />} />
            </Route>
          ) : (
            <Route
              path="*"
              element={
                <Layout type="fallback">
                  <MantisSkeleton />
                </Layout>
              }
            />
          )
        )
      ),
    [isAppSetupReady, defaultStep, flowType, search, sc]
  );

  return <RouterProvider router={router} fallbackElement={<FallbackUI />} />;
};

export default AppRouter;
