import { QuestionContextTypes, basePath, possibleTravelTypesEnum, travelTypeQuestionsEnum } from 'const';
import { Destination } from 'reducer';
import { STEPS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { destinationHasBudgetDefinitions, getPossibleTravelTypes } from 'utils/budgetDefinitions';
import { t } from 'utils/translations';

import { addStepIcons } from './icons';

// Travel Types

export const TRANSFER = {
  value: possibleTravelTypesEnum.TRANSFER,
  title: t('travelType.transferTitle'),
  content: t('travelType.transferContent'),
  icon: 'PrivateGuidedIcon',
};

export const SELF_DRIVE = {
  value: possibleTravelTypesEnum.SELF_DRIVE,
  title: t('travelType.selfDriveTitle'),
  content: t('travelType.selfDriveContent'),
  icon: 'SelfDriveIcon',
};

const GROUP_TOUR = {
  value: possibleTravelTypesEnum.GROUP_TOUR,
  title: t('travelType.groupTourTitle'),
  content: t('travelType.groupTourContent'),
  icon: 'GroupTourIcon',
};

const PRIVATE_GUIDED = {
  value: possibleTravelTypesEnum.PRIVATE_GUIDED,
  title: t('travelType.privateGuidedTitle'),
  content: t('travelType.privateGuidedContent'),
  icon: 'PrivateGuidedIcon',
};

const OWN_CAR = {
  value: possibleTravelTypesEnum.OWN_CAR,
  title: t('travelType.ownCarTitle'),
  content: t('travelType.ownCarContent'),
  icon: 'PrivateGuidedIcon',
};

export const mapPossibleTravelTypeStringToTravelType = {
  [possibleTravelTypesEnum.SELF_DRIVE]: SELF_DRIVE,
  [possibleTravelTypesEnum.PRIVATE_GUIDED]: PRIVATE_GUIDED,
  [possibleTravelTypesEnum.GROUP_TOUR]: GROUP_TOUR,
  [possibleTravelTypesEnum.OWN_CAR]: OWN_CAR,
  [possibleTravelTypesEnum.TRANSFER]: TRANSFER,
};

export const DEFAULT_INITIAL_TRAVEL_TYPE = possibleTravelTypesEnum.SELF_DRIVE;

export const config = {
  id: STEPS.TRAVEL_TYPE,
  idx: 5,
  questions: {
    [travelTypeQuestionsEnum.travelType]: {
      id: travelTypeQuestionsEnum.travelType,
      answerText: (value: FrontendAnswerValueType) =>
        config.questions.travelType.availableAnswers.find(a => a.value === value)?.title,
      initialValue: DEFAULT_INITIAL_TRAVEL_TYPE,
      availableAnswers: [SELF_DRIVE, PRIVATE_GUIDED, GROUP_TOUR],
      context: {
        type: QuestionContextTypes.SINGLE_SELECT_PILL,
      },
    },
  },
  questionText: t('travelType.headingInfo'),
  next: () => STEPS.BUDGET,
  slug: `${basePath}/travel-type`,
};

export const configWithDynamicBudgetDefinitions = (enabledTypes: possibleTravelTypesEnum[]): any => {
  // NOTE: this is to make the slider render at least one colored segment on
  // first load
  const initialValue = enabledTypes[1] ? enabledTypes[1] : enabledTypes[0];

  return {
    id: STEPS.TRAVEL_TYPE,
    idx: 5,
    questions: {
      [travelTypeQuestionsEnum.travelType]: {
        id: travelTypeQuestionsEnum.travelType,
        answerText: (value: FrontendAnswerValueType) =>
          config.questions.travelType.availableAnswers.find(a => a.value === value)?.title,
        initialValue: initialValue || DEFAULT_INITIAL_TRAVEL_TYPE,
        availableAnswers: enabledTypes.map(enabledType => mapPossibleTravelTypeStringToTravelType[enabledType]),
        context: {
          type: QuestionContextTypes.SINGLE_SELECT_PILL,
        },
      },
    },
    questionText: t('travelType.headingInfo'),
    next: () => STEPS.BUDGET,
    slug: `${basePath}/travel-type`,
  };
};

export const getTravelTypeConfig = (destination: Destination) => {
  if (destinationHasBudgetDefinitions(destination)) {
    const possibleTravelTypes = getPossibleTravelTypes(destination);

    return addStepIcons({
      stepConfig: configWithDynamicBudgetDefinitions(possibleTravelTypes),
    });
  }

  return null;
};
