import { QuestionContextTypes, basePath, durationOptionsEnum, durationQuestionsEnum } from 'const';
import { STEPS, STEP_KEYS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { t } from 'utils/translations';

export const DEFAULT_INITIAL_DURATION = durationOptionsEnum.TWO_WEEKS;

export const availableAnswers = [
  {
    value: durationOptionsEnum.ONE_WEEK,
    title: t('duration.oneWeek'),
  },
  {
    value: durationOptionsEnum.TWO_WEEKS,
    title: t('duration.twoWeeks'),
  },
  {
    value: durationOptionsEnum.THREE_TO_FOUR_WEEKS,
    title: t('duration.threeToFourWeeks'),
  },
  {
    value: durationOptionsEnum.FIVE_PLUS_WEEKS,
    title: t('duration.fivePlusWeeks'),
  },
];

export const config = {
  id: STEPS.DURATION,
  idx: 2,
  questionText: t('duration.headline'),
  questions: {
    [durationQuestionsEnum.duration]: {
      id: durationQuestionsEnum.duration,
      initialValue: DEFAULT_INITIAL_DURATION,
      answerText: (value: FrontendAnswerValueType) =>
        config.questions.duration.availableAnswers.find(a => a.value === value)?.title,
      // NOTE: if this list change we should keep update the keys with 'SelectedDuration' postfix on the phrase
      // Check src\steps\TravelType\TravelTypeStepRenderer.tsx - function'renderSelectedAnswer'
      availableAnswers,
    },
  },
  next: (): STEP_KEYS => STEPS.DATES_APPROX,
  slug: `${basePath}/duration`,
  context: {
    type: QuestionContextTypes.SINGLE_SELECT_PILL,
    values: availableAnswers.map(({ value }) => value),
  },
};
