import { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@tourlane/tourlane-ui';
import { Step } from 'components/Step';
import { getNextStepPath, getStepFromPathName } from 'utils/routing';

import { ContactUs } from '../../components/ContactUs';
import { EmailForm } from '../../components/EmailForm';
import { SPLIT_FORM_MAX_WIDTH } from '../../const';
import { useCreateOfferPreview } from '../../hooks/useCreateOfferPreview';
import { useAppContext } from '../../reducer';

export const SimplifiedTwo: FC = () => {
  const { stepsConfig } = useAppContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentStep = getStepFromPathName(stepsConfig, pathname);
  const currentStepConfig = stepsConfig[currentStep!];

  useCreateOfferPreview();

  return (
    <Step stickyFooterOnDesktop data-cy="step-email">
      <Box maxWidth={SPLIT_FORM_MAX_WIDTH} mx="auto">
        <EmailForm
          stepConfig={currentStepConfig}
          heading={<ContactUs translationsKey="emailHeader" />}
          onSubmitCallback={() => {
            const nextStepPath = getNextStepPath(currentStepConfig.id);
            navigate(nextStepPath);
          }}
        />
      </Box>
    </Step>
  );
};
