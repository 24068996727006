import { Locale, currentMarket } from 'const';
import i18n from 'i18next';
import CUSTOMER_TRANSLATION_DE_DE from 'translations/de-DE/customer.json';
import MANTIS_TRANSLATION_DE_DE from 'translations/de-DE/mantis.json';
import CUSTOMER_TRANSLATION_EN_GB from 'translations/en-GB/customer.json';
import MANTIS_TRANSLATION_EN_GB from 'translations/en-GB/mantis.json';
import CUSTOMER_TRANSLATION_EN_US from 'translations/en-US/customer.json';
import MANTIS_TRANSLATION_EN_US from 'translations/en-US/mantis.json';
import CUSTOMER_TRANSLATION_FR_FR from 'translations/fr-FR/customer.json';
import MANTIS_TRANSLATION_FR_FR from 'translations/fr-FR/mantis.json';
import CUSTOMER_TRANSLATION_NL_NL from 'translations/nl-NL/customer.json';
import MANTIS_TRANSLATION_NL_NL from 'translations/nl-NL/mantis.json';

const resources = {
  [Locale.Gb]: {
    translation: {
      ...CUSTOMER_TRANSLATION_EN_GB,
      ...MANTIS_TRANSLATION_EN_GB,
    },
  },
  [Locale.Us]: {
    translation: {
      ...CUSTOMER_TRANSLATION_EN_US,
      ...MANTIS_TRANSLATION_EN_US,
    },
  },
  [Locale.De]: {
    translation: {
      ...CUSTOMER_TRANSLATION_DE_DE,
      ...MANTIS_TRANSLATION_DE_DE,
    },
  },
  [Locale.Fr]: {
    translation: {
      ...CUSTOMER_TRANSLATION_FR_FR,
      ...MANTIS_TRANSLATION_FR_FR,
    },
  },
  [Locale.Nl]: {
    translation: {
      ...CUSTOMER_TRANSLATION_NL_NL,
      ...MANTIS_TRANSLATION_NL_NL,
    },
  },
};

i18n.init({
  resources,
  lng: currentMarket.locale,
  react: {
    transKeepBasicHtmlNodesFor: ['span'],
  },
  returnObjects: true,
});

export default i18n;

export const t = (key: string, dynamicValue: { [key: string]: string | number | any } = {}): any =>
  i18n.t(key, dynamicValue);
