import { COLORS, MEDIA, SliderOptions } from '@tourlane/tourlane-ui';
import styled from 'styled-components';

const svgStarSize = 20;
export const StyledSliderOptions = styled(SliderOptions)<{ $iconLeftOffset?: number }>`
  > div > div:nth-child(3) > span {
    display: none;
  }
  > div > div:nth-child(3) > span:first-of-type {
    display: none;
  }

  > div > div:nth-child(3) > span:last-of-type {
    display: none;
  }

  > div > div:nth-child(3) > p:not(:first-of-type):not(:last-of-type) {
    color: ${COLORS.INACTIVE_GRAY};
  }

  > div > div[role='slider'] {
    height: 8px;
  }

  > div > div:nth-child(2) {
    height: 8px;
  }

  > div > button > div {
    height: inherit;
    width: inherit;
  }

  > div::before {
    /* encoded via https://yoksel.github.io/url-encoder/ */
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='white' stroke-width='1' fill='%23EAC51B'%3E%3Cpath d='M12 17l-5.878 3.09 1.123-6.545L2.489 8.91l6.572-.955L12 2l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    width: ${svgStarSize}px;
    height: ${svgStarSize}px;
    position: absolute;
    z-index: 5;
    left: ${({ $iconLeftOffset }) => `calc(${$iconLeftOffset}% - ${svgStarSize / 2}px)`};
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    display: ${({ $iconLeftOffset }) => (typeof $iconLeftOffset === 'number' ? 'block' : 'none')};

    ${({ $iconLeftOffset }) => MEDIA.from.md.to.lg`
        width: calc(${svgStarSize}px + 2px);
        height: calc(${svgStarSize}px + 2px);
        left: calc(${$iconLeftOffset}% - ${(svgStarSize + 2) / 2}px);
    `};

    ${({ $iconLeftOffset }) => MEDIA.only.xl`
        width: calc(${svgStarSize}px + 6px);
        height: calc(${svgStarSize}px + 6px);
        left: calc(${$iconLeftOffset}% - ${(svgStarSize + 6) / 2}px);
    `};
  }
`;
