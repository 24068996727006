import { useEffect, useState } from 'react';

import { error } from '@tourlane/tourlane-ui';

import { Market } from '../../const';
import { TrustpilotResponse, getTruspilotUrl } from './Trustpilot.types';

export const useTrustpilotQuery = (market: Market) => {
  const [response, setResponse] = useState<TrustpilotResponse | null>(null);
  useEffect(() => {
    fetch(getTruspilotUrl(market))
      .then(data => data.json())
      .then(json => {
        setResponse({
          reviews: json.numberOfReviews.total,
          score: json.score.trustScore,
          stars: json.score.stars,
        });
      })
      .catch((e: Error) => error('TrustpilotBadge', e));
  }, [market]);

  return { data: response };
};
