import { FC, ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import AlertIcon from '@tourlane/iconography/Glyphs/Notifications/Alert';
import { Button, ButtonWithLoader, COLORS, Strong, Subline, TextButton, useNotification } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import styled from 'styled-components';
import { t } from 'utils/translations';

export const StyledStrong = styled(Strong)`
  display: block;
`;

export interface HeadingEntryProps {
  children: ReactNode;
  withBottomMargin?: boolean;
  'data-cy'?: string;
}

export const HeadingEntry: FC<HeadingEntryProps> = ({ 'data-cy': dataCy, children, withBottomMargin }) => {
  return (
    <Subline withBottomMargin={withBottomMargin} textAlignCenter={false}>
      <StyledStrong data-cy={dataCy} role="heading" aria-level={1}>
        {children}
      </StyledStrong>
    </Subline>
  );
};

export interface NextButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
  hasError?: boolean;
  error?: string;
  disabledClickInfo?: string;
  href?: string;
  dataCy?: string;
}

export const NextButton: FC<NextButtonProps> = ({
  children = t('actions.next'),
  disabled = false,
  onClick,
  disabledClickInfo,
  isLoading,
  title,
  subTitle,
  type = 'button',
  href,
  dataCy = 'footer-submit-button',
}) => {
  const [isNotificationDisabled, setNotificationDisabled] = useState(false);
  const [NewButton, setNewButton] = useState<any>(() => Button);
  const [isLoadingProp, setIsLoadingProp] = useState<any>({});

  useEffect(() => {
    if (typeof isLoading === 'undefined') {
      setNewButton(() => Button);
      setIsLoadingProp({});
    } else {
      setNewButton(() => ButtonWithLoader);
      setIsLoadingProp({ isLoading });
    }
  }, [isLoading]);

  const { showInfo } = useNotification();

  const handleDisabledClick = () => {
    setNotificationDisabled(true);

    if (disabledClickInfo && !isNotificationDisabled) {
      setTimeout(() => {
        showInfo({
          variant: 'error',
          closable: false,
          message: disabledClickInfo,
          icon: <AlertIcon />,
          delay: 4500,
          onClose: () => setNotificationDisabled(false),
        });
      }, 800);
    }
  };

  return (
    <Box minWidth={['100%', 300, 250, 280]}>
      <NewButton
        fullWidth
        {...(onClick ? { onClick } : {})}
        {...isLoadingProp}
        {...(title ? { title } : {})}
        {...(subTitle ? { subTitle } : {})}
        {...(href ? { href } : {})}
        style={
          !isLoading
            ? {
                background: COLORS.ADVENTURE_GREEN,
                color: COLORS.SENSATION_WHITE,
                cursor: 'pointer',
              }
            : {}
        }
        data-cy={dataCy}
        disabled={disabled}
        aria-disabled={disabled}
        type={type}
        onDisabledClick={handleDisabledClick}
      >
        {children}
      </NewButton>
    </Box>
  );
};

export const SkipButton: FC<NextButtonProps> = ({ children = t('actions.skipThisStep'), onClick }) => (
  <Box mt={24} mb={8}>
    <TextButton
      {...(onClick ? { onClick } : {})}
      data-cy="footer-skip-button"
      noFocus
      width={['100%', 300, 250, 280]}
      variant="secondary"
    >
      {children}
    </TextButton>
  </Box>
);
