import { FC } from 'react';

import TripPlannerIcon from '@tourlane/iconography/Glyphs/Suite/TripPlanner';
import { COLORS } from '@tourlane/tourlane-ui';

import { t } from '../../utils/translations';
import { Box } from '../Box';
import { InfoWithIconCustom } from '../InfoWithIconCustom';

export const TripPlanningInfo: FC = () => (
  <Box
    display="flex"
    justifyContent="flex-start"
    mt={[24, 40]}
    p={[8]}
    backgroundColor={COLORS.BACKGROUND_GRAY}
    borderRadius="20px"
  >
    <InfoWithIconCustom text={t('usp.tripPlanning')} icon={TripPlannerIcon} />
  </Box>
);
