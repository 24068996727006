// Have stolen from Morpheus
import { BREAKPOINTS, COLORS } from '@tourlane/tourlane-ui';

const { sm, md, lg, xl } = BREAKPOINTS;
const breakpoints = [sm.min, md.min, lg.min, xl.min].map(b => `${b / 16}em`);

const colors = {
  ...COLORS,
};

const theme = { breakpoints, space: [], colors };

export default theme;
