import { useEffect, useState } from 'react';

import { FlowTypes, PREVIEW_ID, environment, flowTypes } from 'const';
import dayjs from 'dayjs';
import { ACTION_TYPE, Dispatch } from 'reducer';
import { api } from 'utils/api';

const removePreviewIdFromParams = () => {
  // remove the url param for non-surfing or invalid offers
  const url = new URL(window.location.href);
  url.searchParams.delete(PREVIEW_ID);
  window.history.replaceState({}, '', url.toString());
};
export const useFetchOfferPreview = ({ dispatch, previewId }: { previewId: string | null; dispatch: Dispatch }) => {
  const [flowType, setFlowType] = useState<FlowTypes | undefined>(undefined);

  useEffect(() => {
    if (flowType) {
      dispatch({ type: ACTION_TYPE.SET_FLOW_TYPE, payload: { flowType } });
    }
  }, [flowType, dispatch]);

  useEffect(() => {
    if (!previewId) {
      // when no preview, we do not need to call the api so we can mark the fetch as done to unblock the UI render
      setFlowType(flowTypes.LINEAR);
      return;
    }
    const fetchData = async () => {
      const url = `${environment.API_ENDPOINT_GECKO}/previews/${previewId}`;

      try {
        const {
          meta: { uuid, cuid },
          price: { amount: price },
          surfing,
          travel_dates: travelDates,
        } = await api({
          url,
        });

        if (surfing) {
          const duration = dayjs(new Date(travelDates.end)).diff(new Date(travelDates.start), 'day');

          const offerPreview = {
            uuid,
            cuid,
            price,
            surfing,
            duration,
          };

          dispatch({ type: ACTION_TYPE.STORE_META, payload: { offerPreview } });

          setFlowType(flowTypes.SIMPLIFIED);
        } else {
          removePreviewIdFromParams();
          setFlowType(flowTypes.LINEAR);
        }
      } catch (err: any) {
        removePreviewIdFromParams();
        setFlowType(flowTypes.LINEAR);
      }
    };

    fetchData();
  }, [previewId]); // eslint-disable-line react-hooks/exhaustive-deps

  return { flowType };
};
