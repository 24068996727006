import { useEffect } from 'react';

import { snowplow } from '@tourlane/tracking';
import { SNOWPLOW_PAGE_TYPE, SNOWPLOW_QUESTIONNAIRE_VERSION } from 'const';
import { Base64 } from 'js-base64';
import { STEPS } from 'stepsConfig';
import { StepConfigType } from 'stepsConfig.types';
import { TrackingEntity } from 'tracking.types';
import { BackendAnswerType } from 'utils/answers';

export enum QuestionEventTypes {
  VIEW = 'view',
  SUBMIT = 'submit',
  SKIP = 'skip',
}

// TODO: once approved by Data team, entities can be moved to rooster
const getQualificationQuestionnaireFlowEntity = (
  stepConfigId: StepConfigType['id'],
  questionText: StepConfigType['questionText'],
  eventType: QuestionEventTypes,
  questionIndex: number
) => ({
  schema: 'iglu:com.tourlane/qualification_questionnaire_flow/jsonschema/2-0-1',
  data: {
    type: eventType,
    current_idx: questionIndex,
    question: {
      identifier: stepConfigId,
      // TODO once stepConfig can't be undef remove ts-ignore
      // @ts-ignore
      text: Base64.encode(questionText),
    },
    questionnaire_version: SNOWPLOW_QUESTIONNAIRE_VERSION,
  },
});

const getQualificationAnswerEntity = (stepConfig: StepConfigType, answers: BackendAnswerType) => ({
  schema: 'iglu:com.tourlane/qualification_answer/jsonschema/4-0-0',
  data: {
    type: stepConfig.id,
    value: answers,
  },
});

export const trackQuestionSubmitSnowplowEvent = ({
  type,
  stepConfig,
  answers,
  questionIndex,
  destinationUuid,
}: {
  type: QuestionEventTypes;
  stepConfig: StepConfigType;
  answers: BackendAnswerType[];
  questionIndex: number;
  destinationUuid: string;
}) => {
  snowplow.trackSelfDescribingEvent({
    pageType: SNOWPLOW_PAGE_TYPE,
    destination: destinationUuid,
    event: getQualificationQuestionnaireFlowEntity(stepConfig.id, stepConfig.questionText, type, questionIndex),
    entities: answers.map(answer => getQualificationAnswerEntity(stepConfig, answer)),
  });
};

export const trackQuestionViewSnowplowEvent = (
  stepConfig: StepConfigType,
  questionIndex: number,
  destinationUuid: string,
  entities?: TrackingEntity[]
) => {
  snowplow.trackSelfDescribingEvent({
    pageType: SNOWPLOW_PAGE_TYPE,
    destination: destinationUuid,
    event: getQualificationQuestionnaireFlowEntity(
      stepConfig.id,
      stepConfig.questionText,
      QuestionEventTypes.VIEW,
      questionIndex
    ),
    entities,
  });
};

export const trackQuestionSkipSnowplowEvent = (
  stepConfig: StepConfigType,
  questionIndex: number,
  destinationUuid: string
) => {
  snowplow.trackSelfDescribingEvent({
    pageType: SNOWPLOW_PAGE_TYPE,
    destination: destinationUuid,
    event: getQualificationQuestionnaireFlowEntity(
      stepConfig.id,
      stepConfig.questionText,
      QuestionEventTypes.SKIP,
      questionIndex
    ),
  });
};

export const useTrackQuestionViewSnowplowEvent = (
  stepConfig: StepConfigType,
  destinationUuid: string,
  questionIndex: number,
  entities?: TrackingEntity[]
) => {
  useEffect(() => {
    trackQuestionViewSnowplowEvent(stepConfig, questionIndex, destinationUuid, entities);
  }, [questionIndex]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const trackQuestionnaireCompleteSnowplowEvent = (numberOfQuestions: number, destinationUuid: string) => {
  snowplow.trackQualificationQuestionnaireCompleteEvent({
    destination: destinationUuid,
    length: numberOfQuestions,
    id: 1,
  });
};

const getConversionEvent = (conversionStep: STEPS['PHONE'] | STEPS['EMAIL']) => {
  return {
    schema: 'iglu:com.tourlane/conversion/jsonschema/1-0-0',
    data: {
      step: conversionStep,
    },
  };
};

export type ConversionSnowplowEventProps = {
  conversionStep: STEPS['PHONE'] | STEPS['EMAIL'];
  travelRequestUuid: string;
  destinationUuid: string;
};

export const trackConversionSnowplowStep = ({
  conversionStep,
  travelRequestUuid,
  destinationUuid,
}: ConversionSnowplowEventProps) => {
  snowplow.trackSelfDescribingEvent({
    pageType: SNOWPLOW_PAGE_TYPE,
    destination: destinationUuid,
    event: getConversionEvent(conversionStep),
    entities: [snowplow.getTravelRequestEntity(travelRequestUuid)],
  });
};

export type FeatureFlagsType = Record<string, string>;

type ContextType = {
  schema: string;
  data: {
    [key: string]: any;
  };
};

// Hack - should be removed after traveler routing test is concluded.
export const getExperimentContexts = (featureFlags: FeatureFlagsType = {}) =>
  Object.entries(featureFlags).map(
    ([key, value = '']): ContextType => ({
      schema: 'iglu:com.tourlane/experiment_entity/jsonschema/1-0-1',
      data: {
        variation: key,
        value: String(value),
      },
    })
  );
