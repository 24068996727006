import {
  CHANNEL_COOKIE_NAME,
  CUSTOMER_UUID_COOKIE_NAME,
  GTM_EVENT_LABELS,
  TOURLANE_ID_COOKIE_NAME,
} from '@tourlane/tracking';
import { Currency, currentMarket } from 'const';
import { DestinationTracking } from 'reducer';
import { StepConfigType } from 'stepsConfig.types';
import { EmailDataRecordTypes } from 'types/EmailDataRecord.types';
import { FrontendAnswerType } from 'utils/answerHook';

import { getCookie } from './cookie';
import { destinationIsoLocalStorageKey, getFromLocalStorage } from './localStorage';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type GTMTrackingVarStringKeys =
  | 'country'
  | 'region'
  | 'city'
  | 'lead_id'
  | 'answer'
  | 'budget'
  | 'tourlane_id'
  | 'channel'
  | 'email'
  | 'firstname'
  | 'surname'
  | 'situation'
  | 'locale'
  | 'booking_currency'
  | 'customer_uuid'
  | 'browser_name';

type GTMTrackingVarNumberKeys = 'numberOfTravelers' | 'answerId' | 'questionId' | 'questionIndex';

// eslint-disable
export type GTMTrackingVars = {
  [key in GTMTrackingVarStringKeys]?: string;
} & {
  [key in GTMTrackingVarNumberKeys]?: number;
} & {
  event: string;
  // eslint-disable-next-line camelcase
  content_ids?: string[];
  currency?: Currency;
};

export const formatBudget = (budget: number) => Number(budget).toFixed(2);

const isDataLayerDefined = () => typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined';

export const trackGTMEvent = (variables: GTMTrackingVars) => isDataLayerDefined() && window.dataLayer.push(variables);

export type TrackGTMFacebookType = {
  event: string;
  // eslint-disable-next-line camelcase
  content_ids: string[];
  budget: string;
  currency: Currency;
  numberOfTravelers: number;
  channel?: string;
};

// landing_page_loaded GTM event
export const LANDING_PAGE_LOADED_EVENT = {
  event: GTM_EVENT_LABELS.LANDING_PAGE_LOADED,
  channel: getCookie(CHANNEL_COOKIE_NAME),
};

export const trackLandingPageLoadedGTMEvent = (destinationTracking: DestinationTracking) =>
  trackGTMEvent({
    ...LANDING_PAGE_LOADED_EVENT,
    ...destinationTracking,
  });

export const trackQuestionAnsweredGTMEvent = ({
  stepConfig,
  answers,
  questionIndex,
}: {
  stepConfig: StepConfigType;
  answers: FrontendAnswerType | FrontendAnswerType[];
  questionIndex: number;
}) => {
  const event = {
    event: GTM_EVENT_LABELS.QUESTION_ANSWERED,
    questionId: stepConfig?.id,
    questionIndex,
    answer: answers,
    channel: getCookie(CHANNEL_COOKIE_NAME),
  };
  trackGTMEvent(event as any);
};

type LandingPageArgs = {
  event: string;
  budget: string;
  currency?: Currency | undefined;
  numberOfTravelers?: number;
  channel?: string;
  destinationTracking?: DestinationTracking;
};

export const createLandingPageGTMEvent = ({
  currency = currentMarket.currency,
  // TODO: change this to use real data from Passenger step
  numberOfTravelers = 2,
  destinationTracking = {},
  ...rest
}: LandingPageArgs): GTMTrackingVars => ({
  currency,
  numberOfTravelers,
  channel: getCookie(CHANNEL_COOKIE_NAME),
  ...destinationTracking,
  ...rest,
});

export const trackLastQuestionAnsweredGTMEvent = (budget: string, destinationTracking: DestinationTracking) => {
  const event = createLandingPageGTMEvent({
    budget,
    event: GTM_EVENT_LABELS.LAST_QUESTION_ANSWERED,
    destinationTracking,
  });

  trackGTMEvent(event);
};

export const createFirstFormSubmittedGTMEvent = ({
  leadId,
  tourlaneId,
  email,
  gender,
  firstName,
  lastName,
}: { leadId: string; tourlaneId: string } & EmailDataRecordTypes) => {
  const destinationISO = JSON.parse(getFromLocalStorage(destinationIsoLocalStorageKey) as string);
  const destinationISOCode = destinationISO?.alpha3 || destinationISO?.alpha2 || '';

  return {
    event: GTM_EVENT_LABELS.FIRST_FORM_SUBMITTED,
    lead_id: leadId,
    tourlane_id: tourlaneId,
    channel: getCookie(CHANNEL_COOKIE_NAME),
    email,
    gender: gender.toLocaleLowerCase(),
    firstname: firstName,
    surname: lastName,
    language: currentMarket.gtmLanguage,
    customer_uuid: getCookie(CUSTOMER_UUID_COOKIE_NAME),
    situation: 'ee-form',
    last_lead_destination_iso: destinationISOCode,
  };
};

export const trackEmailSubmitGTMEvent = ({
  travelRequestUuid,
  email,
  gender,
  firstName,
  lastName,
}: { travelRequestUuid: string } & EmailDataRecordTypes) => {
  const event = createFirstFormSubmittedGTMEvent({
    leadId: travelRequestUuid,
    tourlaneId: getCookie(TOURLANE_ID_COOKIE_NAME),
    gender,
    firstName,
    lastName,
    email,
  });

  // first_form_submitted event
  trackGTMEvent(event);
};

export const trackPhoneSubmitGTMEvent = (budget: string, mentionMeData = {}) => {
  const event = createLandingPageGTMEvent({
    budget,
    channel: getCookie(CHANNEL_COOKIE_NAME),
    event: GTM_EVENT_LABELS.LEAD_CREATED,
    ...mentionMeData,
  });

  // lead_created event
  trackGTMEvent(event);
};

export const trackBrowserBannerClick = (browserName: string) => {
  trackGTMEvent({
    event: 'browser_download_link_clicked',
    browser_name: browserName,
  });
};
