import styled from 'styled-components';
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  ZIndexProps,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
  zIndex,
} from 'styled-system';

type PointerEventsProps = {
  pointerEvents?: string;
};

const pointerEvents = system({ pointerEvents: true });

export const Box = styled.div<
  SpaceProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    TypographyProps &
    BorderProps &
    PositionProps &
    ShadowProps &
    ZIndexProps &
    PointerEventsProps
>(space, color, layout, flexbox, typography, border, position, shadow, zIndex, pointerEvents);
