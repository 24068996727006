import { currentMarket } from 'const';
import { StepsConfigType } from 'stepsConfig.types';
import { AnswerInReactContextType } from 'utils/answers';
import { BackendAnswerTypeEnrichedWithQuestion, answerMapper } from 'utils/travelRequestHelpers';

import { FlagState } from '../../components/FeatureFlag';

const createPostBody = (
  answers: BackendAnswerTypeEnrichedWithQuestion[],
  offerPreviewFlags: Partial<FlagState>,
  templateId = ''
) => ({
  enquiry: answers,
  market: currentMarket.market,
  experiments: offerPreviewFlags,
  template_cuid: templateId,
});

export const preparePreviewRequest = (
  allAnswers: AnswerInReactContextType[],
  stepsConfig: StepsConfigType,
  experiments: Partial<FlagState>,
  templateId?: string
) => {
  const enquiryAnswers = allAnswers.map(({ stepId, value: answers }) => {
    // pick stepConfig specific to the questionId
    const stepConfig = stepsConfig[stepId];

    return {
      question: {
        identifier: stepId,
        text: stepConfig?.questionText,
      },
      answers: answerMapper({
        answers,
        stepConfig,
      }),
    };
  });

  return createPostBody(enquiryAnswers, experiments, templateId);
};
