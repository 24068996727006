import { Locale } from '../const';

export const formatAgentShiftHoursRange = (hourFrom: number, hourTo: number, locale: Locale) => {
  let hourFromFormatted = `${hourFrom}`;
  let hourToFormatted = `${hourTo}`;

  if ([Locale.Us, Locale.Gb].includes(locale)) {
    const formatTo12Hour = (hour: number): string => {
      if (hour > 12) {
        return `${hour - 12}pm`;
      }
      return `${hour}am`;
    };

    hourFromFormatted = formatTo12Hour(hourFrom);
    hourToFormatted = formatTo12Hour(hourTo);
  }

  return { hourFromFormatted, hourToFormatted };
};
