import { getDuckToolValue } from 'utils/duckTools/utils';

export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Gbp = 'GBP',
}

export enum Market {
  De = 'tourlane.de',
  Fr = 'tourlane.fr',
  Nl = 'tourlane.nl',
  Gb = 'tourlane.co.uk',
  Us = 'tourlane.com',
}

// ietf code
export enum Locale {
  De = 'de-DE',
  Fr = 'fr-FR',
  Nl = 'nl-NL',
  Gb = 'en-GB',
  Us = 'en-US',
}

export enum StagingMarket {
  De = 'de-de',
  Fr = 'fr-fr',
  Nl = 'nl-nl',
  Gb = 'en-gb',
  Us = 'en-us',
}

type Key = keyof typeof Market | keyof typeof StagingMarket;

export const currencyByLocale: Record<Locale, Currency> = {
  [Locale.De]: Currency.Eur,
  [Locale.Fr]: Currency.Eur,
  [Locale.Nl]: Currency.Eur,
  [Locale.Gb]: Currency.Gbp,
  [Locale.Us]: Currency.Usd,
};

export type MarketType = (typeof Locale)[keyof typeof Locale];

// iso 639-1 code
export enum DayJsLocale {
  De = 'de',
  Fr = 'fr',
  Nl = 'nl',
  Gb = 'en-gb',
  Us = 'en',
}

// https://tourlane.atlassian.net/browse/TRIP-9368
export enum GTMLanguage {
  De = 'de',
  Fr = 'fr',
  Nl = 'nl',
  Gb = 'en',
  Us = 'en',
}

export const allMarkets = {
  [Market.De]: {
    market: Market.De,
    locale: Locale.De,
    dayJsLocale: DayJsLocale.De,
    currency: Currency.Eur,
    gtmLanguage: GTMLanguage.De,
  },
  [Market.Fr]: {
    market: Market.Fr,
    locale: Locale.Fr,
    dayJsLocale: DayJsLocale.Fr,
    currency: Currency.Eur,
    gtmLanguage: GTMLanguage.Fr,
  },
  [Market.Nl]: {
    market: Market.Nl,
    locale: Locale.Nl,
    dayJsLocale: DayJsLocale.Nl,
    currency: Currency.Eur,
    gtmLanguage: GTMLanguage.Nl,
  },
  [Market.Gb]: {
    market: Market.Gb,
    locale: Locale.Gb,
    dayJsLocale: DayJsLocale.Gb,
    currency: Currency.Gbp,
    gtmLanguage: GTMLanguage.Gb,
  },
  [Market.Us]: {
    market: Market.Us,
    locale: Locale.Us,
    dayJsLocale: DayJsLocale.Us,
    currency: Currency.Usd,
    gtmLanguage: GTMLanguage.Us,
  },
};

const getMarket = (host: string) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return Object.values(Market).find(market => host.includes(market)) || Market.De;
  }

  const marketFromDomain = Object.keys(StagingMarket).find(key => host.includes(StagingMarket[key as Key]));
  return Market[marketFromDomain as Key] || Market.De;
};

const MARKET: Market = (getDuckToolValue('market') as Market) || getMarket(window.location.hostname);

export const currentMarket = allMarkets[MARKET];

export const isDE = (locale: string) => locale === Locale.De;
export const isUS = (locale: string) => locale === Locale.Us;
export const isUK = (locale: string) => locale === Locale.Gb;
export const isFR = (locale: string) => locale === Locale.Fr;
export const isNL = (locale: string) => locale === Locale.Nl;
export const isInOperatingMarket = (locale: string) => isDE(locale) || isFR(locale);

export const getCurrency = (locale: Locale) => {
  return currencyByLocale[locale];
};
