import { FC, ReactNode } from 'react';

import { TrustpilotBadge as Badge, Strong } from '@tourlane/tourlane-ui';
import { formatNumber } from 'utils';

import { MarketType, currentMarket } from '../../const';
import { useTrustpilotQuery } from './Trustpilot.hooks';
import { TrustpilotResponse, reviewsByMarket, trustpilotUrls } from './Trustpilot.types';

const formatBottomTitle: (market: MarketType, response: TrustpilotResponse) => ReactNode = (
  market,
  { score, reviews }
) => (
  <>
    TrustScore <Strong>{formatNumber(score)}</Strong> | <Strong>{formatNumber(reviews)}</Strong>{' '}
    {reviewsByMarket[market]}
  </>
);

interface TrustpilotBadgeProps {
  children?: ReactNode;
  className?: string;
}

export const TrustpilotBadge: FC<TrustpilotBadgeProps> = ({ children, className }) => {
  const { market, locale } = currentMarket;
  const { data: score } = useTrustpilotQuery(market);

  if (score) {
    return (
      <Badge
        href={trustpilotUrls[locale]}
        target="_blank"
        rel="noopener noreferrer"
        starsNumber={score.stars}
        bottomTitle={formatBottomTitle(locale, score)}
        className={className}
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (children) return <>{children}</>;

  return (
    <a href={trustpilotUrls[locale]} target="_blank" rel="noopener noreferrer">
      <noscript>Trustpilot Badge</noscript>
    </a>
  );
};
