import { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { H4, IconButton } from '@tourlane/tourlane-ui';
import { NIGHTINGALE_BLACK } from '@tourlane/tourlane-ui/dist/colors';
import { Box } from 'components/Box';
import { useAppContext } from 'reducer';
import { SIMPLIFIED_FLOW_STEPS } from 'steps';
import { getStepFromPathName } from 'utils/routing';

import { HeaderProps } from './Header';
import { LinearFlowContent } from './LinearFlowContent';

export const SimpleFlowContent: FC<HeaderProps> = () => {
  const {
    stepsConfig,
    initialStep,
    stepSequence,
    meta: { offerPreview },
  } = useAppContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentStep = getStepFromPathName(stepsConfig, pathname) || initialStep;
  const currentStepIndex = stepSequence.indexOf(currentStep);
  const stepTotal = stepSequence.length;
  // displayed value is different as we don't include the success step in the count
  const displayedStepTotal = stepTotal - 1;
  const isLastStep = currentStepIndex === stepTotal - 1;

  if (isLastStep) {
    return <LinearFlowContent />;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={[16, null, 24, 32, 40]}
      py={[16, 20, 24, null, 32]}
      maxHeight={[52, 60, 68, 72, 92]}
    >
      <IconButton
        onClick={() => {
          if (currentStep === SIMPLIFIED_FLOW_STEPS.ONE && offerPreview)
            return window.location.assign(`/offer-preview/${offerPreview.uuid}${window.location.search}`);
          return navigate(-1);
        }}
        hasArrow
        arrow="left"
        iconColor={NIGHTINGALE_BLACK}
        iconSize={32}
      />
      <H4>{`${currentStepIndex + 1}/${displayedStepTotal}`}</H4>
    </Box>
  );
};
