import { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Step } from 'components/Step';
import { TripPlanningInfo } from 'components/TripPlanningInfo';
import { USPs } from 'components/USPs';
import { useAppContext } from 'reducer';
import { getNextStepPath, getStepFromPathName } from 'utils/routing';

import { Box } from '../../components/Box';
import { ContactUs } from '../../components/ContactUs';
import { DataSafetyHint } from '../../components/DataSafetyHint';
import { PhoneForm } from '../../components/PhoneForm';
import { SPLIT_FORM_MAX_WIDTH } from '../../const';

export const SimplifiedThree: FC = () => {
  const {
    stepsConfig,
    meta: {
      customer: { firstName },
    },
  } = useAppContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentStep = getStepFromPathName(stepsConfig, pathname);
  const currentStepConfig = stepsConfig[currentStep!];

  return (
    <Step stickyFooterOnDesktop data-cy="step-phone">
      <Box maxWidth={SPLIT_FORM_MAX_WIDTH} mx="auto">
        <PhoneForm
          heading={<ContactUs firstName={firstName} translationsKey="phoneSimplified" />}
          onSubmitCallback={() => {
            const nextStepPath = getNextStepPath(currentStepConfig.id);
            navigate(nextStepPath);
          }}
        >
          <Box pb={[32, null, null, null, 10]} px={[16, null, null, 32]}>
            <DataSafetyHint />
            <TripPlanningInfo />
            <USPs />
          </Box>
        </PhoneForm>
      </Box>
    </Step>
  );
};
