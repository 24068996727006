import { QuestionContextTypes } from 'const';
import isNil from 'lodash-es/isNil';
import { QuestionsType, StepConfigType } from 'stepsConfig.types';

import { BackendContextType } from './questionContext.types';

const getContextValues = ({ questions, key }: { questions: QuestionsType; key: string }) => {
  const { availableAnswers, context } = questions[key];
  const { type, min, max, step } = context || {};

  return {
    ...(availableAnswers && {
      values: availableAnswers.map(({ value }) => value),
    }),
    ...(type && { type }),
    ...(!isNil(min) && { min }),
    ...(!isNil(max) && { max }),
    ...(!isNil(step) && { step }),
  };
};

export const contextMapper = ({ stepConfig }: { stepConfig: StepConfigType }): BackendContextType[] => {
  const { questions, context } = stepConfig;
  const { type, ...remainingContext } = context || {};

  if (!questions) {
    return [];
  }

  return Object.keys(questions)
    .map(question => {
      return {
        id: question,
        type: type as QuestionContextTypes,
        initial: questions![question].initialValue,
        // get context values from the context property in the config
        ...(remainingContext && remainingContext),
        // override with question specific ones if they exist
        ...getContextValues({ questions, key: question }),
      };
    })
    .flat();
};
