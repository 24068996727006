import { FC, SVGProps } from 'react';

import { Base, COLORS, InfoWithIcons, SvgIcon } from '@tourlane/tourlane-ui';

interface InfoWithIconCustomProps {
  text: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

export const InfoWithIconCustom: FC<InfoWithIconCustomProps> = ({ text, icon: Icon }) => (
  <InfoWithIcons
    multiline
    iconMargin={6}
    iconWidth={[20, 20, 20, 26]}
    icon={
      <SvgIcon size={[20, 20, 20, 26]} color={COLORS.ADVENTURE_GREEN}>
        <Icon />
      </SvgIcon>
    }
  >
    <Base textAlign="left">{text}</Base>
  </InfoWithIcons>
);
