import { basePath, currentMarket } from 'const';
import { SIMPLIFIED_FLOW_STEPS } from 'steps';
import { t } from 'utils/translations';

export const config = {
  id: SIMPLIFIED_FLOW_STEPS.THREE,
  idx: 3,
  questionText: t('contactus.greetings'),
  initialValue: { code: currentMarket.locale.slice(-2) },
  next: () => SIMPLIFIED_FLOW_STEPS.FOUR,
  slug: `${basePath}/${SIMPLIFIED_FLOW_STEPS.THREE}`,
};
