import { FC } from 'react';

import { Base, H2, Hr } from '@tourlane/tourlane-ui';
import { Body } from 'components/Body';
import { Box } from 'components/Box';
import { Footer } from 'components/Footer';
import { Step } from 'components/Step';
import { HeadingEntry, NextButton } from 'components/Styled';
import { SNOWPLOW_PAGE_TYPE, currentMarket, isInOperatingMarket } from 'const';
import { useAppContext } from 'reducer';
import { CUSTOMISABLE_OFFER_PATH } from 'steps/Processing/config';
import styled from 'styled-components';
import { trackClick } from 'utils/trackingEvents';
import { t } from 'utils/translations';

import { useAgentShifts } from '../../components/TalkToUs/useAgentShifts';
import { nextRoute } from './config';
import { useSuccessElements } from './useSuccessElements';

const BaseWithBoldSpan = styled(Base)`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: bold;
  }
`;

export const Success: FC = () => {
  const {
    meta: {
      customer: { firstName },
      travelerRouting,
      salesConfig,
      previewId: existingPreviewId,
      travelRequestUuid,
      destination,
    },
  } = useAppContext();

  const isInTheOperatingMarket = isInOperatingMarket(currentMarket.locale);
  const isDirectBooking = travelerRouting === 'directBooking';
  const { isDuringAgentShift, agentShifts, phoneNumber } = useAgentShifts(salesConfig);

  const { HeadlineIcon, Description, BodyCopy, NextSteps } = useSuccessElements({
    isDirectBooking,
    isInTheOperatingMarket,
    isDuringAgentShift,
    agentShifts,
    phoneNumber,
  });

  const goToNextStep = () => {
    let urlToGoTo = nextRoute(isInTheOperatingMarket);

    if (existingPreviewId) {
      trackClick({
        molecule: 'ee_success',
        atom: 'view_offer',
        label: 'view_offer',
        pageType: SNOWPLOW_PAGE_TYPE,
        destinationUuid: destination?.uuid,
      });
      urlToGoTo = `${window.location.origin}${CUSTOMISABLE_OFFER_PATH}/${existingPreviewId}?travel_request_uuid=${travelRequestUuid}`;
    }

    window.location.assign(urlToGoTo);
  };

  const getButtonText = () => {
    if (existingPreviewId) {
      return t('success.buttonLabelExistingPreview');
    }

    if (isInTheOperatingMarket) {
      return t('success.buttonLabelTC');
    }

    return t('success.buttonLabel');
  };

  const buttonText = getButtonText();

  return (
    <Step stickyFooterOnDesktop data-cy="step-success">
      <Body>
        <Box maxWidth={['100%', 440, '100%']} margin="0 auto">
          <Box pb={[32, null, null, null, 40]}>
            <Box textAlign="center">
              <Box position="relative" display="flex" justifyContent="center" alignItems="center" mt={24} mb={16}>
                <HeadlineIcon />
              </Box>
              <H2>{t('success.header', { name: firstName })}</H2>
              <Box mt={[16, 8, null, null, 16]}>
                <BaseWithBoldSpan>
                  <Description />
                </BaseWithBoldSpan>
              </Box>
              <BodyCopy />
            </Box>
          </Box>
        </Box>
        {isInTheOperatingMarket && (
          <>
            <Hr />
            <Box maxWidth={['100%', 440, '100%']} margin="0 auto">
              <Box pt={[32, null, null, null, 40]}>
                <Box textAlign="center" mb={18}>
                  <HeadingEntry withBottomMargin>{t('success.subheading')}</HeadingEntry>
                </Box>
                <NextSteps />
              </Box>
            </Box>
          </>
        )}
      </Body>
      <Footer>
        <NextButton onClick={goToNextStep} type="button">
          {buttonText}
        </NextButton>
      </Footer>
    </Step>
  );
};
