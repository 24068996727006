import PrivateGuidedIcon from '@tourlane/iconography/Icons/EnquiryExperience/PrivateGuided';
import SelfDriveIcon from '@tourlane/iconography/Icons/EnquiryExperience/SelfDrive';
import GroupTourIcon from '@tourlane/iconography/Icons/EnquiryExperience/SmallGroup';
import { addIcons } from 'steps/TravelType/iconUtils';

const icons = {
  PrivateGuidedIcon,
  SelfDriveIcon,
  GroupTourIcon,
};

// @ts-ignore
export const addStepIcons = ({ stepConfig }) => addIcons({ icons, question: 'travelType', stepConfig });
