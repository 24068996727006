import { QuestionContextTypes, basePath, budgetQuestionsEnum, currentMarket } from 'const';
import { STEPS, STEP_KEYS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { formatPrice } from 'utils/formatPrice';
import { t } from 'utils/translations';

export const DEFAULT_MIN_BUDGET = 500;
const DEFAULT_BUDGET_OFFSET = 500;
const DEFAULT_BUDGET_STEP = 500;
export const DEFAULT_MAX_BUDGET = 10000;
const DEFAULT_INITIAL_BUDGET = 2000;

export const config = {
  id: STEPS.BUDGET,
  idx: 6,
  questions: {
    [budgetQuestionsEnum.budget]: {
      id: budgetQuestionsEnum.budget,
      initialValue: DEFAULT_INITIAL_BUDGET,
      answerText: (value: FrontendAnswerValueType) => formatPrice(Number(value), currentMarket.locale),
      context: {
        type: QuestionContextTypes.INCREMENTAL_NUMBER_SLIDER,
        get step(): number {
          return config.configuration.budgetStep;
        },
        get min(): number {
          return config.configuration.minBudget;
        },
        get max(): number {
          return config.configuration.maxBudget;
        },
      },
    },
  },
  configuration: {
    budgetStep: DEFAULT_BUDGET_STEP,
    minBudget: DEFAULT_MIN_BUDGET,
    maxBudget: DEFAULT_MAX_BUDGET,
    budgetOffset: DEFAULT_BUDGET_OFFSET,
  },
  questionText: t('budget.headingInfo'),
  next: (): STEP_KEYS => STEPS.INTENT,
  slug: `${basePath}/budget`,
  context: {},
};
