import { basePath, currentMarket, environment } from 'const';
import { STEPS } from 'steps';

// !TODO manual-check: what is up with this
const portalSuffix = environment.ENVIRONMENT === 'production' ? '/portal' : '/portal-staging';

export const nextRoute = (isInTheOperatingMarket: boolean) =>
  `https://${currentMarket.market}${isInTheOperatingMarket ? portalSuffix : ''}`;

export const config = {
  id: STEPS.SUCCESS,
  idx: 9,
  next: () => STEPS.SUCCESS,
  slug: `${basePath}/success`,
};
