import { QuestionContextTypes, basePath, passengerTypesEnum } from 'const';
import { STEPS, STEP_KEYS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { BackendContextType } from 'utils/questionContext.types';
import { t } from 'utils/translations';

export const notSure = 'notSure';

const MAX_PASSENGER_NUMBER = 20;

const availablePassengers = [
  {
    value: 1,
    title: `1 ${t('simplifiedForm.passengerOne')}`,
  },
  {
    value: 2,
    title: `2 ${t('simplifiedForm.passengers')}`,
  },
  {
    value: 3,
    title: `3 ${t('simplifiedForm.passengers')}`,
  },
  {
    value: 4,
    title: `4${t('passengers.test.fourPlus')}`,
  },
];

export const config: {
  id: STEP_KEYS;
  idx: number;
  questionText: string;
  configuration: { [key: string]: number }; // because of circ-dependency 😑
  context?: Partial<BackendContextType>;
  next: () => STEP_KEYS;
  slug: string;
  questions: {
    [key in keyof typeof passengerTypesEnum | string]: {
      id: passengerTypesEnum | string;
      title: string;
      subtitle?: string;
      answerText: (count: FrontendAnswerValueType) => string;
      initialValue: number;
      availableAnswers?: { value: number; title: string }[];
    };
  };
} = {
  id: STEPS.PASSENGERS,
  idx: 1,
  questionText: t('passengers.headline'),
  configuration: {
    maxNumberPassengers: MAX_PASSENGER_NUMBER,
  },
  context: {
    type: QuestionContextTypes.SINGLE_SELECT_PILL,
  },
  next: () => STEPS.DURATION,
  slug: `${basePath}/passengers`,
  questions: {
    [passengerTypesEnum.adults]: {
      id: passengerTypesEnum.adults,
      title: t('passengers.adults'),
      answerText: (count: FrontendAnswerValueType) =>
        `${count === 4 ? `${count}+` : count} - ${t('passengers.adults')}`,
      initialValue: 2,
      availableAnswers: availablePassengers,
    },
  },
};
