import dayjs from 'dayjs';
import { t } from 'utils/translations';

const SEASONS = ['spring', 'summer', 'autumn', 'winter'] as const;

type Season = (typeof SEASONS)[number];

export const getSeasonName = (date: Date): Season => {
  const month = date.getMonth();
  if (month >= 2 && month <= 4) {
    return 'spring';
  }
  if (month >= 5 && month <= 7) {
    return 'summer';
  }
  if (month >= 8 && month <= 10) {
    return 'autumn';
  }
  return 'winter';
};

export const getSeasonStartDate = (date: Date): Date => {
  const month = date.getMonth();
  const year = date.getFullYear();

  const seasonName = getSeasonName(date);

  if (seasonName === 'spring') return new Date(year, 2, 1); // 1st of March
  if (seasonName === 'summer') return new Date(year, 5, 1); // 1st of June
  if (seasonName === 'autumn') return new Date(year, 8, 1); // 1st of September
  return month < 11 ? new Date(year - 1, 11, 1) : new Date(year, 11, 1); // 1st of December previous year
};

/**
 * Rules:
 * 1. Add 1 month to the current date to start generating seasons
 * 2. If requested date is Jan, or Feb of year XXXX, then the season year should be XXXX-1
 * (i.e. users can confuse Winter 2024(Jan, Feb) with Winter 2024-2025(Dec, Jan, Feb))
 *
 * If season starts from spring2023 summer2023 autumn2023 winter2023-24
 * If season starts from summer2023 autumn2023 winter2023-24 spring2024
 * If season starts from autumn2023 winter2023-24 spring2024 summer2024
 * If season starts from winter2023-24 spring2024 summer2024 autumn2024
 *
 * Possible Seasons Order:
 * ['spring', 'summer', 'autumn', 'winter']
 * ['summer', 'autumn', 'winter', 'spring']
 * ['autumn', 'winter', 'spring', 'summer']
 * ['winter', 'spring', 'summer', 'autumn']
 */

// Return season list starting from the given season
const getSeasonList = (startSeason: string) => {
  const startIndex = SEASONS.findIndex(season => season === startSeason);
  return SEASONS.slice(startIndex).concat(SEASONS.slice(0, startIndex)) as Season[];
};

export const getSeasonsInSequence = (requestedDate = new Date()) => {
  const requestedStartDate = dayjs(requestedDate);
  const seasonsStartDate = requestedStartDate.add(1, 'month'); // Rule 1

  const startSeasonName = getSeasonName(seasonsStartDate.toDate());
  const seasons = getSeasonList(startSeasonName);
  const winterIndex = seasons.findIndex(season => season === 'winter');

  let startYear = requestedStartDate.year();
  if (winterIndex === 0 && requestedStartDate.month() <= 1) startYear -= 1; // Rule 2

  const options = seasons.map((seasonName, index) => {
    const seasonYear = index <= winterIndex ? startYear : startYear + 1; // that would be the season year for the current season in the loop

    const yearDisplay = seasonName === 'winter' ? `${seasonYear}-${seasonYear + 1}` : `${seasonYear}`;

    return {
      value: `${seasonName}${seasonYear}`,
      title: t(`dates.approx.${seasonName}`, { year: yearDisplay }),
    };
  });

  return [...options, { value: 'later', title: t('actions.later') }];
};
