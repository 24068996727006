import { FC } from 'react';

import CheckmarkIcon from '@tourlane/iconography/Glyphs/Other/Checkmark';
import { COLORS } from '@tourlane/tourlane-ui';

import { t } from '../../utils/translations';
import { Box } from '../Box';
import { InfoWithIconCustom } from '../InfoWithIconCustom';

export const USPs: FC = () => {
  const usps = [t('usp.free'), t('usp.bestPrice'), t('usp.customerService')];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      mt={[8]}
      p={[8]}
      backgroundColor={COLORS.BACKGROUND_GRAY}
      borderRadius="20px"
    >
      {usps.map(usp => (
        <Box mb={2} key={usp}>
          <InfoWithIconCustom text={usp} icon={CheckmarkIcon} />
        </Box>
      ))}
    </Box>
  );
};
