import { Suspense, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { SnowplowScript, setMarketingCookies, setupTourlaneCookies } from '@tourlane/tracking';
import AppRouter from 'AppRouter';
import { DynamicMeta } from 'DynamicMeta';
import { ErrorPage } from 'LazySteps';
import { Didomi } from 'components/Didomi';
import { FeatureFlagProvider } from 'components/FeatureFlag';
import { MantisSkeleton } from 'components/Skeleton';
import {
  Market,
  PARENT_HOST,
  PARENT_PAGE,
  PREVIEW_ID,
  TRIP_SURFING,
  currentMarket,
  destinationSlug,
  environment,
  flowTypes,
  inactiveDestinationLandingPage,
  isDestinationEnabledForOP,
} from 'const';
import { initialState } from 'initialState';
import { ACTION_TYPE, AppContext, reducer } from 'reducer';
import { ThemeProvider } from 'styled-components';
import GlobalStylesWithNormalize from 'styles/globalStyles';
import theme from 'styles/theme';
import { DuckTools } from 'utils/duckTools/DuckTools';
import {
  answersLocalStorageKey,
  destinationIsoLocalStorageKey,
  getFromLocalStorage,
  isLocalStorageEnabled,
  removeFromLocalStorage,
  timeToCompletion,
  trackingLocalStorageKey,
} from 'utils/localStorage';
import 'utils/manageDayJs';
import { initializeSentry } from 'utils/sentryInit';
import { trackOnInitialLoad } from 'utils/tracking';
import i18n from 'utils/translations';

import { isEmbedded } from './const/displayModes';
import { useFetchDestination } from './hooks/useFetchDestination';
import { useFetchOfferPreview } from './hooks/useFetchOfferPreview';
import { useSalesConfig } from './hooks/useSalesConfig';
import { getQueryParams } from './utils/getQueryParams';

setupTourlaneCookies();
setMarketingCookies();

initializeSentry();

const rootElement = document.getElementById('root') as HTMLElement;

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const context = useMemo(() => ({ ...state, dispatch }), [state]);
  const [snowplowLoaded, setSnowplowLoaded] = useState(false);
  const [configCatLoaded, setConfigCatLoaded] = useState(false);

  const [parentPage, parentHost, previewId, tripSurfing] = getQueryParams(window.location.search, [
    PARENT_PAGE,
    PARENT_HOST,
    PREVIEW_ID,
    TRIP_SURFING,
  ]);
  const tourlaneDomains = Object.values(Market).map(value => value);
  const isParentTourlane = tourlaneDomains.some(domain => parentHost?.includes(domain));
  const isTripSurfing = tripSurfing === 'true';

  const isProduction = environment.ENVIRONMENT === 'production';
  const shouldRenderDidomiWidget = isProduction && !isParentTourlane;

  const { destinationFetchStatus, destination } = useFetchDestination(dispatch);

  const { flowType } = useFetchOfferPreview({ previewId, dispatch });

  useSalesConfig(dispatch);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPE.SET_TRIP_SURFING,
      payload: { isTripSurfing },
    });
  }, [isTripSurfing]);

  useEffect(() => {
    dispatch({
      type: ACTION_TYPE.SAVE_EMBEDDED,
      payload: { isEmbedded, parentPage, parentHost },
    });

    const snowplowCallback = () => {
      trackOnInitialLoad({
        destinationUuid: destination!.uuid,
        isEmbedded,
        isDestinationEnabledForOP,
        parentPage,
        flowType,
        isLocalStorageEnabled,
      });
      setSnowplowLoaded(true);
    };

    if (destination && flowType) {
      SnowplowScript({
        environment: isProduction ? 'production' : 'staging',
        appId: 'mantis',
        withWebVitals: true,
        callback: snowplowCallback,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination, flowType]);

  useEffect(() => {
    if (flowType !== flowTypes.LINEAR) {
      return;
    }

    const localStorageData = JSON.parse(getFromLocalStorage(answersLocalStorageKey) as string);

    const destinationData = localStorageData?.destination;
    const enquiryData = localStorageData?.enquiry;

    if (destinationData?.slug === destinationSlug) {
      dispatch({
        type: ACTION_TYPE.SET_ANSWERS_FROM_LOCALSTORAGE,
        payload: enquiryData,
      });
    } else {
      removeFromLocalStorage(answersLocalStorageKey);
      removeFromLocalStorage(trackingLocalStorageKey);
      removeFromLocalStorage(destinationIsoLocalStorageKey);
      removeFromLocalStorage(timeToCompletion);
    }
  }, [dispatch, flowType]);

  /* 
   / fix for when safari/FF users navigate back from another page to EE
   / using the browser's back button. they should be redirected to the
   / first question but because of bfcache support a full page reload
   / is not triggered. read more here https://web.dev/bfcache/
   */
  useEffect(() => {
    const pageShowEventHandler = (evt: { persisted: boolean }) => {
      if (evt.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener(
      'pageshow',
      evt => {
        pageShowEventHandler(evt);
      },
      false
    );

    return () => {
      window.removeEventListener('pageshow', pageShowEventHandler);
    };
  }, []);

  const onReadyConfigCat = useCallback((status: boolean) => {
    setConfigCatLoaded(status);
  }, []);

  const isDestinationSuccessfullyFetched = destinationFetchStatus && destinationFetchStatus === 200;
  const isFlowTypeDefined = Boolean(flowType);

  const isAppSetupReady = Boolean(
    isDestinationSuccessfullyFetched && isFlowTypeDefined && snowplowLoaded && configCatLoaded
  );

  if (destinationFetchStatus && destinationFetchStatus !== 200) {
    if (destinationFetchStatus === 404 && !window.location.pathname.includes('offer')) {
      window.location.href = inactiveDestinationLandingPage[currentMarket.market];
      return null;
    }

    return (
      <Suspense fallback={<MantisSkeleton />}>
        <ErrorPage />
      </Suspense>
    );
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <FeatureFlagProvider
          isTripSurfing={isTripSurfing}
          flowType={flowType}
          destination={destination}
          snowplowLoaded={snowplowLoaded}
          onReadyConfigCat={onReadyConfigCat}
        >
          <AppContext.Provider value={context}>
            {shouldRenderDidomiWidget && snowplowLoaded && <Didomi />}
            <GlobalStylesWithNormalize />
            <AppRouter isAppSetupReady={isAppSetupReady} />
            {!isProduction && configCatLoaded && <DuckTools />}
            <DynamicMeta />
          </AppContext.Provider>
        </FeatureFlagProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

const root = createRoot(rootElement);
root.render(<App />);
