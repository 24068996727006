import { destinationsToShowStars } from 'utils/experiment/destinationMap';

import { datesApproxQuestionsEnum } from './enums';
import { Market, currentMarket } from './marketsAndLocales';
import { destinationSlug } from './pathUtils';

export const flowTypes = {
  SIMPLIFIED: 'simplified',
  LINEAR: 'linear',
} as const;

export type FlowTypes = (typeof flowTypes)[keyof typeof flowTypes];

const destinationsEnabledForOPonDE = [
  'suedafrika',
  'island',
  'costa-rica',
  'seychellen',
  'italien',
  'namibia',
  'tansania',
  'thailand',
  'vietnam',
  'neuseeland',
  'kenia',
  'irland',
  'kanada',
  'usa',
  'mexiko',
  'griechenland',
  'vereinigtes-konigreich',
  'peru',
  'ecuador',
  'chile',
  'brasilien',
  'kroatien',
  'kolumbien',
  'argentinien',
  'panama',
  'spanien',
  'mauritius',
  'australien',
  'botswana',
  'kambodscha',
  'portugal',
  'laos',
  'sri-lanka',
  'japan',
  'malaysia',
  'indonesien',
  'saudi-arabien',
  'oman',
  'vae',
  'suedkorea',
  'norwegen',
];
const destinationsEnabledForOPonFR = [
  'etats-unis',
  'afrique-du-sud',
  'tanzanie',
  'islande',
  'costa-rica',
  'canada',
  'namibie',
  'seychelles',
  'ile-maurice',
  'kenya',
  'vietnam',
  'argentine',
  'nouvelle-zelande',
  'perou',
  'australie',
  'chili',
  'indonesie',
  'mexique',
  'bresil',
  'thailande',
  'japon',
];
const destinationEnabledMarket = {
  [Market.De]: destinationsEnabledForOPonDE,
  [Market.Fr]: destinationsEnabledForOPonFR,
  [Market.Nl]: [],
  [Market.Us]: [],
  [Market.Gb]: [],
};
const activeMarkets = [Market.De, Market.Fr];

const SEYCHELLES = '82c39059-45b7-4885-928c-c3ee5c66b20f';
const JAPAN = 'ff48fcf3-9eb3-4621-9456-40528c36132c';
const INDONESIA = '2f791cbc-53cc-49c3-8d13-9f268ab07051';

export const isInActiveMarket = (market: Market) => activeMarkets.includes(market);

export const canShowNewSignUp = (market: Market) => isInActiveMarket(market);

// Example format: [JAPAN]: [{ value: 'spring2024', tooltip: 'dates.disabled.spring' }]
const datesApproxToDisable: any = {
  [datesApproxQuestionsEnum.datesApprox]: {
    [SEYCHELLES]: [
      { value: 'summer2024', tooltip: 'dates.disabled.summer' },
      { value: 'autumn2024', tooltip: 'dates.disabled.autumn' },
      ],
    [INDONESIA]: [
      {value: 'summer2024', tooltip: 'dates.disabled.summer' },
    ]},
};

export const optionsToDisable = (stepId: string, destinationId: string): { value: string; tooltip: string }[] => {
  return datesApproxToDisable[stepId] ? datesApproxToDisable[stepId][destinationId] : undefined;
};

// Example format: [JAPAN]: ['September 2024', 'October 2024']
export const DESTINATIONS_WITH_STOP_SALE_MONTH: Record<string, string[]> = {
  [JAPAN]: ['September 2024', 'October 2024'],
};

export const isDestinationEligibleForOP = (destinationUuid?: string | undefined) =>
  destinationEnabledMarket[currentMarket.market].some(destination => {
    return destinationUuid === destination;
  });

// @ts-ignore
export const isDestinationEnabledForOP: boolean = window.Cypress
  ? // @ts-ignore
    window.Cypress.env('isDestinationEnabledForOP')
  : activeMarkets.includes(currentMarket.market) && isDestinationEligibleForOP(destinationSlug);

export const canShowRecommendationStarsExperiment = (destinationUuid: string) => {
  const destinationsIncludedInExperiment = Object.keys(destinationsToShowStars);
  return destinationsIncludedInExperiment.includes(destinationUuid);
};

export const inactiveDestinationLandingPage = {
  [Market.De]: 'https://www.tourlane.de/derzeit-nicht-verfuegbar/',
  [Market.Fr]: 'https://www.tourlane.fr/actuellement-indisponible/',
  [Market.Us]: 'https://www.tourlane.com/destination-currently-unavailable/',
  [Market.Nl]: '',
  [Market.Gb]: '',
};
