import { FC, PropsWithChildren, ReactNode } from 'react';

import OpeningTimesIcon from '@tourlane/iconography/Glyphs/OpeningTimes/OpeningTimes';
import {
  AppearTransitionPosition,
  Base,
  Big,
  COLORS,
  ExtraSmall,
  Flex,
  Hr,
  InfoDropdownWithArrow,
  ResponsiveValue,
  Small,
  Strong,
  useDeviceType,
} from '@tourlane/tourlane-ui';
import styled from 'styled-components';
import { zIndex } from 'utils/zIndex';

export interface Translations {
  callUsForFreeOn: string;
  openForCall: string;
  closedForCall: string;
  discussTripOverPhone: string;
  agentShifts: string[];
  phone: string;
  phoneNumber: string;
  title: string;
}

interface TalkToUsProps {
  i18n: Translations;
  isDuringAgentShift: boolean;
  offset?: number;
  darkMode?: boolean;
  width?: ResponsiveValue;
  onToggle?: () => void;
  tooltipPosition?: AppearTransitionPosition;
  className?: string;
  onOpen?: () => void;
  tooltipProps?: Record<string, any>;
}

const BoxWithPadding = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const StyledOpeningTimesIcon = styled(OpeningTimesIcon)<{ $canCall: boolean }>`
  fill: ${({ $canCall }) => ($canCall ? COLORS.ADVENTURE_GREEN : COLORS.RIOJA_RED)};
  width: 32px;
  margin-right: 8px;
`;

const FlexWithPadding = styled(Flex)`
  height: 64px;
  font-size: 18px;
  padding: 11px 18px;
  align-items: center;
`;

const StyledInfoDropdownWithArrow = styled(InfoDropdownWithArrow)<{
  $hasTouch: boolean;
}>`
  & button:focus {
    * {
      color: ${COLORS.ADVENTURE_GREEN};
    }
  }

  ${({ $hasTouch }) =>
    !$hasTouch
      ? `
        &:hover {
          * {
            color: ${COLORS.ADVENTURE_GREEN};
          }
        }
      `
      : ''}
`;

const StyledPhone = styled.a`
  color: ${COLORS.ADVENTURE_GREEN};
  font-weight: 600;
`;

export const TalkToUs: FC<PropsWithChildren & TalkToUsProps> = ({
  i18n,
  children,
  offset,
  darkMode = false,
  width,
  onToggle,
  tooltipPosition,
  className,
  onOpen,
  tooltipProps,
  isDuringAgentShift,
}) => {
  const { callUsForFreeOn, openForCall, closedForCall, discussTripOverPhone, agentShifts, title, phoneNumber } = i18n;

  const fontColor = darkMode ? COLORS.SENSATION_WHITE : COLORS.NIGHTINGALE_BLACK;

  const content: ReactNode = (
    <>
      <FlexWithPadding>
        <StyledOpeningTimesIcon $canCall={isDuringAgentShift} />
        <Base color={fontColor}>{isDuringAgentShift ? openForCall : closedForCall}</Base>
      </FlexWithPadding>
      <Hr />
      <BoxWithPadding>
        <Small withBottomMargin color={fontColor}>
          {discussTripOverPhone}
        </Small>
        <Small color={fontColor}>
          <Strong>{callUsForFreeOn}</Strong>
        </Small>
        <Big color={fontColor}>
          Tel.&nbsp;
          {isDuringAgentShift ? (
            <StyledPhone href={`tel:${phoneNumber}`}>{phoneNumber}</StyledPhone>
          ) : (
            <span>{phoneNumber}</span>
          )}
        </Big>
        {agentShifts?.map(agentShift => (
          <ExtraSmall key={agentShift} color={fontColor}>
            {agentShift}
          </ExtraSmall>
        ))}
      </BoxWithPadding>
    </>
  );

  const { hasTouchScreen } = useDeviceType();

  return (
    <StyledInfoDropdownWithArrow
      menu={content}
      tooltipPosition={tooltipPosition || 'bottom-end'}
      zIndex={zIndex.callUsTooltip}
      offset={offset}
      inverse={darkMode}
      blurred={darkMode}
      buttonLabel={title}
      width={width}
      $hasTouch={hasTouchScreen}
      onToggle={onToggle}
      className={className}
      buttonProps={{ noFocus: false }}
      onOpen={onOpen}
      tooltipProps={tooltipProps}
    >
      <Flex flexDirection="row" alignItems="center">
        {children}
      </Flex>
    </StyledInfoDropdownWithArrow>
  );
};
