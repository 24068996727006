import { FlowTypes, destinationSlug, environment, isDevelopment, isTestingEnv } from 'const';
import { StepsConfigType } from 'stepsConfig.types';

import { FlagState } from '../components/FeatureFlag';
import { AnswerInReactContextType } from './answers';
import { BackendResponse, api } from './api';
import { t } from './translations';
import { prepareTravelRequest } from './travelRequestHelpers';

const TRAVEL_REQUEST_URL = `${environment.API_ENDPOINT_CM}/api/v2/travel_requests`;

export interface TravelRequestParams {
  answers: AnswerInReactContextType[];
  customer: any;
  templateId: string;
  stepsConfig: StepsConfigType;
  destinationUuid: string;
  travelerRouting: string;
  experiments: Partial<FlagState>;
  flowType: FlowTypes;
  previewId?: string;
  offerUrl?: string;
}

export const createTravelRequest = async ({
  answers,
  customer,
  templateId,
  stepsConfig,
  destinationUuid,
  travelerRouting,
  experiments,
  previewId,
  flowType,
  offerUrl,
}: TravelRequestParams) => {
  const travelRequest = prepareTravelRequest({
    answers,
    stepsConfig,
    destination: {
      uuid: destinationUuid,
      slug: destinationSlug,
    },
    experiments,
    flowType,
  });

  let body = {
    ...travelRequest,
    customer,
    templateId,
    travelerRouting,
    previewId,
  };

  if (offerUrl) {
    body = {
      ...body,
      // @ts-ignore
      offer_preview_link: offerUrl,
    };
  }

  const shouldUseProxy = !isTestingEnv && !isDevelopment;
  const resp = await api({
    url: shouldUseProxy ? `/enquiry/api/proxyCreateTravelRequest?cmUrl=${TRAVEL_REQUEST_URL}` : TRAVEL_REQUEST_URL,
    method: 'POST',
    body,
  });

  return resp;
};

export const updateTravelRequest = async (travelRequestUuid: string, payload: any) => {
  const resp = await api({
    url: `${TRAVEL_REQUEST_URL}/${travelRequestUuid}`,
    method: 'PUT',
    body: payload,
  });

  return resp;
};

export const handleBackendError = (formik: any, response: BackendResponse) => {
  if (response.errors?.customer) {
    if (response.errors.customer?.phone) {
      formik.setFieldError('number', t('phone.invalid'));
    }
    if (response.errors.customer?.email) {
      formik.setFieldError('email', t('emailform.invalidEmail'));
    }
  } else {
    throw new Error(response.toString());
  }
};
