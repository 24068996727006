import { useCallback, useEffect, useState } from 'react';

import { ACTION_TYPE, useAppContext } from 'reducer';

import { useFeatureFlagContext } from '../components/FeatureFlag';
import { previewIdCookieMaxAge, previewIdCookieName } from '../steps/Processing/config';
import { setCookie } from '../utils/cookie';
import { getExperiments } from '../utils/getExperiments';
import { createPreview } from '../utils/previewRequest';

export const useCreateOfferPreview = () => {
  const [createdOfferPreviewId, setCreatedOfferPreviewId] = useState<string>('');
  const [isCreatingOfferPreview, setIsCreatingOfferPreview] = useState(true);

  const {
    answers,
    meta: { destination, offerPreview },
    stepsConfig,
    dispatch,
  } = useAppContext();

  const { flags } = useFeatureFlagContext();
  const experiments = getExperiments(flags);
  const templateId = offerPreview?.cuid;

  const createOfferPreview = useCallback(async () => {
    try {
      const {
        meta: { uuid },
      } = await createPreview(answers, stepsConfig, destination.uuid, experiments, templateId);

      setCreatedOfferPreviewId(uuid);
      setIsCreatingOfferPreview(false);

      dispatch({ type: ACTION_TYPE.STORE_META, payload: { previewId: uuid } });

      setCookie({
        cookieName: previewIdCookieName,
        cookieValue: uuid,
        maxAge: previewIdCookieMaxAge,
        secure: true,
      });
    } catch (e) {
      setIsCreatingOfferPreview(false);

      if (process.env.NODE_ENV === 'development')
        // eslint-disable-next-line no-console
        console.log('Error in creating offer preview', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, stepsConfig, destination.uuid]);

  useEffect(() => {
    createOfferPreview();
  }, [createOfferPreview]);

  return { createdOfferPreviewId, isCreatingOfferPreview };
};
