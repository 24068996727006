import { TOURLANE_ID_COOKIE_NAME, VISIT_ID_COOKIE_NAME } from '@tourlane/tracking';
import { FlowTypes, currentMarket, flowTypes } from 'const';
import { SIMPLIFIED_FLOW_STEPS, STEP_KEYS } from 'steps';
import { StepConfigType, StepsConfigType } from 'stepsConfig.types';
import { AnswerInReactContextType, BackendAnswerType } from 'utils/answers';

import { FlagState } from '../components/FeatureFlag';
import { FrontendAnswerType } from './answerHook';
import { getCookie } from './cookie';
import { contextMapper } from './questionContext';

export type BackendAnswerTypeEnrichedWithQuestion = {
  question: {
    identifier: string;
    text: string | undefined;
  };
  answers: BackendAnswerType[];
};

export const getTravelRequestRelevantCookies = () => {
  const tourlaneId = getCookie(TOURLANE_ID_COOKIE_NAME);
  const visitId = getCookie(VISIT_ID_COOKIE_NAME);

  return { visitId, tourlaneId };
};

export const getCurrentTimeZone = () => {
  const timeZone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;

  if (timeZone) {
    return timeZone;
  }

  return '';
};

const createPostBody = ({
  answers,
  destination,
  experiments,
  flowType,
}: {
  answers: BackendAnswerTypeEnrichedWithQuestion[];
  destination: IDestination;
  experiments: Partial<FlagState>;
  flowType: FlowTypes;
}) => ({
  timeZone: getCurrentTimeZone(),
  meta: {
    appId: 'mantis',
    flowType,
  },
  destination,
  enquiry: answers,
  experiments,
  market: currentMarket.market,
  locale: currentMarket.locale,
  tracking: {
    ...getTravelRequestRelevantCookies(),
  },
});

const filterFalseValues = (answers: BackendAnswerType[]) => answers.filter(({ value }) => value !== false);

export const answerMapper = ({
  answers,
  stepConfig,
}: {
  answers: FrontendAnswerType;
  stepConfig: StepConfigType;
}): BackendAnswerType[] => {
  /**
     Transforms 🦾
    {
      departureDate: '03-03',
      arrivalDate: '05-05',
      wouldLikeToBookWithTourlane: true
    }
           ⬇️
    [{
      identifier: 'departureDate',
      value: '03-03',
      text: 'From'
      },{
      identifier: 'arrivalDate',
      ...
    }]
  * */

  // Would be nice to cover with unit test
  const { questions } = stepConfig;

  // Covering Name step which needs to be reported, but doesn't have question
  if (!questions) {
    return [];
  }

  return filterFalseValues(
    Object.keys(answers)
      .map(question => {
        const value = answers[question];
        // Arrays like poi, activities
        if (Array.isArray(value)) {
          // Return an array which will be flattened
          return value.map(valueInArray => ({
            identifier: question,
            value: valueInArray,
            text: questions![question].answerText(valueInArray, questions![question].availableAnswers),
          }));
        }

        // Strings, numbers and booleans
        return {
          identifier: question,
          value,
          text: questions![question].answerText(value, questions![question].availableAnswers),
        };
      })
      .flat()
  );
};

interface IDestination {
  uuid: string;
  slug: string;
}

const getContextMapper = ({
  stepsConfig,
  flowType,
  stepId,
}: {
  stepsConfig: StepsConfigType;
  flowType: FlowTypes;
  stepId: STEP_KEYS;
}) => {
  if (flowType === flowTypes.SIMPLIFIED) {
    const simplifedFlowContext = contextMapper({
      stepConfig: stepsConfig[SIMPLIFIED_FLOW_STEPS.ONE],
    });
    return simplifedFlowContext.filter(item => item.id === stepId);
  }
  return contextMapper({ stepConfig: stepsConfig[stepId] });
};

export const prepareTravelRequest = ({
  answers,
  stepsConfig,
  destination,
  experiments,
  flowType,
}: {
  answers: AnswerInReactContextType[];
  stepsConfig: StepsConfigType;
  destination: IDestination;
  experiments: Partial<FlagState>;
  flowType: FlowTypes;
}) => {
  const enquiryAnswers = answers.map(({ stepId, value: stepAnswers }) => {
    // pick stepConfig specific to the questionId
    const stepConfig = stepsConfig[stepId];
    return {
      question: {
        identifier: stepId,
        text: stepConfig?.questionText,
      },
      answers: answerMapper({
        answers: stepAnswers,
        stepConfig,
      }),
      context: getContextMapper({ stepsConfig, flowType, stepId }),
    };
  });
  return createPostBody({ answers: enquiryAnswers, destination, experiments, flowType });
};
