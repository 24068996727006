import { QuestionContextTypes, basePath, highlightsQuestionsEnum } from 'const';
import { STEPS } from 'steps';
import { AvailableAnswerType, FrontendAnswerValueType } from 'utils/answerHook';
import { t } from 'utils/translations';

export const config = {
  id: STEPS.HIGHLIGHTS,
  idx: 4,
  questions: {
    [highlightsQuestionsEnum.poi]: {
      id: highlightsQuestionsEnum.poi,
      answerText: (value: FrontendAnswerValueType, availableAnswers?: AvailableAnswerType[]) =>
        availableAnswers!.find(a => a.value === value)!.title,
      initialValue: [],
      context: {
        type: QuestionContextTypes.MULTI_SELECT_CARDS,
      },
    },
    [highlightsQuestionsEnum.preferTalkToAgent]: {
      id: highlightsQuestionsEnum.preferTalkToAgent,
      initialValue: true,
      answerText: () => t('actions.preferTalkToAgent'),
      context: {
        type: QuestionContextTypes.CHECKBOX,
      },
    },
  },
  questionText: t('highlights.checkHighlightsInterest'),
  next: () => STEPS.TRAVEL_TYPE,
  slug: `${basePath}/highlights`,
};
