import { Locale, getCurrency } from 'const';

export const formatPrice = (price: number, locale: Locale, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getCurrency(locale),
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(price);
};
