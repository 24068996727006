import { FC } from 'react';

import { Trans } from 'react-i18next';

import ChevronDown from '@tourlane/iconography/Glyphs/Navigation/ChevronDown';
import { COLORS, SvgIcon } from '@tourlane/tourlane-ui';
import { StepItem } from 'components/StepItem';

import { Box } from '../Box';
import { SuccessNextStep } from './SuccessNextStep.types';

export const SuccessNextSteps: FC<{
  steps: SuccessNextStep[];
  dataCy?: string;
}> = ({ steps, dataCy }) => (
  <div data-cy={dataCy}>
    {steps.map((step: SuccessNextStep, index: number) => (
      <Box textAlign="center" key={step.title} flex="0 44%" mb={[16, 24, 40, 32]}>
        <StepItem
          title={step.title}
          content={<Trans>{step.description}</Trans>}
          icon={step.icon}
          iconSize={{ xs: 130, sm: 114, md: 144 }}
        />
        {index < steps.length - 1 && (
          <Box mt={[16, 24]} display={['block', null, 'none']}>
            <SvgIcon size={40} color={COLORS.ELEMENT_GRAY}>
              <ChevronDown />
            </SvgIcon>
          </Box>
        )}
      </Box>
    ))}
  </div>
);
