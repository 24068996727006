import { basePath, emailFormFieldsEnum, genderOptionsEnum } from 'const';
import { SIMPLIFIED_FLOW_STEPS } from 'steps';
import { t } from 'utils/translations';

export const config = {
  id: SIMPLIFIED_FLOW_STEPS.TWO,
  idx: 2,
  questionText: t('emailHeader.greetings'),
  initialValues: {
    [emailFormFieldsEnum.gender]: genderOptionsEnum.male,
  },
  next: () => SIMPLIFIED_FLOW_STEPS.THREE,
  slug: `${basePath}/${SIMPLIFIED_FLOW_STEPS.TWO}`,
};
