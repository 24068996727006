import React from 'react';

import * as Sentry from '@sentry/browser';
import { SomethingWentWrong } from 'components/SomethingWentWrong';
import { STEP_KEYS } from 'steps';
import { STEPS } from 'stepsConfig';

interface ComponentWithPreload extends React.LazyExoticComponent<React.ComponentType<any>> {
  preload: () => void;
  loadWhenThis: STEP_KEYS;
}

const lazyWithPreload = (factory: () => any, loadWhenThis: STEP_KEYS): ComponentWithPreload => {
  const Component = React.lazy(factory);

  (Component as ComponentWithPreload).preload = factory;
  (Component as ComponentWithPreload).loadWhenThis = loadWhenThis;
  return Component as ComponentWithPreload;
};

export const handleChunkError = () => {
  Sentry.captureException(new Error(`Failed to download step JS chunk`));
  return { default: SomethingWentWrong };
};

const Passengers = lazyWithPreload(
  () =>
    import('steps/Passengers' /* webpackChunkName: "Passengers" */)
      .then(modules => {
        return {
          default: modules.Passengers,
        };
      })
      .catch(handleChunkError),
  STEPS.PASSENGERS
);

const DurationDatesHighlights = lazyWithPreload(
  () =>
    import('steps/DurationDatesHighlights' /* webpackChunkName: "DurationDatesHighlights" */)
      .then(modules => {
        return {
          default: modules.DurationDatesHighlights,
        };
      })
      .catch(handleChunkError),
  STEPS.DURATION
);

const ActivityTypeBudget = lazyWithPreload(
  () =>
    import('steps/ActivityTypeBudget' /* webpackChunkName: "ActivityTypeBudget" */)
      .then(modules => {
        return {
          default: modules.ActivityTypeBudget,
        };
      })
      .catch(handleChunkError),
  STEPS.HIGHLIGHTS
);

const SplitForm = lazyWithPreload(
  () =>
    import('steps/SplitForm' /* webpackChunkName: "SplitForm" */)
      .then(modules => {
        return {
          default: modules.SplitForm,
        };
      })
      .catch(handleChunkError),
  STEPS.BUDGET
);

const Intent = lazyWithPreload(
  () =>
    import('steps/Intent' /* webpackChunkName: "Intent" */)
      .then(modules => {
        return {
          default: modules.Intent,
        };
      })
      .catch(handleChunkError),
  STEPS.BUDGET
);

export const LazySteps = [Passengers, DurationDatesHighlights, ActivityTypeBudget, SplitForm, Intent];

export const ErrorPage = React.lazy(() =>
  import('components/ErrorPage' /* webpackChunkName: "ErrorPage" */)
    .then(modules => {
      return { default: modules.ErrorPage };
    })
    .catch(handleChunkError)
);
