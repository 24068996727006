import { FC, PropsWithChildren, useContext } from 'react';

import { COLORS, Flex } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { StepContext, StepContextType } from 'components/Step/context';
import { isEmbedded } from 'const/displayModes';
import styled from 'styled-components';
import { zIndex } from 'utils/zIndex';

const NIGHTINGALE_BLACK_30 = '0 0 24px 0 rgba(63, 65, 68, 0.3)'; // IE11 hack

const StyledBox = styled(Box)`
  transition: box-shadow 0.2s ease-in-out;
`;

export const Footer: FC<PropsWithChildren> = ({ children }) => {
  const { stickyFooter, isAtBottom } = useContext<StepContextType>(StepContext);

  const paddingY = isEmbedded ? [16, null, 16, 16] : [16, null, 24, 16];

  return (
    <StyledBox
      as="footer"
      px={16}
      py={paddingY}
      position={stickyFooter ? 'absolute' : 'static'}
      bottom="0"
      left="0"
      textAlign="center"
      width="100%"
      zIndex={zIndex.footer}
      maxWidth={[null, null, null, '100%']}
      backgroundColor={COLORS.SENSATION_WHITE}
      boxShadow={stickyFooter && !isAtBottom ? NIGHTINGALE_BLACK_30 : 'none'}
    >
      <Flex direction="ttb" center>
        {children}
      </Flex>
    </StyledBox>
  );
};
