import { FC } from 'react';

import { Market, currentMarket } from '../../const';
import BadgeDe from './MoneyTrustBadge.svg';
import { StyledMoneyBadgeImage } from './MoneyTrustBadgeStyled';
import BadgeFr from './PetitFute.png';

const BADGE = {
  [Market.De]: {
    src: BadgeDe,
    alt: 'Focus Money Top Kundendienst',
    width: 48,
    height: 80,
  },
  [Market.Fr]: {
    src: BadgeFr,
    alt: 'Petit Futé',
    width: 90,
    height: 85,
  },
  [Market.Nl]: null,
  [Market.Gb]: null,
  [Market.Us]: null,
};
export const MoneyTrustBadge: FC = () => {
  const { market } = currentMarket;

  if (!BADGE[market]) {
    return null;
  }

  return <StyledMoneyBadgeImage loading="lazy" {...BADGE[market]} />;
};
