import { ConsentID, ConsentStatus } from '@didomi/react';

import { ConsentEventTypes } from '../../const';

export const getFormSubmitEventContext = (event: ConsentEventTypes) => ({
  schema: 'iglu:com.tourlane/form_submit_event/jsonschema/1-0-1',
  data: {
    event_type: event,
    form_type: 'consent-form',
  },
});

export const consentStatusReducer =
  (func: (id: ConsentID) => ConsentStatus) => (acc: Array<[string, boolean]>, current: string) =>
    current.includes('tourlane') ? acc : [...acc, [current, func(current)]];

export const checkTupleValueIsTrue = (e: [string, boolean]) => e[1] === true;
