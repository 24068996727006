import { snowplow } from '@tourlane/tracking';

import { environment } from './environment';

// typically used when we cannot send personalised tracking data because of
// privacy regulations etc
export const PROTECTED = '--PROTECTED--';

export const EMBEDDED = 'embedded';
export const PARENT_PAGE = 'page';
export const PARENT_HOST = 'host';
export const TRAVEL_REQUEST_UUID = 'travel_request_uuid';
export const PREVIEW_ID = 'preview_id';

export const TRIP_SURFING = 'trip_surfing';
export const CH = 'ch';
export const CMP = 'cmp';
export const FLAG_NAME = 'flag_name';
export const FLAG_VALUE = 'flag_value';

export const SNOWPLOW_PAGE_TYPE = snowplow.PageType.LANDING_PAGE;

// mantis version identifier for snowplow
export const SNOWPLOW_QUESTIONNAIRE_VERSION = 1;

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const customXSHeight = 550;

export const SPLIT_FORM_MAX_WIDTH = [414, null, 504, 496, 578];

export const accessTokenLocalStorageKey =
  environment.ENVIRONMENT === 'production' ? 'customer:cm-access-token' : 'customer:cm-staging-access-token';
