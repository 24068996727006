import { logFetchErrorToSentry } from './logError';

const handleFetchFailure = (val: unknown, url: string) => {
  logFetchErrorToSentry({ error: JSON.stringify(val), url });
  return Promise.reject(val);
};

const defaultHeaders = {
  'Content-Type': 'application/json',
};

type ErrorFromBackend = {
  customer: {
    email?: string[];
    phone?: string[];
  };
};

export interface BackendResponse {
  errors?: ErrorFromBackend;
}

export const api = async ({
  url = '',
  method = 'GET',
  body,
  headers = {},
}: {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: Record<string, unknown>;
  headers?: Record<string, unknown>;
}) => {
  try {
    const res = await fetch(url, {
      method,
      headers: { ...defaultHeaders, ...headers },
      ...(body && { body: JSON.stringify(body) }),
    } as any);

    const json = await res.json();

    if (!res.ok) {
      return handleFetchFailure(json, url);
    }

    return json;
  } catch (err) {
    return handleFetchFailure(err, url);
  }
};
