import { destinationSlug, environment } from 'const';
import { StepsConfigType } from 'stepsConfig.types';
import { api } from 'utils/api';

import { FlagState } from '../../components/FeatureFlag';
import { preparePreviewRequest } from './helpers';

const CREATE_PREVIEW_URL = `${environment.API_ENDPOINT_CM}/api/v2/previews`;

export const createPreview = async (
  answers: any,
  stepsConfig: StepsConfigType,
  destinationUuid: string,
  experiments: Partial<FlagState>,
  templateId?: string
) => {
  const previewRequest = preparePreviewRequest(answers, stepsConfig, experiments, templateId);

  return api({
    url: CREATE_PREVIEW_URL,
    method: 'POST',
    body: {
      ...previewRequest,
      destination: {
        uuid: destinationUuid,
        slug: destinationSlug,
      },
    },
  });
};
