import { durationOptionsEnum } from 'const';
import dayjs from 'dayjs';
import { STEPS } from 'steps';
import { getSeasonName, getSeasonStartDate, getSeasonsInSequence } from 'steps/DatesApprox/getSeasonsInSequence';
import { t } from 'utils/translations';

import { mapDurationToOptions } from '../../utils/mappers';

export const availableAnswers = getSeasonsInSequence();

export const availableDuration = [
  {
    value: durationOptionsEnum.ONE_WEEK,
    title: t('duration.oneWeek'),
  },
  {
    value: durationOptionsEnum.TWO_WEEKS,
    title: t('duration.twoWeeks'),
  },
  {
    value: durationOptionsEnum.THREE_TO_FOUR_WEEKS,
    title: t('duration.threeToFourWeeks'),
  },
  {
    value: durationOptionsEnum.FIVE_PLUS_WEEKS,
    title: t('duration.fivePlusWeeks'),
  },
];

export const changeNumberOfWeeksToString = (value: number) => {
  switch (value) {
    case 1:
      return durationOptionsEnum.ONE_WEEK;
    case 2:
      return durationOptionsEnum.TWO_WEEKS;
    case 3:
      return durationOptionsEnum.THREE_TO_FOUR_WEEKS;
    case 4:
      return durationOptionsEnum.THREE_TO_FOUR_WEEKS;
    case 5:
      return durationOptionsEnum.FIVE_PLUS_WEEKS;
    default:
      return '';
  }
};

export const getDurationText = (numberOfWeeks: number) => {
  if (numberOfWeeks === 1) {
    return t('duration.singleWeek');
  }
  return t('duration.multipleWeeks');
};

export const defaultValues = {
  [STEPS.DURATION]: {
    asNumber: 2,
    asString: durationOptionsEnum.TWO_WEEKS,
  },
};

export const addDaysToCurrentDate = (days: number) => {
  const newDate = new Date();

  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

export const mapDatesToDuration = (dates: Date[]) => {
  const [start, end] = dates;
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  const duration = endDate.diff(startDate, 'day');

  return mapDurationToOptions(duration);
};

export const mapFixedDatesToDatesApprox = (startDate: Date) => {
  const dateSeason = getSeasonStartDate(startDate);
  const yearSeason = dateSeason.getFullYear();
  const nameSeason = getSeasonName(dateSeason);
  return `${nameSeason}${yearSeason}`;
};
