import * as Yup from 'yup';

export const USA = '1f0e3970-d3b8-4af8-ba72-33898fc408da';

export const simplifiedFormSchema = Yup.object({
  passengers: Yup.string().required(),
  datesApprox: Yup.string().required(),
  duration: Yup.string().required(),
  budget: Yup.number().required(),
  travelType: Yup.string().required(),
});
