import { SIMPLIFIED_FLOW_STEPS, STEPS } from 'steps';
import { config as budgetConfig } from 'steps/Budget/config';
import { config as datesConfig } from 'steps/Dates/config';
import { config as datesApproxConfig } from 'steps/DatesApprox/config';
import { config as durationConfig } from 'steps/Duration/config';
import { config as emailConfig } from 'steps/Email/config';
import { config as highlightsConfig } from 'steps/Highlights/config';
import { config as intentConfig } from 'steps/Intent/config';
import { config as passengerConfig } from 'steps/Passengers/config';
import { config as phoneConfig } from 'steps/Phone/config';
import { config as processingConfig } from 'steps/Processing/config';
import { config as simplifiedFourConfig } from 'steps/Simplified.Four/config';
import { config as simplifiedOneConfig } from 'steps/Simplified.One/config';
import { config as simplifiedThreeConfig } from 'steps/Simplified.Three/config';
import { config as simplifiedTwoConfig } from 'steps/Simplified.Two/config';
import { config as successConfig } from 'steps/Success/config';
import { config as travelTypeConfig } from 'steps/TravelType/config';
import { StepsConfigType } from 'stepsConfig.types';

export { STEPS };

export const linearFlowStepsConfig = {
  [STEPS.HIGHLIGHTS]: highlightsConfig,
  [STEPS.PASSENGERS]: passengerConfig,
  [STEPS.DATES_APPROX]: datesApproxConfig,
  [STEPS.DURATION]: durationConfig,
  [STEPS.DATES]: datesConfig,
  [STEPS.BUDGET]: budgetConfig,
  [STEPS.INTENT]: intentConfig,
  [STEPS.TRAVEL_TYPE]: travelTypeConfig,
  [STEPS.PROCESSING]: processingConfig,
  [STEPS.EMAIL]: emailConfig,
  [STEPS.PHONE]: phoneConfig,
  [STEPS.SUCCESS]: successConfig,
};

export const simplifiedFlowStepsConfig = {
  [SIMPLIFIED_FLOW_STEPS.ONE]: simplifiedOneConfig,
  [SIMPLIFIED_FLOW_STEPS.TWO]: simplifiedTwoConfig,
  [SIMPLIFIED_FLOW_STEPS.THREE]: simplifiedThreeConfig,
  [SIMPLIFIED_FLOW_STEPS.FOUR]: simplifiedFourConfig,
};

export const stepsConfig: StepsConfigType = {
  ...linearFlowStepsConfig,
  // TODO split this later
  ...simplifiedFlowStepsConfig,
};
