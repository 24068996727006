import { BudgetDefinition, Destination } from 'reducer';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { destinationHasBudgetDefinitions } from 'utils/budgetDefinitions';

import { DEFAULT_MIN_BUDGET } from './config';

export const getBudgetConfigsByTravelType = (
  budgetDefnConfigs: BudgetDefinition[] | undefined,
  travelType: FrontendAnswerValueType | undefined
) => {
  return budgetDefnConfigs?.find(config => config.travelType === travelType);
};

export const getMinBudgetByDuration = (
  budgetDefn: BudgetDefinition | undefined,
  duration: FrontendAnswerValueType | undefined
) => {
  const minBudget = budgetDefn?.configs?.find(config => config.travelDuration === duration)?.minimum;

  return minBudget || DEFAULT_MIN_BUDGET;
};

export const getBudgetDefinitionsByDestination = (destination: Destination) => {
  let budgetDefinitions: BudgetDefinition[] | undefined;

  if (destinationHasBudgetDefinitions(destination)) {
    budgetDefinitions = destination.budgetDefinitions;
  }

  return budgetDefinitions;
};
