import { QuestionContextTypes, basePath } from 'const';
import { STEPS, STEP_KEYS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { t } from 'utils/translations';

export const NO_SELECTION = 'noSelection';

export const availableAnswers = [
  { title: t('intent.optionOne'), value: 'firstInspiration' },
  { title: t('intent.optionTwo'), value: 'freeAdvice' },
  { title: t('intent.optionThree'), value: 'concreteOffer' },
  { title: t('intent.optionFour'), value: 'helpWithBooking' },
  { title: NO_SELECTION, value: NO_SELECTION },
];

export const config = {
  id: STEPS.INTENT,
  idx: 7,
  questions: {
    [STEPS.INTENT]: {
      id: STEPS.INTENT,
      initialValue: [NO_SELECTION],
      answerText: (value: FrontendAnswerValueType) => availableAnswers!.find(a => a.value === value)!.value,
      context: {
        type: QuestionContextTypes.MULTI_SELECT_CARDS,
      },
      availableAnswers,
    },
  },
  questionText: t('intent.heading'),
  next: (): STEP_KEYS => STEPS.PROCESSING,
  slug: `${basePath}/intent`,
};
