import { Locale, currentMarket, phoneFormFieldsEnum } from 'const';

import { PhoneDataRecord } from './config';

// from TUI
export const phoneCodes = [
  ['Afghanistan', '+93', 'AF'],
  ['Ägypten', '+20', 'EG'],
  ['Aland Islands', '+358', 'AX'],
  ['Albanien', '+355', 'AL'],
  ['Algerien', '+213', 'DZ'],
  ['Amerikanischen Samoa-Inseln', '+1 684', 'AS'],
  ['Andorra', '+376', 'AD'],
  ['Angola', '+244', 'AO'],
  ['Anguilla', '+1 264', 'AI'],
  ['Antarktis', '+672', 'AQ'],
  ['Antigua und Barbuda', '+1268', 'AG'],
  ['Äquatorialguinea', '+240', 'GQ'],
  ['Argentinien', '+54', 'AR'],
  ['Armenien', '+374', 'AM'],
  ['Aruba', '+297', 'AW'],
  ['Aserbaidschan', '+994', 'AZ'],
  ['Äthiopien', '+251', 'ET'],
  ['Australien', '+61', 'AU'],
  ['Bahamas', '+1 242', 'BS'],
  ['Bahrain', '+973', 'BH'],
  ['Bangladesch', '+880', 'BD'],
  ['Barbados', '+1 246', 'BB'],
  ['Belgien', '+32', 'BE'],
  ['Belize', '+501', 'BZ'],
  ['Benin', '+229', 'BJ'],
  ['Bermuda', '+1 441', 'BM'],
  ['Besetzte palästinensische Gebiete', '+970', 'PS'],
  ['Bhutan', '+975', 'BT'],
  ['Bolivien, plurinationaler Staat von', '+591', 'BO'],
  ['Bosnien und Herzegowina', '+387', 'BA'],
  ['Botswana', '+267', 'BW'],
  ['Brasilien', '+55', 'BR'],
  ['Britisches Territorium des Indischen Ozeans', '+246', 'IO'],
  ['Brunei Darussalam', '+673', 'BN'],
  ['Bulgarien', '+359', 'BG'],
  ['Burkina Faso', '+226', 'BF'],
  ['Burundi', '+257', 'BI'],
  ['Cayman Inseln', '+ 345', 'KY'],
  ['Chile', '+56', 'CL'],
  ['China', '+86', 'CN'],
  ['Cookinseln', '+682', 'CK'],
  ['Costa Rica', '+506', 'CR'],
  ['Deutschland', '+49', 'DE'],
  ['Dominica', '+1 767', 'DM'],
  ['Dominikanische Republik', '+1 849', 'DO'],
  ['Dschibuti', '+253', 'DJ'],
  ['Dänemark', '+45', 'DK'],
  ['Ecuador', '+593', 'EC'],
  ['El Salvador', '+503', 'SV'],
  ['Elfenbeinküste', '+225', 'CI'],
  ['Eritrea', '+291', 'ER'],
  ['Estland', '+372', 'EE'],
  ['Falklandinseln (Malvinas)', '+500', 'FK'],
  ['Fidschi', '+679', 'FJ'],
  ['Finnland', '+358', 'FI'],
  ['Frankreich', '+33', 'FR'],
  ['Französisch Polynesien', '+689', 'PF'],
  ['Französisch-Guayana', '+594', 'GF'],
  ['Färöer Inseln', '+298', 'FO'],
  ['Gabun', '+241', 'GA'],
  ['Gambia', '+220', 'GM'],
  ['Togo', '+228', 'TG'],
  ['Georgia', '+995', 'GE'],
  ['Ghana', '+233', 'GH'],
  ['Gibraltar', '+350', 'GI'],
  ['Grenada', '+1 473', 'GD'],
  ['Griechenland', '+30', 'GR'],
  ['Großbritannien', '+44', 'GB'],
  ['Grönland', '+299', 'GL'],
  ['Guadeloupe', '+590', 'GP'],
  ['Guam', '+1 671', 'GU'],
  ['Guatemala', '+502', 'GT'],
  ['Guernsey', '+44', 'GG'],
  ['Guinea', '+224', 'GN'],
  ['Guinea-Bissau', '+245', 'GW'],
  ['Guyana', '+595', 'GY'],
  ['Haiti', '+509', 'HT'],
  ['Heiliger Stuhl (Staat Vatikanstadt)', '+379', 'VA'],
  ['Honduras', '+504', 'HN'],
  ['Hongkong', '+852', 'HK'],
  ['Indien', '+91', 'IN'],
  ['Indonesien', '+62', 'ID'],
  ['Irak', '+964', 'IQ'],
  ['Iran, Islamische Republik Persischer Golf', '+98', 'IR'],
  ['Irland', '+353', 'IE'],
  ['Island', '+354', 'IS'],
  ['Isle of Man', '+44', 'IM'],
  ['Israel', '+972', 'IL'],
  ['Italien', '+39', 'IT'],
  ['Jamaika', '+1 876', 'JM'],
  ['Japan', '+81', 'JP'],
  ['Jemen', '+967', 'YE'],
  ['Jersey', '+44', 'JE'],
  ['Jordan', '+962', 'JO'],
  ['Jungferninseln, USA', '+1 340', 'VI'],
  ['Kambodscha', '+855', 'KH'],
  ['Kamerun', '+237', 'CM'],
  ['Kanada', '+1', 'CA'],
  ['Kap Verde', '+238', 'CV'],
  ['Kasachstan', '+7 7', 'KZ'],
  ['Katar', '+974', 'QA'],
  ['Kenia', '+254', 'KE'],
  ['Kirgisistan', '+996', 'KG'],
  ['Kiribati', '+686', 'KI'],
  ['Kokosinseln (Keelinginseln)', '+61', 'CC'],
  ['Kolumbien', '+57', 'CO'],
  ['Komoren', '+269', 'KM'],
  ['Kongo', '+242', 'CG'],
  ['Kongo, die Demokratische Republik Kongo', '+243', 'CD'],
  ['Korea, Demokratische Volksrepublik Korea', '+850', 'KP'],
  ['Korea, Republik Südkorea', '+82', 'KR'],
  ['Kroatien', '+385', 'HR'],
  ['Kuba', '+53', 'CU'],
  ['Kuwait', '+965', 'KW'],
  ['Laos', '+856', 'LA'],
  ['Lesotho', '+266', 'LS'],
  ['Lettland', '+371', 'LV'],
  ['Libanon', '+961', 'LB'],
  ['Liberia', '+231', 'LR'],
  ['Libysche arabische Jamahiriya', '+218', 'LY'],
  ['Liechtenstein', '+423', 'LI'],
  ['Litauen', '+370', 'LT'],
  ['Luxemburg', '+352', 'LU'],
  ['Macao', '+853', 'MO'],
  ['Madagaskar', '+261', 'MG'],
  ['Malawi', '+265', 'MW'],
  ['Malaysia', '+60', 'MY'],
  ['Malediven', '+960', 'MV'],
  ['Mali', '+223', 'ML'],
  ['Malta', '+356', 'MT'],
  ['Marokko', '+212', 'MA'],
  ['Marshallinseln', '+692', 'MH'],
  ['Martinique', '+596', 'MQ'],
  ['Mauretanien', '+222', 'MR'],
  ['Mauritius', '+230', 'MU'],
  ['Mayotte', '+262', 'YT'],
  ['Mazedonien', '+389', 'MK'],
  ['Mexiko', '+52', 'MX'],
  ['Mikronesien, Föderierte Staaten von Mikronesien', '+691', 'FM'],
  ['Moldawien', '+373', 'MD'],
  ['Monaco', '+377', 'MC'],
  ['Mongolei', '+976', 'MN'],
  ['Montenegro', '+382', 'ME'],
  ['Montserrat', '+1664', 'MS'],
  ['Mosambik', '+258', 'MZ'],
  ['Myanmar', '+95', 'MM'],
  ['Namibia', '+264', 'NA'],
  ['Nauru', '+674', 'NR'],
  ['Nepal', '+977', 'NP'],
  ['Neu-Kaledonien', '+687', 'NC'],
  ['Neuseeland', '+64', 'NZ'],
  ['Nicaragua', '+505', 'NI'],
  ['Niederlande', '+31', 'NL'],
  ['Niederländische Antillen', '+599', 'AN'],
  ['Niger', '+227', 'NE'],
  ['Nigeria', '+234', 'NG'],
  ['Niue', '+683', 'NU'],
  ['Norfolkinsel', '+672', 'NF'],
  ['Norwegen', '+47', 'NO'],
  ['Nördliche Marianneninseln', '+1 670', 'MP'],
  ['Österreich', '+43', 'AT'],
  ['Oman', '+968', 'OM'],
  ['Pakistan', '+92', 'PK'],
  ['Palau', '+680', 'PW'],
  ['Panama', '+507', 'PA'],
  ['Papua Neu-Guinea', '+675', 'PG'],
  ['Paraguay', '+595', 'PY'],
  ['Peru', '+51', 'PE'],
  ['Philippinen', '+63', 'PH'],
  ['Pitcairn', '+872', 'PN'],
  ['Polen', '+48', 'PL'],
  ['Portugal', '+351', 'PT'],
  ['Puerto Rico', '+1 939', 'PR'],
  ['Ruanda', '+250', 'RW'],
  ['Rumänien', '+40', 'RO'],
  ['Russland', '+7', 'RU'],
  ['Saint Barthelemy', '+590', 'BL'],
  ['Saint Pierre und Miquelon', '+508', 'PM'],
  ['Salomon-Inseln', '+677', 'SB'],
  ['Sambia', '+260', 'ZM'],
  ['Samoa', '+685', 'WS'],
  ['San Marino', '+378', 'SM'],
  ['Sankt Martin', '+590', 'MF'],
  ['Sao Tome und Principe', '+239', 'ST'],
  ['Saudi Arabien', '+966', 'SA'],
  ['Schweden', '+46', 'SE'],
  ['Schweiz', '+41', 'CH'],
  ['Senegal', '+221', 'SN'],
  ['Serbien', '+381', 'RS'],
  ['Seychellen', '+248', 'SC'],
  ['Sierra Leone', '+232', 'SL'],
  ['Singapur', '+65', 'SG'],
  ['Slowakei', '+421', 'SK'],
  ['Slowenien', '+386', 'SI'],
  ['Somalia', '+252', 'SO'],
  ['Spanien', '+34', 'ES'],
  ['Sri Lanka', '+94', 'LK'],
  ['St. Helena, Ascension und Tristan Da Cunha', '+290', 'SH'],
  ['St. Kitts und Nevis', '+1 869', 'KN'],
  ['St. Lucia', '+1 758', 'LC'],
  ['St. Vincent und die Grenadinen', '+1 784', 'VC'],
  ['Sudan', '+249', 'SD'],
  ['Suriname', '+597', 'SR'],
  ['Svalbard und Jan Mayen', '+47', 'SJ'],
  ['Swasiland', '+268', 'SZ'],
  ['Syrische Arabische Republik', '+963', 'SY'],
  ['Süd-Georgien und die südlichen Sandwich-Inseln', '+500', 'GS'],
  ['Südafrika', '+27', 'ZA'],
  ['Tadschikistan', '+992', 'TJ'],
  ['Taiwan', '+886', 'TW'],
  ['Tansania, Vereinigte Republik Tansania', '+255', 'TZ'],
  ['Thailand', '+66', 'TH'],
  ['Timor-Leste', '+670', 'TL'],
  ['Tokelau', '+690', 'TK'],
  ['Tonga', '+676', 'TO'],
  ['Trinidad und Tobago', '+1 868', 'TT'],
  ['Türkei', '+90', 'TR'],
  ['Tschad', '+235', 'TD'],
  ['Tschechische Republik', '+420', 'CZ'],
  ['Tunesien', '+216', 'TN'],
  ['Turkmenistan', '+993', 'TM'],
  ['Turks- und Caicosinseln', '+1 649', 'TC'],
  ['Tuvalu', '+688', 'TV'],
  ['Uganda', '+256', 'UG'],
  ['Ukraine', '+380', 'UA'],
  ['Ungarn', '+36', 'HU'],
  ['Uruguay', '+598', 'UY'],
  ['Usbekistan', '+998', 'UZ'],
  ['Vanuatu', '+678', 'VU'],
  ['Venezuela, Bolivarische Republik Venezuela', '+58', 'VE'],
  ['Vereinigte Arabische Emirate', '+971', 'AE'],
  ['Vereinigte Staaten', '+1', 'US'],
  ['Vietnam', '+84', 'VN'],
  ['Virgin Inseln, Britisch', '+1 284', 'VG'],
  ['Wallis und Futuna', '+681', 'WF'],
  ['Weihnachtsinsel', '+61', 'CX'],
  ['Weißrussland', '+375', 'BY'],
  ['Wiedervereinigung', '+262', 'RE'],
  ['Zentralafrikanische Republik', '+236', 'CF'],
  ['Zimbabwe', '+263', 'ZW'],
  ['Zypern', '+357', 'CY'],
].map(country => ({
  value: country[2],
  label: country[1] as Extract<PhoneDataRecord, phoneFormFieldsEnum.code>,
  search: `${country[0]} ${country[1]} ${country[2]}`,
  custom: {
    label: country[0],
    hint: country[1],
  },
}));

// from TUI
export const filterOption = (option: any, searchText: string) =>
  option.data.search.toLowerCase().includes(searchText.toLowerCase());

interface MentionMeOptions {
  firstName: string;
  lastName: string;
  email: string;
}

export interface MentionMeData {
  firstname: string;
  surname: string;
  email: string;
  situation: string;
  locale: string;
  booking_currency: string;
}

export const handleMentionMeTracking = (options: MentionMeOptions) => {
  const supportedLocale = [Locale.De, Locale.Fr];

  if (supportedLocale.includes(currentMarket.locale)) {
    return {
      firstname: options.firstName,
      surname: options.lastName,
      email: options.email,
      situation: 'ee-form',
      locale: currentMarket.locale.replaceAll('-', '_'),
      booking_currency: currentMarket.currency,
    };
  }

  return undefined;
};
