import * as React from 'react';

import { Box } from 'components/Box';
import { useAppContext } from 'reducer';

type BodyProps = {
  children?: React.ReactNode;
};

export const useBodyPadding = () => {
  const { isEmbedded } = useAppContext();

  const px = isEmbedded ? [16, 32, 40, 32, null] : [16, null, 148, 32, 40];

  const py = isEmbedded ? 32 : [32, null, null, null, 10];
  const pb = isEmbedded ? [32, null, 40, 24, null] : null;

  return { px, py, pb };
};

export const Body: React.FC<BodyProps> = ({ children }) => {
  const padding = useBodyPadding();

  return (
    <Box {...padding} as="main">
      {children}
    </Box>
  );
};
