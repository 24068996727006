import { useEffect, useState } from 'react';

import { Locale } from '../../const';
import { AgentShiftsConfig } from '../../reducer';
import { AgentShift } from './TalkToUs.types';

const dateToMarketTimeZone = (date: Date, timezone: string) => {
  return new Date(
    date.toLocaleString(Locale.Us, {
      timeZone: timezone,
    })
  );
};

export const useAgentShifts = (agentShiftsConfig?: AgentShiftsConfig, usersDate = new Date()) => {
  const [isDuringAgentShift, setIsDuringAgentShift] = useState(false);
  const [agentShifts, setAgentShifts] = useState<AgentShift[]>([]);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!agentShiftsConfig) {
      setAgentShifts([]);
      setIsDuringAgentShift(false);
      setPhoneNumber('');

      return;
    }

    let isDuringShift = isDuringAgentShift;
    const shifts = agentShifts;

    const {
      timezone,
      availabilities,
      phoneNumber: { international },
    } = agentShiftsConfig;
    const usersDateInMarketTimezone = dateToMarketTimeZone(usersDate, timezone);

    const usersDayOfTheWeek = usersDateInMarketTimezone.getDay();
    const usersHour = usersDateInMarketTimezone.getHours();

    if (availabilities.length > 0) {
      availabilities.forEach(shift => {
        const { days, hours } = shift;
        const openDays = {
          from: days.from.isoWeekday,
          to: days.to.isoWeekday,
        };

        const { from: weekdayFrom, to: weekdayTo } = openDays;
        const { from: hourFrom, to: hourTo } = hours;

        shifts.push({ weekdayFrom, weekdayTo, hourFrom, hourTo });
      });

      isDuringShift = shifts.some(shift => {
        const { weekdayFrom, weekdayTo, hourFrom, hourTo } = shift;

        const isTodayOpen = usersDayOfTheWeek >= weekdayFrom && usersDayOfTheWeek <= weekdayTo;
        const isNowOpen = usersHour >= hourFrom && usersHour < hourTo;

        return isTodayOpen && isNowOpen;
      });
    }

    setAgentShifts(shifts);
    setIsDuringAgentShift(isDuringShift);
    setPhoneNumber(international);
  }, [agentShiftsConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isDuringAgentShift,
    agentShifts,
    phoneNumber,
  };
};
