import { destinationSlugFromUrl, followUpSlug } from 'const';
import { ACTION_TYPE, Dispatch } from 'reducer';
import { STEP_KEYS } from 'steps';
import { STEPS, stepsConfig } from 'stepsConfig';
import { StepConfigType, StepsConfigType } from 'stepsConfig.types';
import { AvailableAnswerType, FrontendAnswerType, getUpdatedStepAnswer } from 'utils/answerHook';

import { previewIdCookieName } from '../steps/Processing/config';
import { getCookie } from './cookie';
import { isDuckToolsOn } from './duckTools';
import { getTravelRequestUuidFromQueryParams } from './queryParams';
import { trackQuestionSkipAsSubmit } from './trackingEvents';

export const getStepFromPathName = (sc: StepsConfigType, pathname: string) => {
  const currentStepConfig = Object.values(sc)?.find(({ slug }: { slug: string }) => slug === pathname);
  return currentStepConfig?.id;
};

export const isValidRoute = () => {
  const noSlugAtAll = !window.location.pathname.split('/')[1];
  const firstSlugIsLOrEEE = ['l', 'eee'].includes(window.location.pathname.split('/')[1]);

  return noSlugAtAll || (firstSlugIsLOrEEE && destinationSlugFromUrl);
};

type SkipStepOptions = {
  skipStepConfig: StepConfigType;
  skipStepId: STEP_KEYS;
  dispatch: Dispatch;
  destinationUuid: string;
  questionId: string;
  answerValue: AvailableAnswerType['value'];
};

export const skipStepToNextPath = ({
  skipStepConfig,
  skipStepId,
  dispatch,
  destinationUuid,
  questionId,
  answerValue,
}: SkipStepOptions) => {
  const skipStepAnswer = getUpdatedStepAnswer({}, questionId, answerValue);

  trackQuestionSkipAsSubmit(skipStepAnswer, skipStepConfig.idx, skipStepConfig, destinationUuid);

  dispatch({
    type: ACTION_TYPE.SAVE_ANSWER,
    payload: { stepId: skipStepConfig.id, answer: skipStepAnswer },
  });

  const nextStep = stepsConfig[skipStepId].next(skipStepAnswer);
  const queryString = window.location.search;

  return stepsConfig[nextStep].slug + queryString;
};

export const getNextStepPath = (stepId: STEP_KEYS, answer?: FrontendAnswerType): string => {
  const nextStep = stepsConfig[stepId].next(answer);
  const queryString = window.location.search;

  return stepsConfig[nextStep].slug + queryString;
};

export const shouldRedirectToStart = ({
  pathname,
  search,
  defaultStep,
}: {
  pathname: string;
  search: string;
  defaultStep: STEP_KEYS;
}): boolean => {
  const isFollowUpStepWithTravelRequestUuid = () =>
    pathname === followUpSlug && getTravelRequestUuidFromQueryParams(search);

  const isCustomerNavigatingBackFromOfferPreview = () =>
    pathname === stepsConfig[STEPS.PROCESSING].slug && !!getCookie(previewIdCookieName);

  // If ducktools is ON AND the link matches one of our steps we do not redirect to start
  if (
    isDuckToolsOn &&
    Object.values(stepsConfig)
      .map(step => step.slug)
      .includes(pathname)
  ) {
    return false;
  }

  // If we do NOT have duckTools on AND
  // the current pathName is neither Passengers nor followup AND
  // customer is not navigating back from offer preview
  // We will redirect user to passengers
  return (
    pathname === '/' ||
    (pathname !== stepsConfig[defaultStep].slug &&
      !isFollowUpStepWithTravelRequestUuid() &&
      !isCustomerNavigatingBackFromOfferPreview())
  );
};
