import * as React from 'react';

import OpeningTimes from '@tourlane/iconography/Glyphs/OpeningTimes/OpeningTimes';
import { SvgIcon } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import styled from 'styled-components';

import { Circle } from './Circle';
import { phoneAnimation } from './animation';

const PHONE_ICON_SIZE = 64;
const PHONE_CIRCLE_SIZE = 80;

interface PhoneSuccessProps {
  withAnimation: boolean;
  color: string;
}
interface StyledSvgIconProps {
  withAnimation: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledSvgCircle = styled(SvgIcon)`
  transform: rotate(-90deg);
`;
const StyledSvgIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledSvgIconSimple = styled(({ withAnimation, ...props }) => <SvgIcon {...props} />)<StyledSvgIconProps>`
  ${({ withAnimation }) => withAnimation && phoneAnimation}
`;

export const PhoneSuccess: React.FC<PhoneSuccessProps> = ({ withAnimation, color }) => {
  return (
    <Box width={PHONE_CIRCLE_SIZE} height={PHONE_CIRCLE_SIZE} position="relative">
      {withAnimation && (
        <StyledSvgCircle size={PHONE_CIRCLE_SIZE}>
          <Circle color={color} />
        </StyledSvgCircle>
      )}
      <StyledSvgIconWrapper>
        <StyledSvgIconSimple size={PHONE_ICON_SIZE} color={color} withAnimation={withAnimation}>
          <OpeningTimes />
        </StyledSvgIconSimple>
      </StyledSvgIconWrapper>
    </Box>
  );
};
