import { QuestionContextTypes, basePath, datesApproxQuestionsEnum, durationQuestionsEnum, fixedDatesEnum } from 'const';
import { STEPS } from 'steps';
import { FrontendAnswerValueType } from 'utils/answerHook';
import { t } from 'utils/translations';

import { formatDate } from '../../utils/manageDayJs';
import { availableAnswers as availableAnswersDuration } from '../Duration/config';
import { availableAnswers } from './helpers';

export const config = {
  id: STEPS.DATES,
  idx: 2,
  questions: {
    [datesApproxQuestionsEnum.datesApprox]: {
      id: datesApproxQuestionsEnum.datesApprox,
      initialValue: availableAnswers[0].value,
      answerText: (value: FrontendAnswerValueType) => availableAnswers.find(a => a.value === value)?.title || '',
      availableAnswers,
      context: {
        type: QuestionContextTypes.DATEPICKER,
        values: availableAnswers.map(({ value }) => value),
      },
    },
    [durationQuestionsEnum.duration]: {
      id: durationQuestionsEnum.duration,
      initialValue: null,
      answerText: (value: FrontendAnswerValueType) =>
        config.questions.duration.availableAnswers.find(a => a.value === value)?.title,
      availableAnswers: availableAnswersDuration,
      context: {
        type: QuestionContextTypes.DATEPICKER,
        values: availableAnswers.map(({ value }) => value),
      },
    },
    [fixedDatesEnum.startDate]: {
      id: fixedDatesEnum.startDate,
      initialValue: null,
      answerText: (value: FrontendAnswerValueType) => (value ? `${formatDate(new Date(value.toString()))}` : ''),
      context: {
        type: QuestionContextTypes.DATEPICKER,
      },
    },
    [fixedDatesEnum.endDate]: {
      id: fixedDatesEnum.endDate,
      initialValue: null,
      answerText: (value: FrontendAnswerValueType) => (value ? `${formatDate(new Date(value.toString()))}` : ''),
      context: {
        type: QuestionContextTypes.DATEPICKER,
      },
    },
  },
  questionText: t('dates.fixedOrFlexible.headline'),
  next: () => STEPS.DURATION,
  slug: `${basePath}/dates`,
};
