const CM_STAGING = 'https://cm.tlservers.com';
const CM_PRODUCTION = 'https://cm.tourlane.com';
const DM_STAGING = 'https://dm.tlservers.com';
const DM_PRODUCTION = 'https://dm.tourlane.com';
const GECKO_STAGING = 'https://gecko-api-staging.tlservers.com/';
const GECKO_PRODUCTION = 'https://gecko-api.tlservers.com/';

const defaultEnvironmentConfig = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  API_ENDPOINT_CM: process.env.REACT_APP_ENVIRONMENT === 'production' ? CM_PRODUCTION : CM_STAGING,
  API_ENDPOINT_DM: process.env.REACT_APP_ENVIRONMENT === 'production' ? DM_PRODUCTION : DM_STAGING,
  API_ENDPOINT_GECKO: process.env.REACT_APP_ENVIRONMENT === 'production' ? GECKO_PRODUCTION : GECKO_STAGING,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  TRUSTPILOT_KEY: process.env.REACT_APP_TRUSTPILOT_API_KEY,
};

export const environment = defaultEnvironmentConfig;

// @ts-ignore
export const isTestingEnv = !!window.Cypress;
export const isDevelopment = process.env.NODE_ENV === 'development';
