import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { TOURLANE_ID_COOKIE_NAME } from '@tourlane/tracking';
import { FlowTypes, currentMarket, flowTypes, isTestingEnv } from 'const';
import { getCookie } from 'utils/cookie';
import { trackFeatureFlags } from 'utils/tracking';

import { Destination } from '../../reducer';
import { configCatClient } from './FeatureFlag.config';
import { FlagState, SettingKeyValue } from './FeatureFlag.types';

interface FeatureFlagProviderProps extends PropsWithChildren {
  snowplowLoaded: boolean;
  onReadyConfigCat: (status: boolean) => void;
  destination: Destination | null;
  flowType: FlowTypes | undefined;
  isTripSurfing: boolean;
}

type State = {
  flags: Partial<FlagState>;
  setFlags: (flags: Partial<FlagState>) => void;
};

const initialState: State = {
  flags: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFlags: () => {},
};

const FeatureFlagContext = createContext<State>(initialState);

const mapFeatureFlags = (flags: SettingKeyValue[]): Partial<FlagState> => {
  return flags.reduce((flags, item) => ({ ...flags, [item.settingKey]: item.settingValue }), {});
};

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({
  children,
  destination,
  flowType,
  snowplowLoaded,
  onReadyConfigCat,
  isTripSurfing,
}) => {
  const MAX_RETRIES = 2;
  const identifier = getCookie(TOURLANE_ID_COOKIE_NAME);

  const [state, setState] = useState(initialState);

  const setFlags = (flags: Partial<FlagState>) => {
    setState({ ...state, flags });
  };

  const context = useMemo(() => ({ ...state }), [state]);

  useEffect(() => {
    setState({ ...state, setFlags });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchExperimentFlags = async () => {
    // when testing do not set other experiment flags, regardless of the value of isUserEligibleForABTests
    if (isTestingEnv) {
      setFlags({});
      onReadyConfigCat(true);
      return;
    }

    if (!destination) {
      return;
    }

    let retry = 0;
    const fetch = async () => {
      const onFetchError = () => {
        retry += 1;

        if (retry < MAX_RETRIES) {
          fetch();
          return;
        }

        onReadyConfigCat(true); // load the app without flags
      };

      try {
        // Getting the flag values from CFA config
        await configCatClient.forceRefreshAsync();
        const res = await configCatClient.getAllValuesAsync({
          custom: { market: currentMarket.market, destination: destination?.uuid || '' },
          identifier,
        });

        const flagsObj = mapFeatureFlags(res);

        setFlags({ ...flagsObj });
      } catch (error) {
        onFetchError();
      }
    };

    fetch();
  };

  useEffect(() => {
    if ((flowType && flowType === flowTypes.SIMPLIFIED) || isTripSurfing) {
      setFlags({ has_configcat_flags: 'true' });
    } else {
      fetchExperimentFlags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination, flowType]);

  useEffect(() => {
    return () => {
      configCatClient.dispose();
    };
  }, []);

  useEffect(() => {
    if (!snowplowLoaded) return;
    if (Object.entries(state?.flags).length === 0) return;

    trackFeatureFlags(state.flags as FlagState);
    onReadyConfigCat(true); // this way we make sure we will load the app with flags added to snowplow context

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snowplowLoaded, state]);

  return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw Error('useFeatureFlagContext must be used within FeatureFlagContext');
  }

  return context;
};
