import React from 'react';

import { Flex, H2, Skeleton } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { isEmbedded } from 'const/displayModes';

function RowSkeleton() {
  return (
    <Box marginBottom={[8, null, 16]}>
      <Skeleton widthRandomness={0} height={{ xs: 42, md: 50 }} rounded />
    </Box>
  );
}

export const MantisSkeleton: React.FC = () => (
  <Box my={16} mx={[16, null, 32]}>
    <Box mt={[32, null, null, null, 40]} mb={[8, 8, 12, 12, 16]}>
      <H2>
        <Skeleton widthRandomness={0} count={2} />
      </H2>
    </Box>

    <Box marginBottom={[8, null, 24]}>
      <Skeleton widthRandomness={0} count={2} />
    </Box>

    <RowSkeleton />
    <RowSkeleton />
    <RowSkeleton />
    {!isEmbedded && <RowSkeleton />}

    <Flex marginTop={[32, null, null, null, 8]} justifyContent="center">
      <Skeleton height={[44, null, null, 54]} width={[300, 300, 250, 280]} widthRandomness={0} />
    </Flex>
    <Flex>
      <Skeleton />
    </Flex>
  </Box>
);
