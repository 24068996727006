import { useEffect, useMemo, useRef, useState } from 'react';

import { useMediaBreakpoints } from '@tourlane/tourlane-ui';

type ScrollDataType = {
  scrollHeight: number;
  scrollTop: number;
  clientHeight: number;
};

type TopBottomScrollType = {
  isAtBottom: boolean;
};

const getScrollData = (node: any): ScrollDataType => ({
  scrollHeight: node.scrollHeight,
  scrollTop: node.scrollTop,
  clientHeight: node.clientHeight,
});

export const useScroll = (): [ScrollDataType | null, any] => {
  const [scrollData, setScrollData] = useState<ScrollDataType | null>(null);

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const node = ref.current;
    if (!node) {
      return;
    }

    const setData = () => setScrollData(getScrollData(node));

    // get initial scroll-position
    setData();

    // get scroll position on scroll event
    node.addEventListener('scroll', setData);
    window.addEventListener('resize', setData);
    window.addEventListener('scroll', setData);

    // eslint-disable-next-line consistent-return
    return () => {
      node?.removeEventListener('scroll', setData); // eslint-disable-line no-unused-expressions
      window?.removeEventListener('scroll', setData); // eslint-disable-line no-unused-expressions
      window?.removeEventListener('resize', setData); // eslint-disable-line no-unused-expressions
    };
  }, [ref.current]); // eslint-disable-line

  return [scrollData, ref];
};

export const useTopBottomScroll = (): [TopBottomScrollType, any] => {
  const isMobile = useMediaBreakpoints([true, false]);
  const [scrollData, ref] = useScroll();

  const delta = isMobile ? 8 : 0;

  const isAtBottom = useMemo(
    () => (scrollData ? scrollData.scrollTop + scrollData.clientHeight + delta >= scrollData.scrollHeight : false),
    [scrollData, delta]
  );

  return [{ isAtBottom }, ref];
};
