import Cookie from 'universal-cookie';

interface SetCookieProps {
  cookieName: string;
  cookieValue: string;
  path?: string;
  maxAge?: number;
  secure?: boolean;
}

export const COOKIE_MAX_AGE = 900000;
export const CUSTOMER_UUID_COOKIE_NAME = 'customer_uuid';

export const getCookie = (cookieName: string, defaultCookie = '') => {
  const cookie = new Cookie();
  return cookie.get(cookieName) ?? defaultCookie;
};

export const setCookie = ({ cookieName, cookieValue, path = '/', maxAge = COOKIE_MAX_AGE, secure }: SetCookieProps) => {
  const cookie = new Cookie();

  cookie.set(cookieName, cookieValue, {
    maxAge,
    httpOnly: false,
    path,
    secure,
  });
};
