import { createContext, useContext } from 'react';

import { FlowTypes, destinationSlug, flowTypes, highlightsQuestionsEnum, possibleTravelTypesEnum } from 'const';
import set from 'lodash-es/set';
import { SIMPLIFIED_FLOW_STEPS, STEP_KEYS } from 'steps';
import { PhoneDataRecord } from 'steps/Phone/config';
import { STEPS, stepsConfig } from 'stepsConfig';
import { StepsConfigType } from 'stepsConfig.types';
import { FrontendAnswerType, MultipleAnswerType } from 'utils/answerHook';
import {
  AnswerInReactContextType,
  BackendAnswerTypeEnrichedWithQuestion,
  answersUtils,
  createAvailableAnswers,
  getAnswersFromLocalStorage,
} from 'utils/answers';
import { answersLocalStorageKey, saveToLocalStorage } from 'utils/localStorage';
import { prepareTravelRequest } from 'utils/travelRequestHelpers';

import { EmailDataRecordTypes } from './types/EmailDataRecord.types';

type Voucher = {
  code: string;
  discount: {
    type: string;
    amount_off: number; // eslint-disable-line camelcase
  };
  expiration_date: null; // eslint-disable-line camelcase
  tracking_id: string; // eslint-disable-line camelcase
  valid: boolean;
};

// TODO: refactor/rename
type MetaUuid = {
  travelRequestUuid: string;
  customerUuid: string;
};

// Preview component related types
export type Coordinates = [number, number];
type Highlight = Record<'uuid' | 'name' | 'imageUrl', string>;

export type Template = {
  templateId: string;
  route: Coordinates[][];
  coordinates: Coordinates[];
  touristicAreas: Highlight[];
  imageUrl: string;
};

export type DestinationTracking = Record<string, string>;

export type BudgetDefinitionConfig = {
  minimum: number;
  travelDuration: 'oneWeek' | 'twoWeeks' | 'threeToFourWeeks' | 'fivePlusWeeks';
};

export type BudgetDefinition = {
  travelType: possibleTravelTypesEnum;
  configs: BudgetDefinitionConfig[];
};

type DestinationData = {
  uuid: string;
  slug: string;
};

export type Destination = {
  name: string;
  uuid: string;
  imageUrl: string;
  tracking: DestinationTracking;
  budgetDefinitions: BudgetDefinition[];
  parentDestination?: DestinationData;
};

type PreviewId = {
  previewId?: string;
};

type OfferPreview = {
  offerPreview:
    | {
        uuid: string;
        cuid?: string;
        price?: number;
        duration?: number;
        surfing?: boolean;
      }
    | undefined;
};

export type PayloadEmbedded = {
  isEmbedded: boolean;
  parentPage: string | null;
  parentHost: string | null;
};

export enum Weekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

type DayAvailability = {
  isoWeekday: number;
  key: Weekday;
};

type Availability = {
  days: {
    from: DayAvailability;
    to: DayAvailability;
  };
  hours: {
    from: number;
    to: number;
  };
};

type PhoneNumber = {
  international: string;
  local: string;
};

export type AgentShiftsConfig = {
  availabilities: Availability[];
  phoneNumber: PhoneNumber;
  timezone: string;
} | null;

export type TravelerRouting = 'directBooking' | 'classicBooking' | '';

type Customer = EmailDataRecordTypes & PhoneDataRecord;
type PayloadCustomer = { customer: Customer };
type PayloadDestination = { destination: Destination };
type PayloadTemplate = { template: Template };
type PayloadVoucher = { voucher?: Voucher };
type PayloadTravelerRouting = {
  travelerRouting: TravelerRouting;
};
type PayloadTrip = {
  trip: {
    uuid: string;
    url: string;
    status: 'incomplete' | '';
  };
};

type PayloadFeatureFlags = {
  featureFlags: {
    [key: string]: string;
  };
};

type PayloadSalesConfig = {
  salesConfig?: AgentShiftsConfig;
};

type FlowType = {
  flowType: FlowTypes;
};

type StepsMeta = {
  steps: {
    [string: string]: any;
  };
};

type TimeToCompletionInSeconds = {
  timeToCompletionInSeconds: number;
};

type AppLoadedTimestamp = {
  appLoadedTimestamp: number;
};

export type PayloadMeta =
  | MetaUuid
  | PayloadCustomer
  | PayloadTemplate
  | PayloadDestination
  | PayloadVoucher
  | PayloadTravelerRouting
  | PayloadTrip
  | PayloadFeatureFlags
  | PayloadSalesConfig
  | OfferPreview
  | PreviewId
  | FlowType
  | AppLoadedTimestamp
  | TimeToCompletionInSeconds;

export type State = {
  isEmbedded: boolean;
  isTripSurfing: boolean;
  parentPage: string | null;
  parentHost: string | null;
  isLoadingDestination: boolean;
  stepsConfig: StepsConfigType;
  answers: AnswerInReactContextType[];
  breadcrumbs: STEP_KEYS[];
  meta: MetaUuid &
    PayloadCustomer &
    PayloadTemplate &
    PayloadDestination &
    PayloadTrip &
    PayloadVoucher &
    PayloadTravelerRouting &
    PayloadFeatureFlags &
    PayloadSalesConfig &
    OfferPreview &
    PreviewId &
    FlowType &
    StepsMeta &
    AppLoadedTimestamp &
    TimeToCompletionInSeconds;
  initialStep: STEP_KEYS;
  stepSequence: Array<STEP_KEYS>;
};

// all possible ACTION Types, including going from a step to another.
export enum ACTION_TYPE {
  GOTO = 'GOTO',
  SAVE_ANSWER = 'SAVE_ANSWER',
  SAVE_MULTIPLE_ANSWERS = 'SAVE_MULTIPLE_ANSWERS',
  DEBUG = 'DEBUG',
  SKIP = 'SKIP',
  TOGGLE_BREADCRUMB = 'TOGGLE_BREADCRUMB',
  STORE_META = 'STORE_META',
  STORE_AVAILABLE_ANSWERS = 'STORE_AVAILABLE_ANSWERS',
  SET_ANSWERS_FROM_LOCALSTORAGE = 'SET_ANSWERS_FROM_LOCALSTORAGE',
  SAVE_EMBEDDED = 'SAVE_EMBEDDED',
  LOADING_DESTINATION = 'LOADING_DESTINATION',
  UPDATE_STEP_PATH = 'UPDATE_STEP_PATH',
  SET_INITIAL_STEP = 'SET_INITIAL_STEP',
  SET_STEP_SEQUENCE = 'SET_STEP_SEQUENCE',
  SET_FLOW_TYPE = 'SET_FLOW_TYPE',
  SET_TRIP_SURFING = 'SET_TRIP_SURFING',
}

export type Action =
  | {
      type: ACTION_TYPE.SAVE_ANSWER;
      payload: { stepId: STEP_KEYS; answer: FrontendAnswerType; meta?: any; shouldSaveToLocalStorage?: boolean };
    }
  | {
      type: ACTION_TYPE.SAVE_MULTIPLE_ANSWERS;
      payload: MultipleAnswerType[];
    }
  | {
      type: ACTION_TYPE.STORE_META;
      payload: PayloadMeta;
    }
  | {
      type: ACTION_TYPE.STORE_AVAILABLE_ANSWERS;
      payload: {
        activities: any;
        accommodations: any;
        pois: any;
      };
    }
  | {
      type: ACTION_TYPE.SET_ANSWERS_FROM_LOCALSTORAGE;
      payload: BackendAnswerTypeEnrichedWithQuestion[];
    }
  | {
      type: ACTION_TYPE.SAVE_EMBEDDED;
      payload: PayloadEmbedded;
    }
  | {
      type: ACTION_TYPE.LOADING_DESTINATION;
      payload: boolean;
    }
  | {
      type: ACTION_TYPE.UPDATE_STEP_PATH;
      payload: { path: string; value: unknown };
    }
  | {
      type: ACTION_TYPE.SET_INITIAL_STEP;
      payload: STEP_KEYS;
    }
  | {
      type: ACTION_TYPE.SET_STEP_SEQUENCE;
      payload: Array<STEP_KEYS>;
    }
  | {
      type: ACTION_TYPE.SET_FLOW_TYPE;
      payload: { flowType: FlowTypes };
    }
  | {
      type: ACTION_TYPE.SET_TRIP_SURFING;
      payload: { isTripSurfing: boolean };
    };

export type Dispatch = (args: Action) => void;

type AppContextType = State & {
  dispatch: Dispatch;
};

export const AppContext = createContext<AppContextType>({} as any);

// this can be used as a hook in functional components to get current Context
export const useAppContext = () => useContext(AppContext);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPE.SAVE_ANSWER: {
      const {
        answers,
        meta: {
          destination: { uuid },
        },
      } = state;
      const { stepId, answer, meta: answerMeta, shouldSaveToLocalStorage = true } = action.payload;

      const newState = {
        ...state,
        meta: {
          ...state.meta,
          steps: {
            ...(state.meta.steps || {}),
            ...(answerMeta && {
              [stepId]: {
                ...answerMeta,
              },
            }),
          },
        },
        answers: answersUtils.add(answers, {
          stepId,
          value: answer,
        } as AnswerInReactContextType),
      };

      if (shouldSaveToLocalStorage) {
        const { enquiry, destination: eeDestination } = prepareTravelRequest({
          answers: newState.answers,
          stepsConfig: state.stepsConfig,
          destination: {
            uuid,
            slug: destinationSlug,
          },
          experiments: {},
          flowType: state.meta.flowType,
        });

        saveToLocalStorage(answersLocalStorageKey, {
          enquiry,
          destination: eeDestination,
        });
      }

      return newState;
    }

    case ACTION_TYPE.SAVE_MULTIPLE_ANSWERS: {
      const formAnswers = action.payload;

      const { answers } = state;

      formAnswers.forEach(formAnswer => {
        const { stepId, value } = formAnswer;
        const answerIndex = answers.findIndex(answer => answer.stepId === stepId);
        if (answerIndex !== -1) {
          answers[answerIndex].value = value;
        } else {
          // @ts-ignore
          answers.push(formAnswer);
        }
      });

      return { ...state, answers };
    }

    case ACTION_TYPE.STORE_META: {
      const { payload } = action;
      const { meta } = state;

      return {
        ...state,
        meta: {
          ...meta,
          ...payload,
        },
      };
    }

    case ACTION_TYPE.UPDATE_STEP_PATH: {
      const { payload } = action;
      const { path, value } = payload;

      const newStepsConfig: StepsConfigType = set({ ...state.stepsConfig }, path, value);

      return {
        ...state,
        stepsConfig: {
          ...newStepsConfig,
        },
      };
    }

    case ACTION_TYPE.STORE_AVAILABLE_ANSWERS: {
      const { payload } = action;

      return {
        ...state,
        stepsConfig: {
          ...stepsConfig,
          [STEPS.HIGHLIGHTS]: createAvailableAnswers(
            state.stepsConfig,
            STEPS.HIGHLIGHTS,
            highlightsQuestionsEnum.poi,
            payload.pois
          ),
        },
      };
    }

    case ACTION_TYPE.SET_ANSWERS_FROM_LOCALSTORAGE: {
      return {
        ...state,
        answers: getAnswersFromLocalStorage(action.payload) as AnswerInReactContextType[],
      };
    }

    case ACTION_TYPE.SAVE_EMBEDDED: {
      const { payload } = action;

      return {
        ...state,
        isEmbedded: payload.isEmbedded,
        parentPage: payload.parentPage,
        parentHost: payload.parentHost,
      };
    }

    case ACTION_TYPE.LOADING_DESTINATION: {
      const { payload } = action;

      return {
        ...state,
        isLoadingDestination: payload,
      };
    }

    case ACTION_TYPE.SET_INITIAL_STEP: {
      const { payload } = action;

      return {
        ...state,
        initialStep: payload,
      };
    }

    case ACTION_TYPE.SET_STEP_SEQUENCE: {
      const { payload } = action;

      return {
        ...state,
        stepSequence: payload,
      };
    }

    case ACTION_TYPE.SET_FLOW_TYPE: {
      const {
        payload: { flowType },
      } = action;
      const { meta } = state;

      const initialStep = flowType === flowTypes.LINEAR ? STEPS.PASSENGERS : SIMPLIFIED_FLOW_STEPS.ONE;
      const currentStepsOptions = flowType === flowTypes.LINEAR ? STEPS : SIMPLIFIED_FLOW_STEPS;
      const possibleSteps = Object.values(currentStepsOptions).map(step => ({
        slug: stepsConfig[step].slug,
        id: stepsConfig[step].id,
      }));

      const stepSequence = possibleSteps.map(step => step.id);

      return {
        ...state,
        meta: {
          ...meta,
          flowType,
        },
        stepSequence,
        initialStep,
      };
    }

    case ACTION_TYPE.SET_TRIP_SURFING: {
      const {
        payload: { isTripSurfing },
      } = action;

      return {
        ...state,
        isTripSurfing,
      };
    }

    default: {
      return state;
    }
  }
};
