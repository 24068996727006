import { basePath } from 'const';
import { STEPS } from 'steps';
import { t } from 'utils/translations';

export const previewIdCookieName = 'offer_preview_id';
export const previewIdCookieMaxAge = 3 * 24 * 60 * 60; // 3 days

export const OFFER_PREVIEW_PATH = '/offer-preview';
export const CUSTOMISABLE_OFFER_PATH = '/offers';

export const config = {
  id: STEPS.PROCESSING,
  idx: 0, // as we dont send questionnaire_events for this step
  questionText: t('processing.headline.noName'),
  next: () => STEPS.EMAIL,
  slug: `${basePath}/processing`,
};
