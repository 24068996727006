import * as Sentry from '@sentry/browser';

type LocalStorageValue = string | number | boolean | Record<string, any>;

const isEnabled = () => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, '');
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    Sentry.captureException(`Local Storage not enabled`);
    return false;
  }
};

export const isLocalStorageEnabled = isEnabled();

export const saveToLocalStorage = (key: string, value: LocalStorageValue) => {
  if (!key || !value) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Error in saving in local storage. Key or value is empty:', key, value);
    }
    return;
  }

  let valueToSave;
  if (typeof value === 'object' || Array.isArray(value)) {
    valueToSave = JSON.stringify(value);
  } else {
    valueToSave = value.toString();
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Saving in local storage:', key, value);
  }

  if (isLocalStorageEnabled) {
    window.localStorage.setItem(key, valueToSave);
  }
};

export const answersLocalStorageKey = 'enquiry_answers';
export const trackingLocalStorageKey = 'enquiry_tracking';
export const destinationIsoLocalStorageKey = 'destination_iso';
export const timeToCompletion = 'enquiry_time_to_completion';
export const budgetValuesExperimentKey = 'randomized_budget_values';

export const getFromLocalStorage = (key: string) => {
  if (isLocalStorageEnabled) {
    return window.localStorage.getItem(key);
  }

  return null;
};

export const removeFromLocalStorage = (key: string) => {
  if (isLocalStorageEnabled) {
    window.localStorage.removeItem(key);
  }
};
