import { TRAVEL_REQUEST_UUID } from 'const';

export const getQueryParams = (locationSearch: string, keys: string[]): Array<string | null> => {
  const queryParam = new URLSearchParams(locationSearch);

  return keys.reduce((prev: Array<string | null>, curr) => {
    const param = queryParam.get(curr);
    prev.push(param);
    return prev;
  }, []);
};

export const getTravelRequestUuidFromQueryParams = (search: string) => {
  const [travelRequestUuid] = getQueryParams(search, [TRAVEL_REQUEST_UUID]);

  return travelRequestUuid || '';
};

export const setQueryParams = (locationSearch: string, params: { name: string; value: string }[]): string => {
  const queryParams = new URLSearchParams(locationSearch);

  params.forEach(({ name, value }) => {
    queryParams.set(name, value);
  });

  return queryParams.toString();
};

export const deleteQueryParams = (locationSearch: string, keys: string[]): string => {
  const queryParams = new URLSearchParams(locationSearch);

  keys.forEach((key: string) => {
    queryParams.delete(key);
  });

  return queryParams.toString();
};
