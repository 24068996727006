import { snowplow } from '@tourlane/tracking';
import { FlagState } from 'components/FeatureFlag/FeatureFlag.types';
import { FlowTypes, SNOWPLOW_PAGE_TYPE } from 'const';

const JSON_SCHEMA = 'iglu:com.snowplowanalytics.iglu/anything-a/jsonschema/1-0-0';

interface SnowplowTrackingData {
  destinationUuid: string;
  isDestinationEnabledForOP?: boolean;
  isEmbedded?: boolean;
  parentPage?: string | null;
  flowType?: FlowTypes;
  isBeforeRedirect?: boolean;
  isLocalStorageEnabled?: boolean;
}

interface SnowplowMetaEntitiesTypes {
  pageType?: string;
  destination?: string;
  entities?:
    | {
        schema: string;
        data: {
          [key: string]: any;
        };
      }[]
    | undefined;
}

export const trackPageView = (trackingData: SnowplowTrackingData, isBeforeRedirect = false) => {
  const params = new URLSearchParams(window.location.search);
  const urlParams: { [key: string]: any } = {};

  params.forEach((value, key) => {
    urlParams[key] = value;
  });

  const entities = {
    schema: JSON_SCHEMA,
    data: { ...trackingData, urlParams } as { [key: string]: any }, // to fit the tracking library type
  };

  const snowPlowMetaEntities: SnowplowMetaEntitiesTypes = {
    pageType: SNOWPLOW_PAGE_TYPE,
    destination: trackingData.destinationUuid,
    entities: isBeforeRedirect ? [{ ...entities, data: { ...entities.data, isBeforeRedirect } }] : [],
  };

  snowplow.trackPageView(snowPlowMetaEntities);
  snowplow.addToGlobalContext([entities]);
};

export const trackOnInitialLoad = (trackingData: SnowplowTrackingData) => {
  snowplow.setup(
    {
      featureFlags: {},
      entities: [],
    },
    5,
    5
  );

  // DO NOT REMOVE
  // This page view tracks the initial 'loading' state
  // before we redirect the user to the actual step.
  // It holds the correct 'Page Referrer URL'
  trackPageView(trackingData, true);
};

// Variation of https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-tracker/general-parameters/getting-the-userid-from-the-snowplow-cookie/
export const getSnowplowSessionUuid = () => {
  const cookieName = '_sp_';
  const matcher = new RegExp(`${cookieName}id\\.[a-f0-9]+=([^;]+);?`);
  const match = document.cookie.match(matcher);
  if (match && match[1]) {
    return match[1].split('.').pop();
  }
  return false;
};

export const trackFeatureFlags = (featureFlags: FlagState) => {
  const snoplowList = Object.entries(featureFlags).map(([key, value]) => ({
    schema: snowplow.Schema.EXPERIMENT_ENTITY,
    data: {
      variation: key,
      value: String(value),
    },
  }));
  snowplow.addToGlobalContext(snoplowList);
};
