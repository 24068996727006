import { basePath, phoneFormFieldsEnum } from 'const';
import { currentMarket } from 'const/marketsAndLocales';
import { STEPS } from 'steps';
import { t } from 'utils/translations';

export type PhoneDataRecord = Record<phoneFormFieldsEnum, string>;

export const config = {
  id: STEPS.PHONE,
  idx: 8,
  questionText: t('contactus.greetings'),
  initialValue: { code: currentMarket.locale.slice(-2) },
  next: () => STEPS.SUCCESS,
  slug: `${basePath}/phone`,
};
