import { useEffect } from 'react';

import { salesConfigStatic } from '../components/TalkToUs/static/salesConfig';
import { currentMarket } from '../const';
import { ACTION_TYPE, AgentShiftsConfig, Dispatch } from '../reducer';
import { api } from '../utils/api';

const SALES_CONFIG = '/enquiry/api/salesConfig';

export const useSalesConfig = (dispatch: Dispatch) => {
  useEffect(() => {
    let salesConfig: AgentShiftsConfig;

    const fetchData = async () => {
      // when not running netlify dev no functions are served and returning 404
      if (process.env.NODE_ENV === 'development') {
        salesConfig = salesConfigStatic[currentMarket.market];
      } else {
        const response = await api({ url: SALES_CONFIG });
        const { data } = response;
        salesConfig = data[currentMarket.market];
      }

      dispatch({
        type: ACTION_TYPE.STORE_META,
        payload: {
          salesConfig,
        },
      });
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
