import { FC, SVGProps, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import DatesApproxIcon from '@tourlane/iconography/Glyphs/Accommodation/Calendar';
import PriceIcon from '@tourlane/iconography/Glyphs/Other/Price';
import DurationIcon from '@tourlane/iconography/Glyphs/Suite/SupplyPending';
import PassengersIcon from '@tourlane/iconography/Glyphs/TravelTypes/PrivateGuided';
import MapNewIconIcon from '@tourlane/iconography/Icons/USPs/MapNew';
import { COLORS, Checkbox, Dropdown, FlexBox, H3, Label, SvgIcon } from '@tourlane/tourlane-ui';
import { Box } from 'components/Box';
import { Footer } from 'components/Footer';
import { Step } from 'components/Step';
import { NextButton } from 'components/Styled';
import { SPLIT_FORM_MAX_WIDTH, currentMarket, passengerTypesEnum, possibleTravelTypesEnum } from 'const';
import { useFormik } from 'formik';
import { range, uniq } from 'lodash-es';
import { ACTION_TYPE, useAppContext } from 'reducer';
import { STEPS } from 'steps';
import { StyledSliderOptions } from 'steps/Budget/BudgetDefaultStyled';
import {
  getBudgetConfigsByTravelType,
  getBudgetDefinitionsByDestination,
  getMinBudgetByDuration,
} from 'steps/Budget/helpers';
import { mapPossibleTravelTypeStringToTravelType } from 'steps/TravelType/config';
import { MultipleAnswerType } from 'utils/answerHook';
import { getPossibleTravelTypes } from 'utils/budgetDefinitions';
import { formatPrice } from 'utils/formatPrice';
import { getNextStepPath } from 'utils/routing';
import { t } from 'utils/translations';

import { StepConfigType } from '../../stepsConfig.types';
import { AnswerInReactContextType } from '../../utils/answers';
import { formatBudget } from '../../utils/gtmEvents';
import { mapDurationToOptions } from '../../utils/mappers';
import { useTrackQuestionViewSnowplowEvent } from '../../utils/snowplowEvents';
import { trackClick, trackQuestionnaireComplete, trackSubmitFormWithMultipleAnswers } from '../../utils/trackingEvents';
import { changeTitleToLabel, config as currentStepConfig } from './config';
import { simplifiedFormSchema } from './const';

interface FormDataType {
  [key: string]: string;
}

export const mapAnswers = (formData: FormDataType) =>
  Object.entries(formData).map(([stepId, value]) => {
    let val;

    if (stepId === currentStepConfig.questions.passengers.id) {
      val = { adults: parseInt(value, 10), children: 0, infants: 0 };
    } else {
      val = { [stepId]: value };
    }

    return {
      stepId,
      value: val,
    };
  });

const getExistingOrInitialValue = (allAnswers: AnswerInReactContextType[], questionId: string) => {
  // @ts-ignore
  const stepConfig: Partial<StepConfigType> = currentStepConfig.questions[questionId];
  const existingAnswers = allAnswers.find((answer: { stepId: string }) => answer.stepId === stepConfig.id);

  const valueIdentifier = questionId === STEPS.PASSENGERS ? passengerTypesEnum.adults : questionId;

  return existingAnswers?.value[valueIdentifier] || stepConfig.initialValue;
};

const Icon: FC<{ icon: FC<SVGProps<SVGSVGElement>> }> = ({ icon: Icon }) => (
  <SvgIcon size={[20, 20, 20, 22, 24]} color={COLORS.ELEMENT_GRAY}>
    <Icon />
  </SvgIcon>
);

export const SimplifiedOne: FC = () => {
  const {
    dispatch,
    answers,
    meta: { destination, offerPreview },
    stepsConfig,
  } = useAppContext();
  const navigate = useNavigate();

  const [currentBudget, setCurrentBudget] = useState<number>();
  const [displayedBudget, setDisplayedBudget] = useState<string>();
  const [options, setOptions] = useState<any>([]);

  if (offerPreview?.price) {
    currentStepConfig.questions.budget.initialValue = offerPreview.price;
  }

  if (offerPreview?.duration) {
    currentStepConfig.questions.duration.initialValue = mapDurationToOptions(offerPreview.duration);
  }

  const initialValues = {
    passengers: getExistingOrInitialValue(answers, STEPS.PASSENGERS)?.toString(),
    datesApprox: getExistingOrInitialValue(answers, STEPS.DATES_APPROX),
    duration: getExistingOrInitialValue(answers, STEPS.DURATION) || '',
    budget: getExistingOrInitialValue(answers, STEPS.BUDGET) || offerPreview?.price || '',
    travelType: getPossibleTravelTypes(destination)[0],
  };

  useTrackQuestionViewSnowplowEvent(currentStepConfig, destination.uuid, currentStepConfig.idx);

  // we update the stepConfig, because it's the source of truth for context data;
  stepsConfig.one.questions!.duration.initialValue = initialValues.duration;
  stepsConfig.one.questions!.travelType.initialValue = initialValues.travelType;
  stepsConfig.one.questions!.travelType.availableAnswers = getPossibleTravelTypes(destination).map(
    type => mapPossibleTravelTypeStringToTravelType[type as unknown as possibleTravelTypesEnum]
  );
  stepsConfig.one.questions!.budget.initialValue =
    stepsConfig.one.questions!.budget.initialValue || offerPreview?.price;

  const onSubmit = (formData: FormDataType) => {
    const formAnswers: MultipleAnswerType[] = mapAnswers(formData);

    trackSubmitFormWithMultipleAnswers(formAnswers, currentStepConfig.idx, currentStepConfig, destination.uuid);
    trackQuestionnaireComplete(
      formAnswers.length,
      formatBudget(parseInt(formData.budget, 10)),
      destination.uuid,
      destination.tracking
    );

    dispatch({
      type: ACTION_TYPE.SAVE_MULTIPLE_ANSWERS,
      payload: formAnswers,
    });

    const nextStepPath = getNextStepPath(currentStepConfig.id);
    navigate(nextStepPath);
  };

  const { setFieldValue, values, errors, isSubmitting, validateField, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: simplifiedFormSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    // validate: false,
  });

  const handleBlur = (name: string) => {
    validateField(name);
  };

  const handleChange = (name: string, value: string | number) => {
    setFieldValue(name, value, false);
  };

  const { budgetStep } = currentStepConfig.questions.budget.configuration;

  const budgetDefinitions = getBudgetDefinitionsByDestination(destination);
  if (!budgetDefinitions) {
    Sentry.captureException(`No budget definitions found for destination ${destination.uuid}`);
  }
  const budgetDefinition = getBudgetConfigsByTravelType(budgetDefinitions, values.travelType);

  useEffect(() => {
    const recommendedBudgetFromDM = getMinBudgetByDuration(budgetDefinition, values.duration);

    const { recommendedBudgetToDisplay, minBudgetToDisplay, maxBudgetToDisplay } = {
      recommendedBudgetToDisplay: recommendedBudgetFromDM,
      minBudgetToDisplay: 1000,
      maxBudgetToDisplay: 6000,
    };

    let selectedBudget = Number(values.budget);

    const selectedBudgetFitsBetweenMinAndMax =
      selectedBudget >= minBudgetToDisplay && selectedBudget <= maxBudgetToDisplay;

    if (!selectedBudgetFitsBetweenMinAndMax) {
      const initialBudgetFromOffer = offerPreview?.price;
      // if currently selected budget doesn't fit into the slider we fallback to the initial offer price if it fits,
      // otherwise, we select the recommendation from DM
      const initialOfferBudgetFitsBetweenMinAndMax = initialBudgetFromOffer
        ? initialBudgetFromOffer >= minBudgetToDisplay && initialBudgetFromOffer <= maxBudgetToDisplay
        : false;

      selectedBudget =
        initialOfferBudgetFitsBetweenMinAndMax && initialBudgetFromOffer
          ? initialBudgetFromOffer
          : recommendedBudgetToDisplay;
    }

    setCurrentBudget(selectedBudget);

    const formattedMinBudget = formatPrice(minBudgetToDisplay, currentMarket.locale);
    const formattedMaxBudget = formatPrice(maxBudgetToDisplay, currentMarket.locale);
    const valueRange = range(minBudgetToDisplay + budgetStep, maxBudgetToDisplay, budgetStep);

    const maybeSelectedBudget =
      selectedBudget < maxBudgetToDisplay && selectedBudget > minBudgetToDisplay ? [selectedBudget] : [];
    const newValueRange = uniq([...valueRange, ...maybeSelectedBudget].sort((a, b) => a - b));

    const options = [
      ...[{ value: minBudgetToDisplay, label: formattedMinBudget }],
      ...newValueRange.map(step => ({ value: step })),
      { value: maxBudgetToDisplay, label: formattedMaxBudget },
    ];

    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentBudget) {
      handleChange(STEPS.BUDGET, currentBudget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBudget]);

  useEffect(() => {
    setDisplayedBudget(formatPrice(initialValues.budget, currentMarket.locale));
  }, [initialValues.budget]);

  useEffect(() => {
    document?.getElementById(Object.keys(errors)[0])?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [errors, isSubmitting]);

  return (
    <Step stickyFooterOnDesktop data-cy="step-one">
      <form onSubmit={handleSubmit}>
        <Box
          maxWidth={SPLIT_FORM_MAX_WIDTH}
          mx="auto"
          px={[16, null, null, 32]}
          pb={[32, null, null, null, 10]}
          pt={[16, 24, 32, 16, 16]}
        >
          <FlexBox justifyContent="center">
            <MapNewIconIcon width={80} height={80} />
          </FlexBox>
          <H3 textAlign="center">{t('simplifiedForm.heading')}</H3>

          <FlexBox direction="column" mt={24}>
            <Box mb={[20, 24, 24, 20, 20]}>
              <Dropdown
                notClearable
                notSearchable
                options={changeTitleToLabel(currentStepConfig?.questions.passengers.availableAnswers)}
                icon={<Icon icon={PassengersIcon} />}
                placeholder={t('simplifiedForm.passengers')}
                shrinkPlaceholder
                forceNativeSelect={false}
                useNativeSelectForMobile={false}
                name={STEPS.PASSENGERS}
                value={values.passengers}
                onChange={(value: string | number) => handleChange(STEPS.PASSENGERS, value)}
                onBlur={() => handleBlur(STEPS.PASSENGERS)}
                error={!!errors.passengers}
                id={STEPS.PASSENGERS}
              />
            </Box>

            <Box mb={[20, 24, 24, 20, 20]}>
              <Dropdown
                notClearable
                notSearchable
                options={changeTitleToLabel(currentStepConfig?.questions.datesApprox.availableAnswers)}
                icon={<Icon icon={DatesApproxIcon} />}
                placeholder={t('simplifiedForm.datesApprox')}
                shrinkPlaceholder
                forceNativeSelect={false}
                useNativeSelectForMobile={false}
                name={STEPS.DATES_APPROX}
                value={values.datesApprox}
                onChange={(value: string | number) => handleChange(STEPS.DATES_APPROX, value)}
                onBlur={() => handleBlur(STEPS.DATES_APPROX)}
                error={!!errors.datesApprox}
                id={STEPS.DATES_APPROX}
              />
            </Box>

            <Box mb={[20, 24, 24, 20, 20]}>
              <Dropdown
                notClearable
                notSearchable
                options={changeTitleToLabel(currentStepConfig?.questions.duration.availableAnswers)}
                icon={<Icon icon={DurationIcon} />}
                placeholder={t('simplifiedForm.duration')}
                shrinkPlaceholder
                forceNativeSelect={false}
                useNativeSelectForMobile={false}
                name={STEPS.DURATION}
                value={values.duration}
                onChange={(value: string | number) => handleChange(STEPS.DURATION, value)}
                onBlur={() => handleBlur(STEPS.DURATION)}
                error={!!errors.duration}
                id={STEPS.DURATION}
              />
            </Box>

            <Box mb={[20, 24, 24, 20, 20]}>
              <Box
                as="fieldset"
                borderRadius="4px"
                py={16}
                pr={16}
                pl={8}
                lineHeight={0}
                m={0}
                border={`1px solid ${COLORS.ELEMENT_GRAY}`}
              >
                <Box
                  as="legend"
                  color={COLORS.INACTIVE_GRAY}
                  fontSize={[12]}
                  fontFamily="Source Sans Pro"
                  fontWeight="400"
                  px={4}
                >
                  {t('simplifiedForm.budget')}
                </Box>
                <Box pl={[4, 4, 8, null, null]}>
                  <FlexBox justifyContent="space-between" alignItems="center" mb={16}>
                    <FlexBox alignItems="center">
                      <SvgIcon size={[20, 20, 20, 22, 24]} color={COLORS.ELEMENT_GRAY}>
                        <PriceIcon />
                      </SvgIcon>
                      <Box
                        ml={8}
                        fontSize={[16, null, 18, null, 20]}
                        lineHeight={['22px', null, '26px', null, '28px']}
                        color={COLORS.NIGHTINGALE_BLACK}
                        fontFamily="Source Sans Pro"
                      >
                        {displayedBudget}/{t('simplifiedForm.passengerOne')}
                      </Box>
                    </FlexBox>
                    <FlexBox alignItems="center">
                      <Label mr={0}>
                        <Checkbox
                          defaultChecked
                          onChange={event =>
                            trackClick({
                              atom: 'budget_flights_checkbox',
                              molecule: 'ee_simplified_form',
                              label: `${event.target.checked}`,
                              destinationUuid: destination.uuid,
                            })
                          }
                        />
                        <Box
                          ml={4}
                          fontSize={[14, null, 16, null, 18]}
                          lineHeight={['20px', null, '24px', null, '26px']}
                          color={COLORS.NIGHTINGALE_BLACK}
                          fontFamily="Source Sans Pro"
                        >
                          {t('simplifiedForm.flightsIncluded')}
                        </Box>
                      </Label>
                    </FlexBox>
                  </FlexBox>
                  <Box>
                    {options.length > 0 ? (
                      <StyledSliderOptions
                        value={currentBudget}
                        onChange={value => setCurrentBudget(value)}
                        onUpdate={value => setDisplayedBudget(formatPrice(value, currentMarket.locale))}
                        options={options}
                        handleProps={{
                          type: 'button',
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </FlexBox>
        </Box>
        <Footer>
          <NextButton type="submit" />
        </Footer>
      </form>
    </Step>
  );
};
